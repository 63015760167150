import { PieChart } from '@mui/x-charts/PieChart';
import { PackageStats } from '../../../interface/package';
import { Box, Button, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import orderService from '../../../services/order.service';
import { getPriceString } from '../../../helper';
import { ArrowForwardIos } from '@mui/icons-material';
import { setIsSelect, setSelectOrder } from '../../../redux/order';
import { setAppState } from '../../../redux/commonSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const OrderPendingList = () => {

    const [rows, setRows] = useState<any[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getData = async () =>{
        const resultStats = await orderService.getLastestPendingPaidOrderList();
		if (resultStats?.success) {
			const { data } = resultStats;
			setRows(data);
		}
    }

    useEffect(()=>{
        getData()
    },[])
    
    const onSelectOrder = (value: any) =>{
        dispatch(setSelectOrder({order_alias : value}))
        dispatch(setIsSelect({isSelect : true}))
        dispatch(setAppState('orders'));
        navigate( '/order');
      }

	return <div className="mt-5 mx-2">
		<div className="">
			<div className="flex flex-col items-center px-1 bg-white shadow-lg rounded-lg mt-5">
				<div className="mb-1 text-xl font-semibold mt-3">Danh sách đơn hàng chưa xử lý</div>
                <Box sx={{ width: '100%', overflow: 'hidden' }}>
                    {
                        rows.length > 0 && 
                    <TableContainer sx={{ width: '100%', maxHeight: '32vh', 'th': { padding: '8px' }, 'td': { padding: '10px' } }}>
                        <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow >
                                <TableCell align="center" width={'20%'}>Mã hóa đơn</TableCell>
                                <TableCell align="right" width={'20%'}>Người dùng</TableCell>
                                <TableCell align="right" width={'20%'}>Tổng (VNĐ)</TableCell>
                                <TableCell align="center" width={'20%'}>Trạng thái</TableCell>
                                <TableCell align="center" width={'10%'}></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <>
                                    <TableRow
                                    hover 
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row" align="center">
                                        {row.order_alias}
                                    </TableCell>
                                    <TableCell align="right">{row.user_infor[0].user_name}</TableCell>
                                    <TableCell align="right">{getPriceString(row.total).replace('VNĐ','')}</TableCell>
                                    <TableCell align="center">
                                        {
                                        row.status === 'pending' ? <Chip size='small' color="error" label={'Chưa xử lý'}/>
                                        : row.status === 'completed' ? <Chip size='small' color="success" label={'Hoàn thành'}/>
                                        : row.status === 'paid' ? <Chip size='small' color="warning" label={'Đã thanh toán'}/>
                                        : <Chip color="default" size='small' label={'Hủy đơn'}/>
                                    }</TableCell>
                                    <TableCell align="right" width={'5%'}>
                                        <IconButton size='small' color='inherit' onClick={()=>onSelectOrder(row.order_alias)}>
                                            <ArrowForwardIos/>
                                        </IconButton>
                                    </TableCell>
                                    </TableRow>
                                </>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                    {
                        rows.length <= 0 && <Box sx={{height:'32vh'}}>

                            Không có đơn chưa xử lý nào !
                        </Box>
                    }
                </Box>
			</div>
		</div>
	</div>
	}


export default OrderPendingList;

  
