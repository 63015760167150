/* eslint-disable */
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
export default function Loading() {
    const isLoading = useSelector((state: RootState) => state.common.loading);
    return (
        <>
            {isLoading && 
            <div className="w-[100vw] h-[100vh] flex justify-center items-center fixed top-0 left-0 z-[99999999] bg-black/10">
                <img src="/Loading.gif"></img>
            </div>
            }
        </>
    )
}
