import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
    IconButton,
    Collapse,
    Typography,
    TableFooter,
    ButtonGroup,
    Chip,
    Tooltip,
    Grid,
    FormControl,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    useMediaQuery
} from "@mui/material";
import { getPriceString } from "../../../helper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { styled, useTheme } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Order, OrderStats } from "../../../interface/order";
import { Fragment, useEffect, useState } from "react";
import orderService from "../../../services/order.service";
// import UpdateOrderDialog from './UpdateOrderDialog'
import { LIMIT_RESOURCE } from "../../../configs/enum";
import ToggleActiveDialog from "./ToggleActiveDialog";
import CompleteDialog from "./CompleteDialog";
import { toast } from "react-toastify";
import CancelDialog from "./CancelDialog";
import moment from "moment";
import PaidIcon from '@mui/icons-material/Paid';
import { Download, ExpandLess, ExpandMore, Replay, Search, ShoppingBasket } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setIsSelect, setOrderStatus, setSelectOrder } from "../../../redux/order";

function SearchOrder({onChangeOtherKey, query, downloadFunc}: {onChangeOtherKey: Function, query: any, downloadFunc: Function}){
    const dispatch = useDispatch();
    const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const selectOrder = useSelector((state: RootState) => state.orders.order_alias);
    const selectStatusOrder = useSelector((state: RootState) => state.orders.status);
    const isSelectOrder = useSelector((state: RootState) => state.orders.isSelect);
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
      };
    
    const statusList = [
        {key: 'all', name: 'Tất cả'},
        {key: 'completed', name: 'Hoàn thành'},
        {key: 'pending', name: 'Chưa xử lý'},
        {key: 'canceled', name: 'Hủy đơn'},
        {key: 'paid', name: 'Đã thanh toán'},
    ]

    const [onReload, setOnReload] = useState(false);

    const [searchstring, setSearchString] = useState(query.searchstring);
    const [status, setStatus] = useState(query.status ?? 'all');
    const [fromDate, setFromDate] = useState(query.fromDate ?? null);
    const [toDate, setToDate] = useState(query.toDate ?? null);
    const [changeFlag, setChangeFlag] = useState(false);

    const onChangeSearchName = ( event: any )=>{
        setSearchString(event.target.value.trim());
        if(event.target.value.trim() !== null)
            setChangeFlag(!changeFlag)
    }

    const onChangeFromDate = ( event: any )=>{
        setFromDate(event.target.value);
        if(event.target.value !== '')
            setChangeFlag(!changeFlag)
    }

    const onChangeToDate = ( event: any )=>{
        setToDate(event.target.value);
        if(event.target.value !== '')
            setChangeFlag(!changeFlag)
    }

    const onChangeStatus = ( event: any )=>{
        setStatus(event.target.value);
        if(event.target.value !== null)
            setChangeFlag(!changeFlag)
    }

    const reLoad = () =>{
        setFromDate('');
        setStatus('all');
        setToDate('');
        setSearchString('');
        dispatch(setSelectOrder({order_alias : ''}));
        setOnReload(!onReload);
        setChangeFlag(!changeFlag)
    }

    useEffect(()=>{
        if(isSelectOrder){
            setSearchString(selectOrder);
            setStatus('all');
            dispatch(setIsSelect({isSelect : false}))
        }
    },[isSelectOrder])

    useEffect(()=>{
        if(selectStatusOrder !== ''){
            setSearchString('');
            setStatus(selectStatusOrder);
            dispatch(setOrderStatus({status: ''}))
        }
    },[selectStatusOrder])

    useEffect(()=>{
        setSearchString(query.searchstring);
    },[query])

    useEffect(()=>{
        if(changeFlag){
            setChangeFlag(false);
            if(searchstring !== null || fromDate !== '' || toDate !== '')
            {
                setTimeout(() => {
                    onChangeOtherKey({
                        searchstring, status, fromDate, toDate
                    });
                }, 500);
                
            }else{
                onChangeOtherKey({
                    searchstring, status, fromDate, toDate
                });
            }
        }
    },[changeFlag])


    return (
        <Box className="grid grid-cols-2 md:grid-cols-12 gap-2 mb-2">
            <Collapse in={expanded} timeout="auto" unmountOnExit className="col-span-2" sx={{
                display: {
                    md: "none"
                },
                width: '100%'
            }}>
                <Box className="grid grid-cols-2 md:grid-cols-12 gap-2 mb-2">
                <FormControl fullWidth className="col-span-2 md:col-span-3">
                <TextField
                    value={searchstring ?? query.searchstring}
                    defaultValue={searchstring ?? query.searchstring}
                    label={'Từ khóa tìm kiếm'}
                    placeholder="Mã hóa đơn, tên người dùng"
                    onChange={onChangeSearchName}
                />
            </FormControl>
            <FormControl fullWidth className="col-span-2 md:col-span-2">
                <InputLabel>
                Trạng thái hóa đơn
                </InputLabel>
                <Select
                    fullWidth
                    value={status}
                    onChange={onChangeStatus}
                    label={'Trạng thái hóa đơn'}
                >
                    {statusList.map(x=><MenuItem value={x.key}>{x.name}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl fullWidth className="col-span-2 md:col-span-2">
                <TextField
                    fullWidth
                    value={fromDate}
                    InputLabelProps={{ shrink: true }}
                    label={'Từ'}
                    onChange={onChangeFromDate}
                    type="date"
                />
            </FormControl>
            <FormControl fullWidth className="col-span-2 md:col-span-2">
                <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={toDate}
                    label={'Đến'}
                    onChange={onChangeToDate}
                    type="date"
                />
            </FormControl>
                </Box>
            </Collapse>
            {
                !fullScreen &&
                <>
                    <FormControl fullWidth className="col-span-2 md:col-span-3">
                        <TextField
                            value={searchstring ?? query.searchstring}
                            defaultValue={searchstring ?? query.searchstring}
                            label={'Từ khóa tìm kiếm'}
                            placeholder="Mã hóa đơn, tên người dùng"
                            onChange={onChangeSearchName}
                        />
                    </FormControl>
                    <FormControl fullWidth className="col-span-2 md:col-span-2">
                        <InputLabel>
                        Trạng thái hóa đơn
                        </InputLabel>
                        <Select
                            fullWidth
                            value={status}
                            onChange={onChangeStatus}
                            label={'Trạng thái hóa đơn'}
                        >
                            {statusList.map(x=><MenuItem value={x.key}>{x.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth className="col-span-2 md:col-span-2">
                        <TextField
                            fullWidth
                            value={fromDate}
                            InputLabelProps={{ shrink: true }}
                            label={'Từ'}
                            onChange={onChangeFromDate}
                            type="date"
                        />
                    </FormControl>
                    <FormControl fullWidth className="col-span-2 md:col-span-2">
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={toDate}
                            label={'Đến'}
                            onChange={onChangeToDate}
                            type="date"
                        />
                    </FormControl>
                </>
            }
            {
                (!fullScreen || expanded) && 
                <Box className="col-span-2 md:col-span-1 my-auto mr-auto h-full w-full md:w-max">
                    <Button size="large" sx={{width:'100%', height: '100%'}} color="info" variant="contained" onClick={()=>reLoad()}>
                    <Replay/>
                    </Button>
                </Box>
            }
            {
                fullScreen && 
                <Box className="col-span-1 md:col-span-1 my-auto mr-auto h-full w-full md:w-max" >
                    <Button size="large" sx={{width:'100%', height: '100%'}} startIcon={expanded ? <ExpandLess/>:<Search/>} color="primary" variant="contained" onClick={()=>handleExpandClick()}>
                        {expanded ? "Thu gọn":"Tìm kiếm"}
                    </Button>
                </Box>
            }
            <Box className="col-span-1 md:col-span-2 my-auto ml-auto w-full md:w-max">
                <Button size="large" sx={{width:'100%', height: '100%'}} color="success" variant="contained" startIcon={<Download/>} onClick={()=>downloadFunc()}>
                    Xuất Excel
                </Button>
            </Box>
        </Box>
    );
}

export default SearchOrder;