import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import SearchFilter from "./component/SearchFilter";
import AdminTable from "./component/AdminTable";
import adminService from "../../services/admin.service";
import { useEffect, useState } from "react";
import packageService from "../../services/packages.service";
import { Button } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateAdminDialog from "./component/CreateAdminDialog";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setLoading } from "../../redux/commonSlice";
import { toast } from "react-toastify";
import { COMMON_ERROR_MSG } from "../../configs/constant";
import { setAdmins , setTotal} from "../../redux/admin";

const AdminsPage = () => {
	const [basePackages, setBasePackages] = useState([]);
	const [extraPackages, setExtraPackages] = useState([]);
	const query = useSelector((state: RootState) => state.admin.query);
	const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
	const dispatch = useDispatch();

	// const getPackage = async ()=>{
	// 	const data = await packageService.getPackages();
	// 	if(data?.success)
	// 	{
	// 		setBasePackages((Object.values(data.data) as never[])?.filter((x:any)=>(x.type === 'base')));
	// 		setExtraPackages((Object.values(data.data) as never[])?.filter((x:any)=>(x.type === 'extra')));
	// 	}
	// }
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// useEffect(()=>{
	// 	getPackage();
	// },[])


	useEffect(() => {
		getUsers();
	}, [query])

	const getUsers = async () => {
		const result = await adminService.getList(query);
		if (result.success) {
			console.log(result.data)
			dispatch(setAdmins(result.data.users));
			dispatch(setTotal(result.data.total));
		}
	}

	const exportUserExcel = async () => {
		dispatch(setLoading(true));
		const result = await adminService.exportExcel({
			searchstring: query.keyword || '',
			role: 'all'
		});
		if (result.success) {
			const { fileData, filename } = result.data as any;
            const href = URL.createObjectURL(fileData);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.toString());
			toast.success('Xuất file thành công.')
		}
		else {
			toast.error(COMMON_ERROR_MSG)
		}
		dispatch(setLoading(false));
	}

	return (
	<>
		<CreateAdminDialog open={openCreateUserDialog} onClose={() => setOpenCreateUserDialog(false)} getUsers={getUsers} />
		<SearchFilter />
		<div className="mt-6">
			<Button variant="contained" onClick={() => setOpenCreateUserDialog(true)} >
				<AddCircleIcon />
				<span className="ml-2">Tạo người dùng</span>
			</Button>
			{/* <Button variant="contained" className={"!ml-4"} onClick={() => exportUserExcel()} color="success">
				<FileCopyIcon />
				<span className="ml-2">Xuất File Excel</span>
			</Button> */}
		</div>
		<AdminTable getUsers={getUsers} basePackages={basePackages} extraPackages={extraPackages}/>
	</>
	);
};

export default AdminsPage;