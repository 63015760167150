import {
  Toolbar,
  Popover,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import assets from "../../assets";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useEffect, useState } from "react";
import { setLoggedIn } from "../../redux/auth";
import { setQuery } from "../../redux/users";
import { DEFAULT_USER_QUERY } from "../../configs/constant";
import Cookies from "js-cookie";
import storageConstant from "../../configs/storage.constant";
import OrderNotification from "../../pages/order/component/OrderNotification";
import Hidden from "@mui/material/Hidden";
import {
  CachedOutlined,
  LogoutOutlined,
  PasswordOutlined,
  Settings,
} from "@mui/icons-material";
import ChangePwdDialog from "./ChangePwdModal";
import appRoutes from "../../routes/appRoutes";
import moment from "moment";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ConfirmModal from "../../pages/component/ConfirmModal";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface Topbar extends MuiAppBarProps {
  openModal?: boolean;
}

interface Props {
  onShow?: any;
}

const Topbar = ({ onShow }: Props) => {
  const dispatch = useDispatch();
  const admin = useSelector((state: RootState) => state.auth.admin);
  const appState = useSelector((state: RootState) => state.common.appState);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openChangePwd, setOpenChangePwd] = useState<boolean>(false);
  const [time, setTime] = useState<Date>(new Date());
  const [openConfirm, setOpenConfirm] = useState(false);
  const openPopover = Boolean(anchorEl);
  const logout = () => {
    dispatch(setLoggedIn(false));
    localStorage.clear();
    Cookies.remove(storageConstant.ACCESS_TOKEN);
    Cookies.remove(storageConstant.REFRESH_TOKEN);
    dispatch(setQuery(DEFAULT_USER_QUERY));
  };

  const onCancelConfirm = () => {
    setOpenConfirm(false);
  };
  const onPassConfirm = async () => {
    await logout();
    setOpenConfirm(false);
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    setTime(new Date());
  }, []);

  const handleDrawerToggle = () => {
    onShow();
  };

  return (
    <AppBar
      enableColorOnDark
      position="fixed"
      className={"!shadow-lg"}
      sx={{
        width: { sm: `calc(100% - ${300}px)` },
        ml: { sm: `${300}px` },
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
      // className={"!shadow-lg"}

      // sx={{
      // 	width: '100%',
      // 	zIndex: 1000,
      // 	ml: sizeConfigs.sidebar.width,
      // 	boxShadow: "unset",
      // 	backgroundColor: colorConfigs.topbar.bg,
      // 	color: colorConfigs.topbar.color,

      // }}
    >
      <Toolbar className={"flex justify-between disableGutters"}>
        <div className="flex gap-2 ">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <div className="logo flex items-center justify-center bg-[#d3d3d3] w-[300px] h-[70px]">
						<img className="mt-1" src={assets.images.logo} width={120} />
					</div> */}
          <span className="text-xl font-medium mx-2 my-auto">
            {" "}
            {
              appRoutes?.find((x) => x.state === appState)?.sidebarProps
                ?.displayText
            }{" "}
          </span>
        </div>
        <div className="flex gap-2 mx-2">
          <Tooltip
            title={
              <div className="w-full">
                <Typography>
                  Cập nhật lần cuối lúc:{" "}
                  {moment(time).format("HH:mm:ss DD/MM/Y")}
                </Typography>
              </div>
            }
          >
            <IconButton
              size="medium"
              onClick={() => {
                reload();
              }}
            >
              <CachedOutlined sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>
          {admin.role === "admin" && <OrderNotification />}
          <div
            className="user flex items-center cursor-pointer"
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
          >
            <AccountCircle className={"pr-2"} fontSize="large" />
            <Hidden smDown>
              <span className="text-xl">{admin.user_name}</span>
            </Hidden>
          </div>
        </div>
      </Toolbar>
      {/* <Popover
				sx={{ zIndex: 999999, px: "unset" }}
				open={openPopover}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			> */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openPopover}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Hidden smUp>
          <span
            style={{ width: "100%", display: "flex", textAlign: "center", justifyContent: 'center' }}
            className="text-xl"
          >
            {admin.user_name}
          </span>
        </Hidden>
        <ListSubheader sx={{ flexDirection: "column" }}>
          {admin.role === "admin" && "Chức vụ: Quản trị viên"}
          {admin.role === "root" && "Chức vụ: Quản trị hệ thống"}
        </ListSubheader>
        <Divider />
        <MenuItem onClick={() => setOpenChangePwd(true)}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Đổi mật khẩu
        </MenuItem>
        <MenuItem onClick={() => setOpenConfirm(true)}>
          <ListItemIcon>
            <LogoutOutlined fontSize="small" />
          </ListItemIcon>
          Đăng xuất
        </MenuItem>
        {/* <List
				sx={{ width: '100%', bgcolor: 'background.paper' }}
				subheader={<ListSubheader>{admin.role === 'admin' && 'Chức vụ: Quản trị viên'}{admin.role === 'root' && 'Chức vụ: Quản trị hệ thống'}</ListSubheader>}
				> */}
        {/* <ListItem disablePadding>
						<ListItemButton onClick={()=>setOpenChangePwd(true)}>
							<ListItemIcon>
								<PasswordOutlined/>
							</ListItemIcon>
							<ListItemText primary="Đổi mật khẩu" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={logout}>
							<ListItemIcon>
								<LogoutOutlined/>
							</ListItemIcon>
							<ListItemText primary="Đăng xuất" />
						</ListItemButton>
					</ListItem> */}
        {/* </List> */}
      </Menu>
      {/* <div className="p-5">
					<Button variant="contained" onClick={logout}>
						Đăng xuất
					</Button>
				</div> */}
      {/* </Popover> */}
      <ChangePwdDialog
        onClose={() => {
          setOpenChangePwd(false);
        }}
        onLogout={logout}
        open={openChangePwd}
        user={admin}
        key={admin._id}
      />
      <ConfirmModal
        open={openConfirm}
        data={null}
        onCancel={onCancelConfirm}
        onPass={onPassConfirm}
        title={"Xác nhận hoàn thành"}
        key={admin._id}
        content={
          <Typography variant="body1">
            Bạn có chắc muốn đăng xuất khỏi hệ thống không ?
          </Typography>
        }
      />
    </AppBar>
  );
};

export default Topbar;
