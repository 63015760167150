import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import { Package } from "../../../interface/package";
import moment from "moment";
import { getPackageType, getPriceString } from "../../../helper";

interface Props {
	open: boolean,
	onClose: any,
	resourcePackage: Package
}

const UpdateHistoryDialog = ({open, onClose, resourcePackage} : Props) => {
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: theme.palette.primary.main,
		  color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 14,
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
		  backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
		  border: 0,
		},
	}));

	if (resourcePackage) {
		return (
			<Dialog open={open} maxWidth="xl" fullWidth sx={{ zIndex: 10000}}>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<span className="w-full text-center">{ `Lịch sử thay đổi gói ${ resourcePackage.package_name }`}</span>
						<IconButton
							sx={{ marginLeft: '1px' }}
							edge="start"
							color="inherit"
							onClick={onClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</div>	
				</DialogTitle>
				<Divider />
				<DialogContent className={"resource-info px-[24px]"}>
					{
						resourcePackage.updateHistories.length
						? (
							<TableContainer component={Paper} className={"mt-5"} >
							<Table sx={{ minWidth: 650 }} aria-label="package table">
								<TableHead>
									<TableRow>
										<StyledTableCell>STT</StyledTableCell>
										<StyledTableCell align="left">Tên gói</StyledTableCell>
										<StyledTableCell align="left">Số lượng khách (người)</StyledTableCell>
										<StyledTableCell align="left">Dung lượng file (Mb)</StyledTableCell>
										<StyledTableCell align="left">Loại gói</StyledTableCell>
										<StyledTableCell align="left">Giá (VNĐ)</StyledTableCell>
										<StyledTableCell align="left">Chu kỳ (tháng)</StyledTableCell>
										<StyledTableCell align="left">Trạng thái</StyledTableCell>
										<StyledTableCell align="left">Ngày chỉnh sửa</StyledTableCell>
										<StyledTableCell align="left">Chỉnh sửa bởi</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{resourcePackage.updateHistories?.map((p, index) => (
									<StyledTableRow
										key={index}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<StyledTableCell component="th" scope="row" sx={{width: '10px'}}>
											{index + 1}
										</StyledTableCell>
										<StyledTableCell align="left" sx={{width: '300px'}}>
											<span className={`${p.updateKey?.includes('package_name') && '!font-bold'}`}>
												{ p.package_name }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{width: '300px'}}>
											<span className={`${p.updateKey?.includes('event_guest') && '!font-bold'}`}>
												{ `${p.event_guest}` }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{width: '340px'}}>
											<span className={`${p.updateKey?.includes('file_storage') && '!font-bold'}`}>
												{ `${p.file_storage}` }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{ width: '180px'}}>
											<span>
												{ getPackageType(p.type) }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{ width: '220px'}}>
											<span className={`${p.updateKey?.includes('price') && '!font-bold'}`}>
												{ getPriceString(p.price).replace('VNĐ','') }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{ width: '220px'}}>
											<span className={`${p.updateKey?.includes('cycle') && '!font-bold'}`}>
												{ `${p.cycle}` }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{ width: '250px'}} >
											<span className={`${p.updateKey?.includes('active') && '!font-bold'}`}>
												{
													p.active 
													? 'Đang hoạt động'
													: 'Không hoạt động'
												}
											</span>
										</StyledTableCell>
										<StyledTableCell align="center" sx={{ width: '200px'}}>
											<span>
												{ p?.createdAt ? moment(p.createdAt).format('DD-MM-YYYY HH:mm') : '-' }
											</span>
										</StyledTableCell>
										<StyledTableCell align="left" sx={{ width: '200px'}}>
											<span>
												{ p?.createdByName }
											</span>
										</StyledTableCell>
									</StyledTableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
						)
						: <>
							<div className="text-center text-lg">
								Gói này chưa có sự thay đổi
							</div>
						</>
					}
				</DialogContent>
				<Divider />
				<DialogActions className={"my-3"}>
					<Button variant="outlined" onClick={onClose}>
						Quay lại
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
	return <></>
}

export default UpdateHistoryDialog;