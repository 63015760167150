import { configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import authSlice from "./auth";
import usersSlice from "./users";
import OrdersSlice from "./order";
import adminsSlice from "./admin";

export const store = configureStore({
  reducer: {
    common: commonSlice,
    auth: authSlice,
    users: usersSlice,
    orders: OrdersSlice,
    admin: adminsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
