/* eslint-disable */
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../configs/constant';

import Cookies from 'js-cookie';
import axios from '../configs/axios';
import storageConstant from '../configs/storage.constant';
import { store } from '../redux/store';
import { setShowTokenExpireMessage } from '../redux/auth';

const authService = {
    getNewAccessAndRefreshToken: async () => {
        const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);

        // If dont't have refreshToken means exp on Client (because cookie exp after 1 hours)
        if (!refreshToken) {
            localStorage.removeItem(storageConstant.USER);
            return Promise.reject(new Error('NOT_FOUND_REFRESH_TOKEN'));
        }

        return axios
            .post('/admin/refresh-token', {
                refreshToken,
            })
            .then(({ data }) => {
                const { accessToken, refreshToken } = data.data;
                const date = new Date();

                // AccessToken expr after 1 hour
                date.setTime(date.getTime() + 3600 * 1000);
                Cookies.set(ACCESS_TOKEN_KEY, accessToken, { expires: date });

                // RefreshToken exp after 2 day
                // date.setDate(date.getDate() + 2)
                Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { expires: 1 });

                return { accessToken, refreshToken };
            })
            .catch((error) => {
				if (localStorage.getItem(storageConstant.USER)) {
					store.dispatch(setShowTokenExpireMessage(true))
				}
                localStorage.removeItem(storageConstant.USER);
                Cookies.remove(ACCESS_TOKEN_KEY);
                Cookies.remove(REFRESH_TOKEN_KEY);

                return error;
            });
    },
	login: async (username: string, password: string, token: string) => {
		try {
			const loginResult = await axios.post('/admin/login', { username, password, token});
			const { user, accessToken, refreshToken } = loginResult.data.data
			localStorage.setItem(storageConstant.USER, JSON.stringify(user))
			let date = new Date()
			date.setTime(date.getTime() + 3600 * 1000)
			Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date })
			Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 }) // 1 day
			return {
				success: true,
				user
			}
		}
		catch (error) {
			return {
				success: false,
				error
			}
		}
	},
	whoAmI: async () => {
		try {
			const result = await axios.get('/admin/who-am-i');
			const user = result?.data?.data;
			localStorage.setItem(storageConstant.USER, JSON.stringify(user));
			return {
				success: true,
				user
			};
		}
		catch (error) {
			return {
				success: false,
				error
			}
		}
		
	}
};

export default authService;
