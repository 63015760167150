import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
	isReload: false,
	isSelect: false,
	order_alias: '',
	status: 'all',
};

export const OrdersSlice = createSlice({
	name: "OrdersState",
	initialState,
	reducers: {
		setReload: (state, action: PayloadAction<{isReload: boolean}>) => {
			state.isReload = action.payload.isReload;
		},
		setSelectOrder: (state, action: PayloadAction<{order_alias: string}>) => {
			state.order_alias = action.payload.order_alias;
		},
		setOrderStatus: (state, action: PayloadAction<{status: string}>) => {
			state.status = action.payload.status;
		},
		setIsSelect: (state, action: PayloadAction<{isSelect: boolean}>) => {
			state.isSelect = action.payload.isSelect;
		},
	}
}); 

export const {
	setReload,
	setSelectOrder,
	setIsSelect,
	setOrderStatus,
} = OrdersSlice.actions;

export default OrdersSlice.reducer;