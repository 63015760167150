import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import SearchFilter from "./component/SearchFilter";
import UserTable from "./component/UserTable";
import { setUsers, setTotal } from "../../redux/users";
import userService from "../../services/user.service";
import { useEffect, useState } from "react";
import packageService from "../../services/packages.service";
import { Button, Grid } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateUserDialog from "./component/CreateUserDialog";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setLoading } from "../../redux/commonSlice";
import { toast } from "react-toastify";
import { COMMON_ERROR_MSG } from "../../configs/constant";

const UsersPage = () => {
	const [basePackages, setBasePackages] = useState([]);
	const [extraPackages, setExtraPackages] = useState([]);
	const total = useSelector((state: RootState) => state.users.total);
	// const query = useSelector((state: RootState) => state.users.query);
	const [query, setQuery] = useState<{page: number, limit: number, keyword: string, roles: any, status: string}>({
		page: 1,
		limit: 10,
		keyword: '',
		status: 'all',
		roles: ['normal','vip'],
	});
	const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
	const dispatch = useDispatch();

	const getPackage = async ()=>{
		const data = await packageService.getPackages();
		if(data?.success)
		{
			setBasePackages((Object.values(data.data) as never[])?.filter((x:any)=>(x.type === 'base')));
			setExtraPackages((Object.values(data.data) as never[])?.filter((x:any)=>(x.type === 'extra')));
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(()=>{
		getPackage();
	},[])


	useEffect(() => {
		getUsers();
	}, [query])

	const getUsers = async () => {
		const result = await userService.getUsers(query);
		if (result.success) {
			dispatch(setUsers(result.data.users));
			dispatch(setTotal(result.data.total));
		}
	}

	const exportUserExcel = async () => {
		dispatch(setLoading(true));
		const result = await userService.exportExcel({
			...query,
		});
		if (result.success) {
			const { fileData, filename } = result.data as any;
            const href = URL.createObjectURL(fileData);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.toString());
			toast.success('Xuất file thành công.')
		}
		else {
			toast.error(COMMON_ERROR_MSG)
		}
		dispatch(setLoading(false));
	}

	return (
	<>
		<CreateUserDialog open={openCreateUserDialog} onClose={() => setOpenCreateUserDialog(false)} getUsers={getUsers} />
		<Grid container spacing={3}>
			<Grid item xs={12} md={6}>
				<SearchFilter query={query} setQuery={setQuery}/>
			</Grid>
			<Grid
				gap-2
				item xs={12} md={6} sx={{
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'flex-end'
			}}>
				<Button
					sx={{ mr: 1 }}
					size="large" 
					variant="contained" 
					startIcon={<AddCircleIcon />} 
					onClick={() => setOpenCreateUserDialog(true)}>
					Tạo người dùng
                </Button>
				<Button size="large" color="success" variant="contained" startIcon={<FileCopyIcon />} onClick={exportUserExcel}>
					Xuất Excel
                </Button>
			</Grid>
			
		</Grid>
		<div className="text-xl font-bold mt-1 text-right">
			<div>Tổng cộng: {total}</div>				
		</div>
		<UserTable setQuery={setQuery} query={query} getUsers={getUsers} basePackages={basePackages} extraPackages={extraPackages} getPackage={getPackage} />
	</>
	);
};

export default UsersPage;