import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
	Divider,
	ButtonGroup,
	TextField,
	Tooltip,
	Typography,
    Select,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function ConfirmModal ({ open, content, title, data, onPass, onCancel}: {open: boolean, content: any, title: any, data: any, onPass: Function, onCancel: any}){
 
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <span>{title}</span>
                    <IconButton
                        sx={{ marginLeft: '1px' }}
                        edge="start"
                        color="inherit"
                        onClick={onCancel}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>	
            </DialogTitle>
            <Divider/>
            <DialogContent>
                {content}
            </DialogContent>
            <Divider/>
            <DialogActions className="flex ml-auto">
                <Button onClick={()=>onCancel(data)} variant="outlined">Hủy bỏ</Button>
                <Button onClick={()=>onPass(data)} variant="contained">Xác nhận</Button>
            </DialogActions>
        </Dialog>
    )

}

export default ConfirmModal;