import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Divider,
  ButtonGroup,
  TextField,
  Tooltip,
  Typography,
  Table,
  TableRow,
  Tab,
  TableCell,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "../../../interface/user";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getHighestRole } from "../../../helper";
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { USER_ROLE } from "../../../configs/enum";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getPriceString } from "../../../helper";
import moment, { Moment } from "moment";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import userService from "../../../services/user.service";
import { toast } from "react-toastify";
import {
  RemoveCircleOutline,
  Replay,
  Star,
  DensityLarge,
  Delete,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface ExtraPackageProps {
  data: any;
  removeExtraComponent: Function;
  changeExtraComponent: Function;
  defaultExtraPackages: any[];
}

const ExtraPackage = ({
  data,
  removeExtraComponent,
  changeExtraComponent,
  defaultExtraPackages,
}: ExtraPackageProps) => {
  // const uniquePackage = Array.from(new Set([...defaultExtraPackages.map(x=>x._id), ...extraPackages.map(x=>x._id)]))
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [quantity, setQuantity] = useState(data.quantity);

  const removeComponent = (_id: any) => {
    removeExtraComponent(_id);
  };

  const changeQuantity = (event: any) => {
    if (+event.target.value > 0 && +event.target.value < 100) {
      setQuantity(event.target.value);
      changeExtraComponent({ _id: data._id, quantity: +event.target.value });
    } else if (+event.target.value <= 0) {
      setQuantity(1);
      changeExtraComponent({ _id: data._id, quantity: 1 });
    } else if (+event.target.value >= 100) {
      setQuantity(100);
      changeExtraComponent({ _id: data._id, quantity: 100 });
    }
  };

  const plusExtra = () => {
    if (0 + quantity + 1 <= 100) {
      setQuantity(1 + quantity);
      changeExtraComponent({ _id: data._id, quantity: 1 + quantity });
    }
  };

  const minusExtra = () => {
    if (0 + quantity - 1 > 0) {
      setQuantity(0 + quantity - 1);
      changeExtraComponent({ _id: data._id, quantity: quantity - 1 });
    }
  };

  return (
    <TableRow>
      {isSmallScreen ? (
        <TableCell colSpan={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "16px",
            }}
          >
            
            {data?.add_type === "old" && (
              <IconButton>
                <Replay color="error" />
              </IconButton>
            )}
            <ButtonGroup variant="text" size="small">
              <IconButton color="primary" onClick={minusExtra}>
                <RemoveCircleSharpIcon />
              </IconButton>
              <TextField
                size="small"
                className="w-[50px]"
                sx={{
                  input: { textAlign: "center", p: 1 },
                  "input[type=number]::-webkit-inner-spin-button": {
                    appearance: "none",
                  },
                  "input[type=number]::-webkit-outer-spin-button": {
                    appearance: "none",
                  },
                }}
                value={quantity}
                type="number"
                onChange={(event) => changeQuantity(event)}
              />
              <IconButton color="primary" onClick={plusExtra}>
                <AddCircleSharpIcon />
              </IconButton>
              <IconButton>
                <CloseSharpIcon />
              </IconButton>
            </ButtonGroup>
            <div className="my-auto ">
              <div className="text-lg">
                {`${
                  defaultExtraPackages.find((x) => x._id === data._id)!
                    .package_name
                }`}
              </div>
              <Typography variant="body2">{`(${getPriceString(
                defaultExtraPackages.find((x) => x._id === data._id)!.price
              )} - ${
                defaultExtraPackages.find((x) => x._id === data._id!)!
                  .event_guest
              } khách - ${
                defaultExtraPackages.find((x) => x._id === data._id)!
                  .file_storage
              } Mb)`}</Typography>
            </div>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: '2', justifyContent: 'space-between'}}>
            <div className="flex gap-3">
              <div className="my-auto m-2">
                <DensityLarge />
              </div>
              <Typography variant="body1">
                <div className="text-lg">
                  {getPriceString(
                    defaultExtraPackages.find((x) => x._id === data._id)?.price *
                      quantity
                  )}
                </div>
                <Typography variant="body2">
                  {`(${
                    defaultExtraPackages.find((x) => x._id === data._id)
                      ?.event_guest * quantity
                  } khách - ${
                    defaultExtraPackages.find((x) => x._id === data._id)
                      ?.file_storage * quantity
                  } Mb)`}
                </Typography>
              </Typography>
            </div>
            {data?.add_type === "new" && (
              <IconButton onClick={() => removeComponent(data._id)}>
                <Delete color="error" />
              </IconButton>
            )}
          </Box>
        </TableCell>
      ) : (
        <>
          <TableCell>
            {data?.add_type === "new" && (
              <IconButton onClick={() => removeComponent(data._id)}>
                <RemoveCircleOutline color="error" />
              </IconButton>
            )}
            {data?.add_type === "old" && (
              <IconButton>
                <Replay color="error" />
              </IconButton>
            )}
          </TableCell>
          <TableCell>
            <div className="my-auto ">
              <div className="text-lg">
                {`${
                  defaultExtraPackages.find((x) => x._id === data._id)!
                    .package_name
                }`}
              </div>
              <Typography variant="body2">{`(${getPriceString(
                defaultExtraPackages.find((x) => x._id === data._id)!.price
              )} - ${
                defaultExtraPackages.find((x) => x._id === data._id!)!
                  .event_guest
              } khách - ${
                defaultExtraPackages.find((x) => x._id === data._id)!
                  .file_storage
              } Mb)`}</Typography>
            </div>
          </TableCell>
          <TableCell>
            <div className="my-auto">
              <CloseSharpIcon />
            </div>
          </TableCell>
          <TableCell>
            <ButtonGroup variant="text" size="small">
              <IconButton color="primary" onClick={minusExtra}>
                <RemoveCircleSharpIcon />
              </IconButton>
              <Box>
                <TextField
                  size="small"
                  className="w-[50px]"
                  sx={{
                    input: { textAlign: "center", p: 1 },
                    "input[type=number]::-webkit-inner-spin-button": {
                      appearance: "none",
                    },
                    "input[type=number]::-webkit-outer-spin-button ": {
                      appearance: "none",
                    },
                  }}
                  value={quantity}
                  type="number"
                  onChange={(event: any) => changeQuantity(event)}
                />
              </Box>
              <IconButton color="primary" onClick={plusExtra}>
                <AddCircleSharpIcon />
              </IconButton>
            </ButtonGroup>
          </TableCell>
          <TableCell>
            <div className="my-auto">
              <DensityLarge />
            </div>
          </TableCell>
          <TableCell>
            <div className="my-auto ">
              <Typography variant="body1">
                <div className="text-lg">
                  {getPriceString(
                    defaultExtraPackages.find((x) => x._id === data._id)
                      ?.price * quantity
                  )}
                </div>
                <div>
                  <Typography variant="body2">
                    {`(${
                      defaultExtraPackages.find((x) => x._id === data._id)
                        ?.event_guest * quantity
                    } khách - ${
                      defaultExtraPackages.find((x) => x._id === data._id)
                        ?.file_storage * quantity
                    } Mb)`}
                  </Typography>
                </div>
              </Typography>
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default ExtraPackage;
export type { ExtraPackageProps };
