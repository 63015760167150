import { useEffect, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { OrderStats } from "../../interface/order";
import { setReload } from "../../redux/order";
import { CachedOutlined, SyncAltSharp } from "@mui/icons-material";
import moment from "moment";
import EmailConfigForm from "./component/EmailConfigForm";

const EmailConfig = () => {

    return (
        <EmailConfigForm/>
    )

}

export default EmailConfig;