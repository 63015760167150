import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Divider,
  ButtonGroup,
  TextField,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "../../../interface/user";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getHighestRole } from "../../../helper";
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { USER_ROLE } from "../../../configs/enum";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import orderService from "../../../services/order.service";
import { toast } from "react-toastify";
import { getPriceString } from "../../../helper";
import {
  FolderCopy,
  Mail,
  Person,
  Phone,
  RecentActors,
  Star,
} from "@mui/icons-material";
import ExtraPackage, { ExtraPackageProps } from "./ExtraPackage";
import AddExtraPackageModal from "./AddExtraPackageModal";
import { Order } from "../../../interface/order";
import { Package } from "../../../interface/package";
import userService from "../../../services/user.service";
import { useTheme } from "@mui/material/styles";

interface Props {
  open: boolean;
  onClose: any;
  getUsers: Function;
  user: User;
  extraPackages: any[];
  basePackages: any[];
}

const DetailUser = ({
  open,
  onClose,
  user,
  basePackages,
  extraPackages,
}: Props) => {
  const [user_detail, setUserDetail] = useState<User>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const getData = async () => {
    const respone = await userService.getById({ id: user._id });
    if (respone?.success) {
      setUserDetail(respone.data);
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        sx={{ zIndex: 10000 }}
        fullScreen={isMobile}
      >
        <DialogTitle>
          <div className="flex justify-between items-center">
            <span>Thông tin tài khoản</span>
            <IconButton
              sx={{ marginLeft: "1px" }}
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent className={"user-info px-[24px]"}>
          <Box>
            <div className="text-lg font-bold pb-4 mt-2">
              Thông tin người dùng
            </div>
            <div className={`grid grid-cols-1 ${user?.fullname || user?.phone ? 'md:grid-cols-3' : 'md:grid-cols-2'} gap-5 w-full justify-around pb-4`}>
              <div className="flex gap-3">
                <Avatar sx={{ width: 56, height: 56 }}>
                  <Mail />
                </Avatar>
                <div>
                  <div className="text-xl font-semibold text-slate-500">
                    Email
                  </div>
                  <div className="text-base">{user?.email}</div>
                </div>
              </div>

              <div className="flex gap-3">
                <Avatar sx={{ width: 56, height: 56 }}>
                  <Person />
                </Avatar>
                <div>
                  <div className="text-lg font-semibold text-slate-500">
                    Tên tài khoản
                  </div>
                  <div className="text-base">{user?.user_name}</div>
                </div>
              </div>
              {user?.fullname && (
                <div className="flex gap-3">
                  <Avatar sx={{ width: 56, height: 56 }}>
                    <RecentActors />
                  </Avatar>
                  <div>
                    <div className="text-lg font-semibold text-slate-500">
                      Họ tên
                    </div>
                    <div className="text-base">{user?.fullname}</div>
                  </div>
                </div>
              )}
              {user?.phone && (
                <div className="flex gap-3">
                  <Avatar sx={{ width: 56, height: 56 }}>
                    <Phone />
                  </Avatar>
                  <div>
                    <div className="text-lg font-semibold text-slate-500">
                      Số điện thoại
                    </div>
                    <div className="text-base">{user?.phone}</div>
                  </div>
                </div>
              )}
              {user_detail?.limitStorage && (
                <div className="flex gap-3">
                  <Avatar sx={{ width: 56, height: 56 }}>
                    <FolderCopy />
                  </Avatar>
                  <div>
                    <div className="text-lg font-semibold text-slate-500">
                      Dung lượng
                    </div>
                    <div className="text-base">
                      {user_detail?.limitStorage} Mb
                    </div>
                  </div>
                </div>
              )}
              {user_detail?.limitGuest && (
                <div className="flex gap-3">
                  <Avatar sx={{ width: 56, height: 56 }}>
                    <RecentActors />
                  </Avatar>
                  <div>
                    <div className="text-lg font-semibold text-slate-500">
                      Số khách mời
                    </div>
                    <div className="text-base">
                      {user_detail?.limitGuest} người
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Divider />
            <div className="text-lg font-bold pb-2 mt-4">
              Thông tin gói dịch vụ đang sử dụng
            </div>
            <div className="text-base font-semibold ml-2 pb-2">{`Gói dịch vụ chính: `}</div>
            {user?.user_package?.base ? (
              <Box width="100%" overflow="auto">
                <Table size="small" sx={{ border: "none", mb: 2 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white",
                          minWidth: "120px",
                        }}
                      >
                        Gói dịch vụ
                      </TableCell>
                      <TableCell>Dung lượng (Mb)</TableCell>
                      <TableCell>Khách mời (khách)</TableCell>
                      <TableCell>Ngày bắt đầu gói</TableCell>
                      <TableCell>Ngày hết hạn gói</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          position: { md: "relative", xs: "sticky" },
                          left: "0px",
                          zIndex: "9",
                          backgroundColor: "white",
                        }}
                        align="left"
                      >
                        {user?.user_package?.base?.package_name ?? ""}
                      </TableCell>
                      <TableCell>
                        {user?.user_package?.base?.file_storage ?? ""}
                      </TableCell>
                      <TableCell>
                        {user?.user_package?.base?.event_guest ?? ""}
                      </TableCell>
                      <TableCell>
                        {user?.user_package?.base?.startedAt &&
                          moment(user?.user_package?.base?.startedAt).format(
                            "DD/MM/Y"
                          )}
                      </TableCell>
                      <TableCell>
                        {user?.user_package?.base?.expiredAt &&
                          moment(user?.user_package?.base?.expiredAt).format(
                            "DD/MM/Y"
                          )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <div className="text-base ml-2 pb-2 italic text-center text-slate-500">
                Chưa chọn gói dịch vụ chính !
              </div>
            )}
            <div className="text-base ml-2 pb-2 font-semibold">
              Gói dịch vụ phụ
            </div>
            {(user?.user_package?.extra || []).length > 0 ? (
              <Box width="100%" overflow="auto">
                <Table size="small" sx={{ border: "none", mb: 2 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        width="5%"
                        sx={{ display: isMobile ? "none" : "default" }}
                      >
                        STT
                      </TableCell>
                      <TableCell
                        width="20%"
                        sx={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white",
                          minWidth: "120px",
                        }}
                      >
                        Gói dịch vụ
                      </TableCell>
                      <TableCell width="15%" align="center">
                        Số lượng
                      </TableCell>
                      <TableCell width="15%">Dung lượng (Mb)</TableCell>
                      <TableCell width="15%">Khách mời (khách)</TableCell>
                      <TableCell width="15%">Ngày bắt đầu</TableCell>
                      <TableCell width="15%">Ngày hết hạn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(user?.user_package?.extra || []).map(
                      (x: any, index: number) => (
                        <TableRow>
                          <TableCell
                            sx={{ display: isMobile ? "none" : "default" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              position: { md: "relative", xs: "sticky" },
                              left: "0px",
                              zIndex: "9",
                              backgroundColor: "white",
                            }}
                          >
                            {x?.package_name ?? ""}
                          </TableCell>
                          <TableCell align="center">{x.quantity}</TableCell>
                          <TableCell>{x?.file_storage}</TableCell>
                          <TableCell>{x?.event_guest}</TableCell>
                          <TableCell>
                            {x.startedAt &&
                              moment(x.startedAt).format("DD/MM/Y")}
                          </TableCell>
                          <TableCell>
                            {x.expiredAt &&
                              moment(x.expiredAt).format("DD/MM/Y")}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <div className="text-base ml-2 pb-2 italic text-center text-slate-500">
                Chưa có gói dịch vụ phụ nào!
              </div>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailUser;
