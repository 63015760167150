import { AxiosResponse } from 'axios';
import axios from '../configs/axios';
import { HTTP_STATUS_CODE } from '../configs/constant';

const templateService = {
	getTemplateStats: async() => {
		try {
			const result = await axios.get('/template/stats',)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
}

export default templateService