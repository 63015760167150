import { ROLE_ORDER } from "./configs/constant";
import { LIMIT_RESOURCE, USER_STATUS, USER_ROLE, PACKAGE_TYPE } from "./configs/enum";

export const removeAllSpace = (textInput: string) => {
	if (typeof textInput !== 'string' || !textInput) return '';
	const arrText = textInput.split(' ');
	let result = '';
	for (let i = 0; i < arrText.length; i++) {
		if (arrText[i] !== '') {
			result += arrText[i];
		}
	}
	return result.trim();
};

export function getHighestRole(roles: USER_ROLE[]) {
	if (roles && !roles.length) {
		return USER_ROLE.NORMAL;
	}
	let highestRole = USER_ROLE.GUEST;
	if (roles?.length) {
		for (const role of roles) {
			if (ROLE_ORDER[role as keyof typeof ROLE_ORDER] > ROLE_ORDER[highestRole  as keyof typeof ROLE_ORDER]) {
				highestRole = role;
			}
		}
	}
	return highestRole;
}

export function getRoleName(role: string) {
	switch (role) {
		case USER_ROLE.NORMAL:
			return 'Tài khoản thường';
		case USER_ROLE.VIP:
			return 'Tài khoản VIP';
		case USER_ROLE.GUEST:
			return 'Tài khoản khách';
		default:
			return '';
	}
}

export function getUserStatus(status: string | undefined) {
	switch (status) {
		case USER_STATUS.VERIFIED:
			return 'Đã xác thực';
		case USER_STATUS.UNVERIFIED:
			return 'Chưa xác thực';
		default:
			return '';
	}
}

export function getResourceName(resource: LIMIT_RESOURCE) {
	switch (resource) {
		case LIMIT_RESOURCE.EVENT:
			return 'Sự kiện';
		case LIMIT_RESOURCE.EVENT_GUEST:
			return 'Khách mời';
		case LIMIT_RESOURCE.FILE_STORAGE:
			return 'Dung lượng file';
		default:
			return '';
	}
}

export function getPackageName(packageName: string) {
	switch (packageName) {
		case USER_ROLE.GUEST:
			return 'Tài khoản khách';
		case USER_ROLE.NORMAL:
			return 'Tài khoản thường';
		case USER_ROLE.VIP:
			return 'Tài khoản VIP';
		default:
			return packageName;
	}
}

export function getPackageType(packageType: PACKAGE_TYPE) {
	switch (packageType) {
		case PACKAGE_TYPE.BASE:
			return 'Gói chính';
		case PACKAGE_TYPE.EXTRA:
			return 'Gói phụ';
		default:
			return '';
	}
}

export function getPriceString(price: number, defaultString: string = 'Miễn phí') {
	if (!price) {
		return defaultString;
	}
	else {
		return `${price.toLocaleString()}`;
	}
}

export function getResourceUnit(resource: LIMIT_RESOURCE) {
	switch (resource) {
		case LIMIT_RESOURCE.EVENT:
			return 'Sự kiện';
		case LIMIT_RESOURCE.EVENT_GUEST:
			return 'Khách mời';
		case LIMIT_RESOURCE.FILE_STORAGE:
			return 'Mb';
		default:
			return '';
	}
}

export function generateDefaultPassword() {
	const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const number = '0123456789';
	const lowercase = 'abcdefghijklmnopqrstuvwxyz';
	let text = '';
    for (let i = 0; i < 6; i++) {
        text += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
    }
	text += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
	text += number.charAt(Math.floor(Math.random() * number.length));
    return text;
}

export function checkRegexEmail (email: string){
	const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	if(pattern.test(email))
	{
		return true;
	}

	return false;
}

export const validatePhone = (phone: string) => {
    // const re = /^\+?([0-9]{1,5})\)?[-. ]?([0-9]{1,5})[-. ]?([0-9]{1,5})$/;
    // return re.test(phone);
    // const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	if(!phone){
		return true;
	}
    const phoneno = /(((\+|)84)|0)(2|3|5|7|8|9)+([0-9]{8})$/; 
    if (phone.match(phoneno)) {
        return true;
    }
    return false;
};