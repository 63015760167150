import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from "react";
import { emailRegex, passwordRegex, usernameRegex } from "../../../configs/constant";
import { generateDefaultPassword, validatePhone } from "../../../helper";
import userService from "../../../services/user.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/commonSlice";
import ConfirmModal from "../../component/ConfirmModal";

interface Props {
	open: boolean,
	onClose: any,
	getUsers: Function
}

export default function CreateUserDialog({ open, onClose, getUsers }: Props) {
	const dispatch = useDispatch();
	const defaultPassword = generateDefaultPassword();
	const [username, setUsername] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [fullName, setFullName] = useState('');
	const [password, setPassword] = useState(defaultPassword);
	const [passwordError, setPasswordError] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState(defaultPassword);
	const [passwordConfirmError, setPasswordConfirmError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);	

	useEffect(() => {
		if (!open) {
			resetAllState();
		}
	}, [open])

	const resetAllState = () => {
		const defaultPassword = generateDefaultPassword();
		setUsername('')
		setUsernameError('')
		setEmail('')
		setEmailError('')
		setPassword(defaultPassword)
		setPasswordError('')
		setPasswordConfirm(defaultPassword)
		setPasswordConfirmError('')
		setPhoneError('')
		setPhone('')
		setFullName('')
		setShowPassword(false)
	}

	const handleSubmit = async () => {
		dispatch(setLoading(true));
		const result = await userService.createUser({ username, email, password, fullname: fullName, ...(phone && {phone: phone}) });
		if (result.success) {
			toast.success('Tạo người dùng thành công');
			await getUsers();
			onClose();
		}
		else {
			if((result as any)?.data.code === "VALIDATE_FAIL")
			{
				const field = (result as any).data.data;
				if(field['phone']){
					setPhoneError(field['phone'].msg)
				}	
				if(field['email']){
					setEmailError(field['email'].msg)
				}	
				if(field['username']){
					setUsername(field['username'].msg)
				}	
			}
			if ((result as any)?.data?.data?.field) {
				const field = (result as any).data.data.field;
				switch (field) {
					case 'email':
						setEmailError((result.data as any).message);
						break;
					case 'username':
						setUsernameError((result.data as any).message);
						break;
				}
			}
			else toast.error('Tạo người dùng thất bại. Vui lòng thử lại.');
		}
		dispatch(setLoading(false));
	};

	const onCancelConfirm = () =>{
		setOpenConfirm(false);
	}
	const onPassConfirm = async () =>{
		await handleSubmit();
		setOpenConfirm(false);
	}

	return <>
		<Dialog open={open} maxWidth="xs" fullWidth>
			<DialogTitle>
				<div className="flex justify-between items-center">
					<span>Tạo người dùng mới</span>
					<IconButton
						sx={{ marginLeft: '1px' }}
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</div>	
			</DialogTitle>
			<DialogContent className={"resource-info px-[24px]"}>		
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="email-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (!value) {
								setEmailError('Email không được để trống!')
							}
							else if (!value.match(emailRegex)) {
								setEmailError('Email không hợp lệ!')
							}
							else {
								setEmailError('')
							}
							setEmail(e.target.value)	
						}}
						label="Email"
						value={email}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ emailError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="username-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (!value) {
								setUsernameError('Tên đăng nhập không được để trống!')
							}
							else if (!value.match(usernameRegex)) {
								setUsernameError('Tên đăng nhập gồm 6-20 ký tự, không chứa ký tự đặc biệt!')
							}
							else {
								setUsernameError('')
							}
							setUsername(e.target.value)	
						}}
						label="Tên đăng nhập"
						value={username}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ usernameError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						type={showPassword ? 'text' : 'password'}
						id="password-input"
						InputProps={{
							endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
									{
										showPassword ? <VisibilityOffIcon/> : <VisibilityIcon  />
									}
								</IconButton>
						}}
						onChange={(e: any) => {
							const value = e.target.value;
							if (!value) {
								setPasswordError('Mật khẩu không được để trống!')
							}
							else if (!value.match(passwordRegex)) {
								setPasswordError('Mật khẩu gồm 6-20 ký tự, có chứa chữ thường, chữ in hoa, và ký tự số!')
							}
							else {
								setPasswordError('')
							}
							if (e.target.value !== passwordConfirm) {
								setPasswordConfirmError('Xác nhận mật khẩu phải trùng với mật khẩu!')
							}
							setPassword(e.target.value)	
						}}
						label="Mật khẩu"
						value={password}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ passwordError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						type={showPassword ? 'text' : 'password'}
						id="password-confirm-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (value !== password) {
								setPasswordConfirmError('Xác nhận mật khẩu phải trùng với mật khẩu!')
							}
							else {
								setPasswordConfirmError('')
							}
							setPasswordConfirm(e.target.value)	
						}}
						InputProps={{
							endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
								{
									showPassword ? <VisibilityOffIcon/> : <VisibilityIcon  />
								}
							</IconButton>
						}}
						label="Xác nhận mật khẩu"
						value={passwordConfirm}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ passwordConfirmError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						fullWidth
						id="fullname-input"
						onChange={(e: any) => {
							setFullName(e.target.value)	
						}}
						label="Họ và tên"
						value={fullName}
					/>
				</div>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						fullWidth
						id="phone-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (!validatePhone(value)) {
								setPhoneError('Số điện thoại chưa đúng định dạng!')
							}
							else {
								setPhoneError('')
							}
							setPhone(e.target.value)	
						}}
						label="Số điện thoại"
						value={phone}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ phoneError }
				</span>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Quay lại
				</Button>
				<Button
					variant="contained"
					onClick={()=>{setOpenConfirm(true)}}
					disabled={ !!(
						usernameError
						|| emailError
						|| phoneError
						|| passwordError
						|| passwordConfirmError
						|| !username
						|| !email
						|| !password
						|| !passwordConfirm
					) }>
					Xác nhận
				</Button>
			</DialogActions>
			<ConfirmModal
				open={openConfirm}
				data={null}
				onCancel={onCancelConfirm}
				onPass={onPassConfirm}
				title={'Xác nhận tạo tài khoản'}
				key={null}
				content={
					<Typography variant="body1">
						Bạn có chắc muốn tạo tài khoản cho người dùng <b>{username}</b> không ?
					</Typography>
				}
			/>
		</Dialog>
	</>
}