import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type appState = {
  appState: string;
  loading: boolean;
  open: boolean;
};

const initialState: appState = {
  appState: "",
  loading: false,
  open: false,
};

export const commonSlice = createSlice({
  name: "commonState",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<string>) => {
      state.appState = action.payload;
	},
	setLoading: (state, action: PayloadAction<boolean>) => {
		state.loading = action.payload;
	},
	setOpen: (state, action: PayloadAction<boolean>) => {
		state.open = action.payload;
	},
  
  }
});

export const {
  setAppState,
  setLoading,
  setOpen
} = commonSlice.actions;

export default commonSlice.reducer;