import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
	Divider,
	ButtonGroup,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { User } from "../../../interface/user";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getHighestRole, getPriceString } from "../../../helper";
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { USER_ROLE } from "../../../configs/enum";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment, now } from "moment";
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import userService from "../../../services/user.service";
import { toast } from "react-toastify";
import { RemoveCircleOutline, Replay, Star } from "@mui/icons-material";

interface Props {
	open: boolean,
	onClose: any,
	onAdd: Function,
	user: User,
	defaultExtraPackages: any[],
	extraPackages: any[],
	expiredDate: Date|null
}

const AddExtraPackageModal = ({open, onClose, onAdd, defaultExtraPackages, extraPackages, expiredDate} : Props)=>{
	const uniquePackage = defaultExtraPackages.map(x=>x._id).filter(val => !extraPackages.map(x=>x._id).includes(val));
	defaultExtraPackages = defaultExtraPackages.map(x=>{
		const diff_day = moment(expiredDate).endOf('days').diff(moment(new Date()),'days');
		const cycle_day = moment(new Date()).endOf('days').add(x.cycle, 'months').diff(moment(new Date()),'days');
		// console.log(`${moment(expiredDate).format('DD-MM-Y')} - ${moment(new Date()).add(x.cycle, 'months').format('DD-MM-Y')} ${diff_day}+ ${cycle_day} +${(x.price / cycle_day)*diff_day}` );
		x['price_after'] = Math.ceil((x.price / cycle_day)*diff_day);
		return x;
	})
	const [selectedPackage, setSelectPackage] = useState(uniquePackage[0]);
	const [quantity, setQuantity] = useState(1);

	useEffect(()=>{
		if(open)
		{
			setQuantity(1);
			setSelectPackage(uniquePackage[0]);
			// console.log(uniquePackage);
			// console.log(defaultExtraPackages);
		}
	},[open])

	const handleSubmit = ()=>{
		if(open)
		{
			onAdd({
				_id: selectedPackage,
				quantity, 
				add_type: 'new',
				type: 'extra',
				package_name: defaultExtraPackages.find(x=>x._id === selectedPackage)?.package_name ?? '',
				price_after: defaultExtraPackages.find(x=>x._id === selectedPackage)?.price ?? 0,
				event_guest: defaultExtraPackages.find(x=>x._id === selectedPackage)?.event_guest ?? 0,
				file_storage: defaultExtraPackages.find(x=>x._id === selectedPackage)?.file_storage ?? 0,
				cycle: defaultExtraPackages.find(x=>x._id === selectedPackage)?.cycle ?? 0,
			});
			setQuantity(1);
			setSelectPackage(uniquePackage[0]);
		}
		onClose();
	}

	const handleChangeRole = (event: SelectChangeEvent) => {
		setSelectPackage(event.target.value);
	}

	return <Dialog open={open} maxWidth="sm" fullWidth>
		<DialogTitle>
			<div className="flex justify-between items-center">
				<span>Thêm mới gói dịch vụ phụ</span>
				<IconButton
					sx={{ marginLeft: '1px' }}
					edge="start"
					color="inherit"
					onClick={onClose}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
			</div>	
		</DialogTitle>
		<Divider/>
		<DialogContent className={"user-info px-[24px]"}>
			<FormControl fullWidth sx={{my: 2}}>
				<InputLabel>Gói dịch vụ phụ</InputLabel>
				<Select
					label="Gói dịch vụ phụ"
					labelId="select-label"
					id="simple-select"
					value={selectedPackage}
					onChange={handleChangeRole}
				>
					{defaultExtraPackages.filter(x=>uniquePackage.includes(x._id)).map((x: any)=>
						<MenuItem value={x._id}>
							{x.package_name + ` (${getPriceString(x.price_after)} VND - ${x.event_guest} khách - ${x.file_storage} Mb)`} 
						</MenuItem>)}
				</Select>
			</FormControl>
			<FormControl fullWidth sx={{my: 2}}>
				<TextField
					label='Số lượng'
					value={quantity}
					type="number"
					InputProps={{
						inputProps: {
							max: 100, min: 1 
						}
					}}
					onChange={(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
						if(+event.target.value > 0 && +event.target.value < 100)
						{
							setQuantity(+event.target.value); 
						} else if( +event.target.value <= 0 )
						{
							setQuantity(1); 
						}else if( +event.target.value >= 100 )
						{
							setQuantity(100); 
						}
					}}
				/>
			</FormControl>
			<Typography><b>Thời gian hết hạn: </b>{moment(expiredDate).format('DD/MM/YYYY')}</Typography>
		</DialogContent>
		<DialogActions>
			<Button variant="outlined" onClick={()=>{
				setQuantity(0);
				setSelectPackage(uniquePackage[0]);
				onClose();
			}}>
				Quay lại
			</Button>
			<Button
				variant="contained"
				onClick={handleSubmit}
				// disabled={
				// 	!enableButton
				// }
				>
				Xác nhận
			</Button>
		</DialogActions>
	</Dialog>
}

export default AddExtraPackageModal;