import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
    IconButton,
    Collapse,
    Typography,
    TableFooter,
    ButtonGroup,
    Chip,
    Tooltip,
    Grid
} from "@mui/material";
import { getPriceString } from "../../../helper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Order, OrderStats } from "../../../interface/order";
import { Fragment, useEffect, useState } from "react";
import adminService from "../../../services/admin.service";
// import UpdateOrderDialog from './UpdateOrderDialog'
import { LIMIT_RESOURCE } from "../../../configs/enum";
import { toast } from "react-toastify";
import moment from "moment";
import PaidIcon from '@mui/icons-material/Paid';
import { ShoppingBasket, Person } from "@mui/icons-material";

function StatsticsAdmin () {

    const defaultAdminStats = {
        total_count: 0,
        active_count:0,
        inactive_count:0,
    }

    const [adminStats, setAdminStats] = useState(defaultAdminStats);

    const getData = async ()=>{
        const res = await adminService.getStats();
        if(res?.success){
            setAdminStats(res.data[0]);
        }
    }

    useEffect(()=>{
        getData()
    },[])

    return (
    <Grid container>
       <Grid item md={2.9} margin={1} className="bg-blue-500 rounded-lg p-2 shadow-lg">
            <div className="text-white text-xl font-semibold">TỔNG SỐ TÀI KHOẢN</div> 
           <Box className="flex justify-between">
                <Person className="my-auto" htmlColor="white"/>
                <div className="text-white flex items-end"><div className="text-4xl font-bold">{adminStats?.total_count}</div><div className="ml-1"></div></div>
           </Box>
       </Grid>
       <Grid item md={2.9} margin={1} className="bg-green-500 rounded-lg p-2 shadow-lg">
            <div className="text-white text-xl font-semibold">ĐANG HOẠT ĐỘNG</div> 
            <Box className="flex justify-between">
                    <Person className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-4xl font-bold ">{adminStats?.active_count}</div><div className="ml-1"></div></div>
            </Box>
       </Grid>
       <Grid item md={2.9} margin={1} className="bg-red-500 rounded-lg p-2 shadow-lg">
            <div className="text-white text-xl font-semibold">DỪNG HOẠT ĐỘNG</div> 
            <Box className="flex justify-between">
                    <Person className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-4xl font-bold ">{adminStats?.inactive_count}</div><div className="ml-1"></div></div>
            </Box>
       </Grid>
    </Grid>
    )
} 

export default StatsticsAdmin;