import { useState, useEffect } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import userService from "../../../services/user.service";
import { Box } from "@mui/material";

const chartSetting = {
    xAxis: [
      {
        label: '',
      },
    ],
    height: 400,
  };
  

function UserPackageStats () {

    const [stats, setStats] = useState<any[]>([])

    const getData = async () =>{
        const resultStats = await userService.getUserPackageStats();
		if (resultStats?.success) {
			const { data } = resultStats;
           
			setStats(data.map((x: any)=>({
                package_name: x.package_infor[0]?.package_name ?? 'Không rõ',
                ...x,
            })));
            // setStats([])
		} 
    }

    useEffect(()=>{
        getData()
    },[])

    const valueFormatter = (value: any) => `${value} người`;

    return (<div className="w-full rounded-lg shadow-md bg-white mt-4 h-full mb-2">
    <div className="text-center text-xl font-bold px-3 pt-4 ml-4">Thống kê người dùng sử dụng gói dịch vụ</div>
    <BarChart
        yAxis={[{ scaleType: 'band', data: stats.length > 0 ? stats.map(x=>x.package_name??null) : [] }]}
        series={[{ data: stats.length > 0 ? stats.map(x=>x.count??0) : [0], valueFormatter, color:'#1976D3' }]}
        layout="horizontal"
        margin={{left: 200}}
        {...chartSetting}
    />
    </div>)
}

export default UserPackageStats;