import { useEffect, useState } from "react";
import { List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setOpen } from "../../redux/commonSlice";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

const drawerWidth = 300;

interface Props {
  window?: () => Window;
}

export default function Sidebar(props: Props) {
  const dispatch = useDispatch();
  const { window } = props;
  const mobileOpen = useSelector((state: RootState) => state.common.open);
  const selectAdmin = useSelector((state: RootState) => state.auth.admin);
  const theme = useTheme();
  // const [isClosing, setIsClosing] = useState(false);

  const drawer = (
    <div
      style={{
        height: "100%",
        backgroundColor: colorConfigs.sidebar.bg,
        color: colorConfigs.sidebar.color,
      }}
    >
      <List disablePadding>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              (!route.role || selectAdmin.role === route.role) && (
                <SidebarItem item={route} key={index} />
              )
            )
          ) : null
        )}
      </List>
    </div>
  );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;
  // console.log("container -----", container);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleDrawerTransitionEnd = () => {
    dispatch(setOpen(true));
    // setIsClosing(false);
  };

  const handleDrawerClose = () => {
    dispatch(setOpen(!mobileOpen));
    // setMobileOpen(false);
  };

  // const handleDrawerToggle = () => {
  //   if (!isClosing) {
  //     setMobileOpen(!mobileOpen);
  //   }
  // };

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        // PaperProps={{ style: { position: "absolute" } }}
        // ModalProps={{
        //   container: document.getElementById('drawer-container'),
        //   style: { position: 'absolute' },
        //   // keepMounted: true,
        // }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            borderColor: "none",
            boxSizing: "border-box",
            width: "300px",
          },
        }}
      >
        <Toolbar sx={{ backgroundColor: "#d3d3d3", width: "100%" }}>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <img src={assets.images.logo} width={150} />
          </Stack>
        </Toolbar>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            borderColor: "none",
            boxSizing: "border-box",
            width: "300px",
          },
        }}
        open
      >
        <Toolbar sx={{ backgroundColor: "#d3d3d3" }}>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <img src={assets.images.logo} width={150} />
          </Stack>
        </Toolbar>
        {drawer}
      </Drawer>
    </Box>
  );
}
