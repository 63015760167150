import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import Login from "../common/LoginModal";
import { useEffect, useState, useRef } from "react";
import { setAdmin, setShowTokenExpireMessage } from "../../redux/auth";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../common/Loading";
import "react-toastify/dist/ReactToastify.css";
import authService from "../../services/auth.service";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { setOpen } from '../../redux/commonSlice';

const MainLayout = () => {
  const reloadRef = useRef(null);
  const matchDownMd = useMediaQuery("(max-width:1440px)");
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const isShowTokenExpireMessage = useSelector(
    (state: RootState) => state.auth.showTokenExpiredMessage
  );
  const loading = useSelector((state: RootState) => state.common.loading);

  useEffect(() => {
    if (isShowTokenExpireMessage) {
      toast.error("Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại");
    }
    setTimeout(() => {
      dispatch(setShowTokenExpireMessage(false));
    }, 200);
  }, [isShowTokenExpireMessage]);

  useEffect(() => {
    whoAmI();
  }, []);

  const whoAmI = async () => {
    const result = await authService.whoAmI();
    if (result.success) {
      dispatch(setAdmin(result.user));
    }
  };
  const dispatch = useDispatch();

  const onSetModal = () => {};

  useEffect(() => {
    // dispatch({ type: SET_MENU, opened: !matchDownMd });
    onSetModal();
  }, [matchDownMd]);

  const onShow = () => {
	dispatch(setOpen(true));
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 10000 }}
        draggable
        pauseOnHover={true}
        hideProgressBar={true}
        newestOnTop={false}
        pauseOnFocusLoss={true}
        closeOnClick
        limit={3}
      />
      {!isLoggedIn ? (
        <Login />
      ) : (
        <>
          {loading && <Loading />}
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Topbar onShow={onShow} />
            <Sidebar/>
            <Box
              component="main"
              sx={{
                backgroundColor: colorConfigs.mainBg,
                flexGrow: 1,
                p: 3,
                width: `calc(100% - ${300}px)`,
                overflowX: "hidden",
                minHeight: "100vh",
              }}
            >
              <Toolbar />
              <Outlet />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default MainLayout;
