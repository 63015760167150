import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Button,
	IconButton,
	ButtonGroup,
	Tooltip,
	Chip,
} from "@mui/material";
import { getHighestRole, getRoleName, getUserStatus } from "../../../helper";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import moment from "moment";
import { setQuery } from "../../../redux/admin";
import { useEffect, useState } from "react";
import { User } from "../../../interface/user";
import { USER_ROLE, USER_STATUS } from "../../../configs/enum";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SendMailModal from "./SendMailDialog";
import SetPasswordDialog from "./SetPasswordDialog";
import EditIcon from '@mui/icons-material/Edit';
import { Admin } from "../../../interface/admin";
import ToggleActiveDialog from "./ToggleActiveDialog";

const AdminTable = ({ getUsers , basePackages, extraPackages}: { getUsers: Function, basePackages: any[], extraPackages: any[]}) => {
	const users = useSelector((state: RootState) => state.admin.admins)
	const query = useSelector((state: RootState) => state.admin.query)
	const total = useSelector((state: RootState) => state.admin.total)
	const [open, setOpen] = useState(false);
	const [openStatus, setOpenStatus] = useState(false);
	const [openSendMailModal, setOpenSendMailModal] = useState(false);
	const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);
	const dispatch = useDispatch()
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: theme.palette.primary.main,
		  color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 14,
		},
	}));

	const [selectedUser, setSelectedUser] = useState(null as unknown as any)

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
		  backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
		  border: 0,
		},
	}));

	const handleChangePage = (e: any, page: any) => {
		dispatch(setQuery({
			...query,
			page: page + 1
		}))
	}

	const handleChangeRowsPerPage = (data: any) => {
		dispatch(setQuery({
			...query,
			page: 1,
			limit: data.target.value
		}))
	}

	const labelDisplayedRows = () => {
		return `Trang ${query.page}/${Math.ceil(total / query.limit)}`; 
	}
	
  	return (
		<>
			<SetPasswordDialog
				open={openSetPasswordModal}
				onClose={() => setOpenSetPasswordModal(false)}
				user={selectedUser}
			/>
			<ToggleActiveDialog
				open={openStatus}
				onClose={() => setOpenStatus(false)}
				resourceAdmin={selectedUser}
				getAdmins={getUsers}
			/>
			<TableContainer component={Paper} className={"mt-6"} >
				<Table sx={{ minWidth: 650 }} aria-label="user table">
					<TableHead>
						<TableRow>
							<StyledTableCell>STT</StyledTableCell>
							<StyledTableCell align="left">Tên tài khoản</StyledTableCell>
							<StyledTableCell align="left">Trạng thái tài khoản</StyledTableCell>
							<StyledTableCell align="left">Ngày tạo</StyledTableCell>
							<StyledTableCell align="left"></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{users?.map((user, index) => (
						<StyledTableRow
							key={user._id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<StyledTableCell component="th" scope="row" sx={{ width: '100px'}}>
								{index + 1 + (query.page - 1) * query.limit}
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '400px'}}>{user.user_name}</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '400px'}}>
								<Chip
								 sx={{
									cursor: 'pointer',
								 }}
								 onClick={()=>{
									 setOpenStatus(true)
									 setSelectedUser(user)
								 }}
								 color={user.status === 'inactive' ? 'error' : 'success'} label={user.status === 'inactive' ? 'Không hoạt động' : 'Đang hoạt động'}></Chip>
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '400px'}}>{moment(new Date(user.createdAt)).format('DD-MM-YYYY HH:mm')}</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '200px'}}>
								<ButtonGroup>
									<Button
										variant="contained"
										startIcon={<EditIcon/>}
										size="small"
										color="info"
										onClick={() => {
											setOpenSetPasswordModal(true)
											setSelectedUser(user)
										}}>
										Cấp lại mật khẩu
									</Button>
									{/* <Button
										variant="contained"
										startIcon={<InfoIcon/>}
										size="small"
										// disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
										onClick={() => openChangeStatusModal(index)}>
										Chi tiết
									</Button> */}
								</ButtonGroup>
							</StyledTableCell>
						</StyledTableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				labelRowsPerPage="Hiển thị"
				labelDisplayedRows={labelDisplayedRows}
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={total}
				rowsPerPage={query.limit}
				page={query.page - 1}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</>

	);
};

export default AdminTable;