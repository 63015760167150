import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import packageService from "../../../services/packages.service";
import { setLoading } from "../../../redux/commonSlice";
import { PACKAGE_TYPE, USER_ROLE } from "../../../configs/enum";
import { getRoleName } from "../../../helper";
import ConfirmModal from "../../component/ConfirmModal";

interface Props {
	open: boolean,
	onClose: any,
	getPackages: Function,
}

const CreatePackageDialog = ({open, onClose, getPackages} : Props) => {
	const [eventGuestInput, setEventGuestInput] =  useState('')
	const [fileStorageInput, setFileStorageInput] =  useState('')
	const [priceInput, setPriceInput] =  useState('')
	const [cycle, setCycle] =  useState('');
	const [nameInput, setNameInput] = useState('')
	const [openConfirm, setOpenConfirm] = useState(false);	
	const [packageType, setPackageType] = useState(PACKAGE_TYPE.EXTRA);
	const [packageRole, setPackageRole] = useState(USER_ROLE.VIP);
	const dispatch = useDispatch();

	const onChangePackageType = (event: any) => {
		setPackageType(event.target.value);
	}

	const onChangePackageRole = (event: any) => {
		setPackageRole(event.target.value);
	}

	const handleSubmit = async () => {
		dispatch(setLoading(true));
		const result = await packageService.createPackage({
			package_name: nameInput,
			event_guest: +eventGuestInput,
			file_storage: +fileStorageInput,
			price: +priceInput,
			cycle: +cycle,
			type: packageType,
			role: packageType === PACKAGE_TYPE.BASE ? packageRole : undefined
		})
		if (result?.success) {
			await getPackages();
			toast.success('Thêm mới thành công.');
			onClose();
		}
		else {
			toast.error((result as any)?.error?.message || COMMON_ERROR_MSG)
		}
		dispatch(setLoading(false));
	}

	const onCancelConfirm = () =>{
		setOpenConfirm(false);
	}
	const onPassConfirm = async () =>{
		await handleSubmit();
		setOpenConfirm(false);
	}

	const [enableButton, setEnableButton] = useState(false);

	useEffect(() => {
		const enable = !!(
			eventGuestInput && fileStorageInput && priceInput && nameInput)
		setEnableButton(enable)
	}, [eventGuestInput, fileStorageInput, nameInput, priceInput])
		
	useEffect(() => {
		if (!open) {
			setEventGuestInput('')
			setFileStorageInput('')
			setPriceInput('')
			setNameInput('')
			setCycle('');
			setPackageType(PACKAGE_TYPE.EXTRA)
			setPackageRole(USER_ROLE.VIP)
		}
	}, [open])
	return (
		<Dialog open={open} maxWidth="xs" fullWidth>
			<DialogTitle>
				<div className="flex justify-between items-center">
					<span>Tạo gói mới</span>
					<IconButton
						sx={{ marginLeft: '1px' }}
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</div>	
			</DialogTitle>
			<DialogContent className={"resource-info px-[24px]"}>
				<div className="flex items-center">
					<TextField
						margin="normal"
						required
						fullWidth
						id="create-package-name-input"
						onChange={(e: any) => {
							setNameInput(e.target.value)
						}}
						label="Tên gói"
						value={nameInput}
					/>
				</div>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="event-guest-input"
						onChange={(e: any) => {
							if (!isNaN(+e.target.value)) {
								setEventGuestInput(e.target.value)	
							}
						}}
						label="Số lượng khách mời"
						value={eventGuestInput}
						InputProps={{
							endAdornment: <div className="whitespace-nowrap"> người </div>
						}}
					/>
				</div>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="file-storage-input"
						onChange={(e: any) => {
							if (!isNaN(+e.target.value)) {
								setFileStorageInput(e.target.value)	
							}
						}}
						label="Dung lượng lưu trữ"
						value={fileStorageInput}
						InputProps={{
							endAdornment: <div> Mb </div>
						}}
					/>
				</div>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="file-storage-input"
						onChange={(e: any) => {
							if (!isNaN(+e.target.value)) {
								setPriceInput(e.target.value)	
							}
						}}
						InputProps={{
							endAdornment: <div> VNĐ </div>
						}}
						label="Giá bán"
						value={priceInput}
					/>
				</div>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="file-storage-input"
						onChange={(e: any) => {
							if (!isNaN(+e.target.value)) {
								setCycle(e.target.value)	
							}
						}}
						InputProps={{
							endAdornment: <div> Tháng </div>
						}}
						label="Chu kì gói"
						value={cycle}
					/>
				</div>
				<div className="flex items-center w-full mt-5">
					<FormControl fullWidth>
						<InputLabel>
							Loại gói
						</InputLabel>
						<Select
							value={packageType}
							onChange={onChangePackageType}
							label='Loại gói'
						>
							{Object.values(PACKAGE_TYPE).map(p => <MenuItem value={p}>
									{ p === PACKAGE_TYPE.BASE ? 'Gói chính' : 'Gói phụ' }
								</MenuItem>)}
						</Select>
					</FormControl>
				</div>
				{
					packageType === PACKAGE_TYPE.BASE &&
					<div className="flex items-center w-full mt-5">
						<FormControl fullWidth>
							<InputLabel>
								Loại tài khoản
							</InputLabel>
							<Select
								value={packageRole}
								onChange={onChangePackageRole}
								label='Loại tài khoản'
							>
								{Object.values(USER_ROLE).map(r => <MenuItem value={r}>
										{ getRoleName(r) }
									</MenuItem>)}
							</Select>
						</FormControl>
					</div>
				}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Quay lại
				</Button>
				<Button
					variant="contained"
					onClick={()=>{
						setOpenConfirm(true);
					}}
					disabled={ !enableButton }
					>
					Xác nhận
				</Button>
			</DialogActions>
			<ConfirmModal
				open={openConfirm}
				data={null}
				onCancel={onCancelConfirm}
				onPass={onPassConfirm}
				title={'Xác nhận tạo gói dịch vụ'}
				key={null}
				content={
					<Typography variant="body1">
						Bạn có chắc muốn tạo gói dịch vụ <b>{nameInput}</b> không ?
					</Typography>
				}
			/>
		</Dialog>
	)
}

export default CreatePackageDialog;