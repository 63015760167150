import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Admin } from '../interface/admin'

const initialState = {
	isLoggedIn: true,
	admin: {} as Admin,
	showTokenExpiredMessage: false,
};

export const authSlice = createSlice({
	name: "authState",
	initialState,
	reducers: {
		setLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.isLoggedIn = action.payload;
		},
		setAdmin: (state, action: PayloadAction<any>) => {
			state.admin = action.payload;
		},
		setShowTokenExpireMessage: (state, action: PayloadAction<boolean>) => {
			state.showTokenExpiredMessage = action.payload;
		},
	}
});

export const {
	setLoggedIn,
	setAdmin,
	setShowTokenExpireMessage
} = authSlice.actions;

export default authSlice.reducer;