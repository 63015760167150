
import UsersPage from "../pages/users/Users";
import PackagePage from "../pages/packages/Package";
import OrderPage from "../pages/order/Order";
import { RouteType } from "./config";
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardPage from "../pages/dashboard/Dashboard";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AdminsPage from "../pages/admins/Admins";
import { MailLock } from "@mui/icons-material";
import EmailConfig from "../pages/email_config/EmailConfig";

const appRoutes: RouteType[] = [
	{
		index: true,
		element: <DashboardPage />,
		path: "/",
		state: "dashboard",
		allowMobile: false,
		sidebarProps: {
			displayText: "Thống kê chung",
			icon: <AnalyticsIcon />
		}
	},
	{
		index: true,
		element: <OrderPage />,
		path: "/order",
		role: 'admin',
		state: "order",
		allowMobile: true,
		sidebarProps: {
			displayText: "Quản lý đơn hàng",
			icon: <ShoppingCartIcon />
		}
	},
	{
		index: true,
		element: <UsersPage />,
		path: "/users",
		role: 'admin',
		state: "users",
		allowMobile: true,
		sidebarProps: {
			displayText: "Quản lý người dùng",
			icon: <PersonIcon />
		}
	},
	{
		index: true,
		element: <PackagePage />,
		path: "/packages",
		role: 'admin',
		state: "packages",
		allowMobile: false,
		sidebarProps: {
			displayText: "Quản lý gói dịch vụ",
			icon: <ArticleIcon />
		}
	},
	{
		index: true,
		element: <AdminsPage />,
		path: "/admin",
		role: 'root',
		state: "admin",
		allowMobile: false,
		sidebarProps: {
			displayText: "Quản lý tài khoản",
			icon: <PersonIcon />
		}
	},
	{
		index: true,
		element: <EmailConfig />,
		path: "/email-config",
		role: 'root',
		allowMobile: false,
		state: "email-config",
		sidebarProps: {
			displayText: "Cấu hình Email",
			icon: <MailLock />
		}
	},
//   {
//     path: "/component",
//     element: <ComponentPageLayout />,
//     state: "component",
//     sidebarProps: {
//       displayText: "Components",
//       icon: <AppsOutlinedIcon />
//     },
//     child: [
//       {
//         path: "/component/alert",
//         element: <AlertPage />,
//         state: "component.alert",
//         sidebarProps: {
//           displayText: "Alert"
//         },
//       },
//       {
//         path: "/component/button",
//         element: <ButtonPage />,
//         state: "component.button",
//         sidebarProps: {
//           displayText: "Button"
//         }
//       }
//     ]
//   },
];

export default appRoutes;