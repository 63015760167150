import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import adminService from "../../../services/admin.service";
import { Admin } from "../../../interface/admin";
import { setLoading } from "../../../redux/commonSlice";
import { useDispatch } from "react-redux";

interface Props {
	open: boolean,
	onClose: any,
	resourceAdmin: Admin
	getAdmins: Function
}

const ToggleActiveDialog = ({open, onClose, resourceAdmin, getAdmins} : Props) => {
	const dispatch = useDispatch();
	const toggleActiveAdmin = async () => {
		dispatch(setLoading(true));
		const result = await adminService.toggleActive({id: resourceAdmin._id, status : resourceAdmin.status !== 'inactive'? false: true });
		if (result?.success) {
			toast.success('Đổi trạng thái thành công');
			getAdmins();
			onClose();
		}
		else {
			toast.error(COMMON_ERROR_MSG)
		}
		dispatch(setLoading(false));
	}

	if (resourceAdmin) {
		return (
			<Dialog open={open} maxWidth="sm" fullWidth>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<span>{ `Chỉnh sửa trạng thái hoạt động của người dùng`}</span>
						<IconButton
							sx={{ marginLeft: '1px' }}
							edge="start"
							color="inherit"
							onClick={onClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</div>	
				</DialogTitle>
				<DialogContent className={"resource-info px-[24px]"}>
					Bạn có muốn đổi trạng thái hoạt động của quản trị viên <b>{resourceAdmin.user_name }</b> thành {resourceAdmin.status !== 'inactive' ? 'không hoạt động' : 'đang hoạt động'} không?`
					 
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose}>
						Quay lại
					</Button>
					<Button
						variant="contained"
						onClick={() => toggleActiveAdmin()}
						>
						Xác nhận
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
	return <></>

}

export default ToggleActiveDialog;