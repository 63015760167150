import { ListItemButton, ListItemIcon, useMediaQuery, useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";
import { setOpen } from '../../redux/commonSlice';

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { appState } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const onAction = () => {
    dispatch(setOpen(false));
  }

  return (
    item.sidebarProps && item.path ? (
      <ListItemButton
        disabled={!item.allowMobile && fullScreen}
        onClick={() => onAction()}
        component={Link}
        to={item.path}
        sx={{
          "&: hover": {
            backgroundColor: colorConfigs.sidebar.hoverBg
          },
          backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
          paddingY: "20px",
          paddingX: "24px"
        }}
      >
        <ListItemIcon sx={{
          color: colorConfigs.sidebar.color
        }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {item.sidebarProps.displayText}
      </ListItemButton>
    ) : null
  );
};

export default SidebarItem;