import { useEffect, useState } from "react";
import PackageTable from "./component/PackageTable";
import resourceService from "../../services/packages.service";
import { Button } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreatePackageDialog from "./component/CreatePackageDialog";

const Package = () => {
	const [packages, setPackages] = useState<any[]>([]);
	const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);

	const getPackages = async () => {
		const result = await resourceService.getPackages();
		if (result?.success) {
			const { data } = result;
			setPackages(data)
		}
	}

	useEffect(() => {
		getPackages()
	}, [])

	return (
		<>
			<CreatePackageDialog
				open={openCreatePackageModal}
				onClose={() => setOpenCreatePackageModal(false)}
				getPackages={getPackages}
			/>
			<div className="flex justify-between">
				<Button variant="contained" onClick={() => setOpenCreatePackageModal(true)}>
					<AddCircleIcon />
					<span className="ml-2">Tạo gói</span>
				</Button>	
				<div className="text-xl font-bold mt-auto">
					Tổng cộng: {packages.length}
				</div>
			</div>
			<PackageTable packages={packages} getPackages={getPackages} />
		</>
	)
}
export default Package