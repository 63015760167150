import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	ButtonGroup,
	IconButton,
	Tooltip
} from "@mui/material";
import { getPackageType, getPriceString, getRoleName } from "../../../helper";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Package } from "../../../interface/package";
import { useState } from "react";
import UpdatePackageDialog from './UpdatePackageDialog'
import ToggleActiveDialog from "./ToggleActiveDialog";
import UpdateHistoryDialog from "./UpdateHistoryDialog";
import moment from "moment";
import { Edit, History } from "@mui/icons-material";

const PackageTable = ({ packages, getPackages }: { packages: Package[], getPackages: Function }) => {
	const [openUpdateModal, setOpenUpdateModal] = useState(false);
	const [openToggleActiveModal, setOpenToggleActiveModal] = useState(false);
	const [openUpdateHistoryModal, setOpenUpdateHistoryModal] = useState(false);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: theme.palette.primary.main,
		  'padding-left': '10px',
		  'padding-right': '10px',
		  color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 14,
		  'padding-left': '10px',
		  'padding-right': '10px',
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
		  backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
		  border: 0,
		},
	}));

	const [selectedPackage, setSelectedPackage] = useState(null as unknown as Package)
	const openUpdatePackageModal = (index: number) => {
		setSelectedPackage(packages[index]);
		setOpenUpdateModal(true);
	}

	const openTogglePackageActiveModal = (index: number) => {
		setSelectedPackage(packages[index]);
		setOpenToggleActiveModal(true);
	}

	const openHistoryModal = (index: number) => {
		setSelectedPackage(packages[index]);
		setOpenUpdateHistoryModal(true);
	}

  	return (
		<>
			<UpdateHistoryDialog
				open={openUpdateHistoryModal}
				onClose={() => setOpenUpdateHistoryModal(false)}
				resourcePackage={selectedPackage}
			/>
			<ToggleActiveDialog
				open={openToggleActiveModal}
				onClose={() => setOpenToggleActiveModal(false)}
				resourcePackage={selectedPackage}
				getPackages={getPackages}
			/>
			<UpdatePackageDialog
				open={openUpdateModal}
				onClose={() => setOpenUpdateModal(false)}
				resourcePackage={selectedPackage}
				getPackages={getPackages}
			/>
			<TableContainer component={Paper} className={"mt-10"} >
				<Table sx={{ minWidth: 650 }} aria-label="package table">
					<TableHead>
						<TableRow>
							<StyledTableCell align="center">STT</StyledTableCell>
							<StyledTableCell align="left">Tên gói</StyledTableCell>
							<StyledTableCell align="left">Số khách (người)</StyledTableCell>
							<StyledTableCell align="left">Dung lượng (Mb)</StyledTableCell>
							<StyledTableCell align="left">Chu kỳ (tháng)</StyledTableCell>
							<StyledTableCell align="left">Loại gói</StyledTableCell>
							<StyledTableCell align="left">Loại tài khoản</StyledTableCell>
							<StyledTableCell align="center">Giá (VNĐ)</StyledTableCell>
							<StyledTableCell align="center">Trạng thái</StyledTableCell>
							<StyledTableCell align="center">Ngày tạo</StyledTableCell>
							<StyledTableCell align="center">Ngày sửa</StyledTableCell>
							<StyledTableCell align="left"></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{packages?.map((p, index) => (
						<StyledTableRow
							key={index}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<StyledTableCell component="th" scope="row" sx={{ width: '10px'}} align="center">
								{index + 1}
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '340px'}}>{ p.package_name }</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '300px'}}>
								<span>
									{ `${p.event_guest}` }
								</span>
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '300px'}}>
								<span>
									{ `${p.file_storage}` }
								</span>
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '180px'}}>
								<span>
									{ p.cycle }
								</span>
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '250px'}}>
								<span>
									{ getPackageType(p.type) }
								</span>
							</StyledTableCell>
							<StyledTableCell align="left" sx={{ width: '250px'}}>
								<span>
									{ getRoleName(p.role as string) }
								</span>
							</StyledTableCell>
							<StyledTableCell align="right" sx={{ width: '220px'}}>
								<span>
									{ getPriceString(p.price).replace('VNĐ', '') }
								</span>
							</StyledTableCell>
							<StyledTableCell align="center" sx={{ width: '250px'}}>
								{
									p.active 
									? 
									<Tooltip title={'Dừng kích hoạt gói dịch vụ '+p.package_name}>
										<div
											className="p-1 py-2 bg-green-500 hover:bg-green-600 rounded-xl text-center text-white cursor-pointer text-sm font-medium"
											onClick={() => openTogglePackageActiveModal(index)}
											>
												Đang hoạt động
										</div>
									</Tooltip>
									: 
									<Tooltip title={'Kích hoạt gói dịch vụ '+p.package_name}>
										<div
											className="p-1 py-2 bg-red-600 rounded-xl hover:bg-red-700 text-center text-white cursor-pointer text-sm font-medium"
											onClick={() => openTogglePackageActiveModal(index)}
											>
												Không hoạt động
										</div>
									</Tooltip>
								}
							</StyledTableCell>
							<StyledTableCell align="center" sx={{ width: '200px'}}>
								<span>
									{ p?.createdAt ? moment(p.createdAt).format('DD-MM-YYYY HH:mm') : '-' }
								</span>
							</StyledTableCell>
							<StyledTableCell align="center" sx={{ width: '200px'}}>
								<span>
									{ p?.updatedAt ? moment(p.updatedAt).format('DD-MM-YYYY HH:mm') : '-' }
								</span>
							</StyledTableCell>
							<StyledTableCell align="center" sx={{ width: '100px'}}>
								<ButtonGroup size="small">
									<Tooltip title='Chỉnh sửa thông tin gói'>
										<Button
											// variant="contained"
											color="info"
											size="small"
											onClick={() => openUpdatePackageModal(index)}>
											<Edit/>
										</Button>
									</Tooltip>
									<Tooltip title='Danh sách lịch sử gói'>
										<Button
											// variant="contained"
											color="success"
											size="small"
											onClick={() => openHistoryModal(index)}>
											<History/>
										</Button>
									</Tooltip>
								</ButtonGroup>
							</StyledTableCell>
						</StyledTableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
		</>

	);
};

export default PackageTable;