import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
	TablePagination
} from "@mui/material";
// import { getOrderName, getOrderType, getPriceString, getResourceUnit } from "../../../helper";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Order } from "../../../interface/order";
import { useState } from "react";
// import UpdateOrderDialog from './UpdateOrderDialog'
import { LIMIT_RESOURCE } from "../../../configs/enum";
import ToggleActiveDialog from "./ToggleActiveDialog";
import OrderTableRow from "./OrderTableRow";

const OrderTable = ({ Orders, onchangePage, onchangeRowperPage, totalPage, total, getOrders, query }: { 
	Orders: Order[],
	onchangePage: Function,
	onchangeRowperPage: Function,
	totalPage: number,
	total: number,
	getOrders: Function,
	query: any
}) => {
	// const [openUpdateModal, setOpenUpdateModal] = useState(false);
	// const [openToggleActiveModal, setOpenToggleActiveModal] = useState(false);

	const columns = [
		{id: 'stt', label: 'STT', width: '2%', align: 'center'},
		{id: 'order_alias', label: 'Mã hóa đơn', width: '10%', align: 'center'},
		{id: 'user_name', label: 'Người dùng', width: '8%', align: 'left'},
		{id: 'order_number', label: 'Số lượng', width: '5%', align: 'center'},
		{id: 'sub_total', label: 'Tổng đơn (VNĐ)', width: '8%', align: 'right'},
		{id: 'total', label: 'Thành tiền (VNĐ)', width: '8%', align: 'right'},
		{id: 'change', label: '', width: '1%', align: 'left'},
		{id: 'status', label: 'Trạng thái', width: '4%', align: 'center'},
		{id: 'createdAt', label: 'Ngày tạo', width: '5%', align: 'center'},
		{id: 'updatedAt', label: 'Ngày hoàn thành', width: '5.5%', align: 'center'},
	];

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: theme.palette.primary.main,
		  color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 14,
		},
	}));

	// const [selectedOrder, setSelectedOrder] = useState(null as unknown as Order)
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
		  backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
		  border: 0,
		},
	}));

	const labelDisplayedRows = () => {
		return `Trang ${query.page}/${Math.ceil(total / query.limit)}`; 
	}

	// const openUpdateOrderModal = (index: number) => {
	// 	setSelectedOrder(Orders[index]);
	// 	setOpenUpdateModal(true);
	// }

	// const openToggleOrderActiveModal = (index: number) => {
	// 	if (Orders[index]?.role) {
	// 		return;
	// 	}
	// 	setSelectedOrder(Orders[index]);
	// 	setOpenToggleActiveModal(true);
	// }

	const handleChangePage = (e: any, page: any) => {
		onchangePage(page+1);
	}

	const handleChangeRowsPerPage = (data: any) => {
		onchangePage(1);
		onchangeRowperPage(data.target.value);
	}



  	return (
		<div className="mt-2">
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow>
							{/* <StyledTableCell width={'2%'}/> */}
							{columns.map((x,i)=><StyledTableCell width={x.width} align={x.align as any} sx={{
								...(i === 1 ? {position: 'sticky', left: '0px', zIndex: '99'} : {}),
								display: {xs: (i === 0?'none':'table-cell'), md:'table-cell'},
								}} key={x.id}>{x.label}</StyledTableCell>)}
							<StyledTableCell width={'5%'}/>
						</TableRow>
					</TableHead>
					<TableBody>
						{Orders.map((x: Order, index: number)=>
							<OrderTableRow row={x} index={(query.page - 1)*query.limit + index} getOrders={getOrders}/>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				labelRowsPerPage="Hiển thị"
				labelDisplayedRows={labelDisplayedRows}
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={total}
				rowsPerPage={query.limit}
				page={query.page - 1}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</div>
	);
};

export default OrderTable;