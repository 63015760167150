/* eslint-disable */
import Cookies from 'js-cookie'
import axios from 'axios'
import envConstants from './key'
import storageConstant from './storage.constant'
import authService from '../services/auth.service'
import { store } from '../redux/store';
import { setLoggedIn, setAdmin, setShowTokenExpireMessage } from '../redux/auth'
import { setQuery } from '../redux/users'
import { DEFAULT_USER_QUERY } from './constant'

const instance = axios.create({
    baseURL: envConstants.API_URL,
    // withCredentials: true
})

instance.interceptors.request.use(
    async (config) => {
        const token = Cookies.get(storageConstant.ACCESS_TOKEN)
        config.headers.Authorization = token ? token : ''
        config.headers.PathName = window.location.pathname
        config.headers.Search = window.location.search
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        // Thrown error for request with OK status code
        const { data } = response
        const originalRequest = response.config

        const isExpAccessToken = data.code === 401 && data.data === 'isAdmin'
        if (isExpAccessToken) {
            // Is auto call get new access and refresh token
            if (data.isAuto) {
                return authService
                    .getNewAccessAndRefreshToken()
                    .then(({ accessToken }) => {
                        axios.defaults.headers.common['Authorization'] = accessToken
                        return instance(originalRequest)
                    })
                    .catch((error) => {
						if (localStorage.getItem(storageConstant.USER)) {
							store.dispatch(setShowTokenExpireMessage(true))
						}
						localStorage.clear()
						store.dispatch(setLoggedIn(false))
						store.dispatch(setQuery(DEFAULT_USER_QUERY))
						store.dispatch(setAdmin({}))
						Cookies.remove(storageConstant.ACCESS_TOKEN)
						Cookies.remove(storageConstant.REFRESH_TOKEN)
                        return Promise.reject(error)
                    })
            }
        }

        return response
    },
    (error) => {
        if (error.code === 'ECONNABORTED') {
            return Promise.reject(error)
        }
        const { response } = error
        const { data } = response
        return Promise.reject(data)
    }
)

export default instance
