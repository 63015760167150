import { useEffect, useState } from "react";
import OrderTable from "./component/OrderTable";
import orderService from "../../services/order.service";
import { Button, IconButton, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import StatsticsOrder from "./component/StatsticsOrder";
import { OrderStats } from "../../interface/order";
import OrderNotification from "./component/OrderNotification";
import SearchOrder from "./component/SearchOrder";
import { setIsSelect, setOrderStatus, setReload } from "../../redux/order";
import { CachedOutlined, SyncAltSharp } from "@mui/icons-material";
import moment from "moment";
import { setLoading } from "../../redux/commonSlice";
import { useSearchParams } from "react-router-dom";
// import CreateOrderDialog from "./component/CreateOrderDialog";

const Order = () => {
	const [searchParam, setSearchParam] = useSearchParams();
	const [Orders, setOrders] = useState<any[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [orderStats, setOrderStats] = useState<OrderStats>({
		cancel_count: 0,
		cancel_price: 0,
		complete_count: 0,
		complete_price: 0,
		pending_count: 0,
		pending_price: 0,
		total_count: 0,
		total_price: 0,
	});
	const dispatch = useDispatch();
	const selectOrder = useSelector((state: RootState) => state.orders.order_alias);
	const isSelectOrder = useSelector((state: RootState) => state.orders.isSelect);
	const selectStatusOrder = useSelector((state: RootState) => state.orders.status);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [time, setTime] = useState<Date>(new Date());
	const [query, setQuery] = useState<{page: number, limit: number, searchstring: string, toDate: any, fromDate: any, status: string}>({
		page: 1,
		limit: 10,
		searchstring: searchParam.get('order_alias') ?? '',
		status: 'all',
		toDate: null,
		fromDate: null,
	});
	const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);

	const getOrders = async () => {
		const result = await orderService.getOrders(query);
		if (result?.success) {
			const { data } = result;
			setOrders(data.docs);
			setTotalPage(data.totalPages);
			setTotal(data.totalDocs);
		}
		const resultStats = await orderService.getOrderStats(query);
		if (resultStats?.success) {
			const { data } = resultStats;
			setOrderStats(data[0] as any);
		}
		dispatch(setLoading(false))

	}

	const useRead = useSelector((state: RootState) => state.orders.isReload);
	const reload = ()=>{
		dispatch(setReload({isReload: !useRead}));
		dispatch(setLoading(true))
	}

	const onSelectStatus = (param: string) =>{
		dispatch(setOrderStatus({status: param}));
		dispatch(setIsSelect({isSelect : true}))
	}

	const onchangePage = (page: number)=>{
		setQuery({
			...query,
			page: page,
		})
	}

	const onchangeRowperPage = (limit: number)=>{
		setQuery({
			...query,
			limit: limit,
		})
	}

	const onChangeOtherKey = (obj: any)=>{
		setQuery({
			...query,
			...obj,
			page: 1,
		})
	}

	const download = async () => {
		await orderService.download({
			...query,
			page: undefined,
			limit: undefined,
		})
	}

	useEffect(()=>{
		if(isSelectOrder){
			setQuery({
				...query,
				searchstring: selectOrder,
				page: 1,
			})
			// setStatus(selectStatusOrder);
			dispatch(setIsSelect({isSelect : false}))
		}
	},[isSelectOrder])
	
	useEffect(()=>{
		if(selectStatusOrder !== ''){
			setQuery({
				...query,
				status: selectStatusOrder,
				page: 1,
			})
			dispatch(setOrderStatus({status: ''}))
		}
	},[selectStatusOrder])

	useEffect(() => {
		reload();
		getOrders();
		setTime(new Date());
	}, [query])

	return (
		<>	
			<SearchOrder onChangeOtherKey={onChangeOtherKey} query={query} downloadFunc={download} />
			<StatsticsOrder orderStats={orderStats} onClick={onSelectStatus}/>
			{/* <div className="mb-4 text-xl flex justify-between font-bold">
				<div className="flex gap-2 text-base italic"><IconButton color="primary" onClick={()=>{
					reload();
					getOrders();
					setTime(new Date());
				}}><CachedOutlined/></IconButton> <div className="my-auto">Cập nhật lần cuối lúc: {moment(time).format('HH:mm:ss DD/MM/Y')}</div></div>
				<div className="text-xl font-bold">{`Tổng cộng: ${total}`}</div>
			</div> */}
			<OrderTable total={total} totalPage={totalPage} Orders={Orders} query={query} getOrders={getOrders} onchangePage={onchangePage} onchangeRowperPage={onchangeRowperPage}/>
		</>
	)
}
export default Order