import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from "react";
import { emailRegex, passwordRegex, usernameRegex } from "../../../configs/constant";
import { generateDefaultPassword } from "../../../helper";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/commonSlice";
import adminService from "../../../services/admin.service";

interface Props {
	open: boolean,
	onClose: any,
	getUsers: Function
}

export default function CreateAdminDialog({ open, onClose, getUsers }: Props) {
	const dispatch = useDispatch();
	const defaultPassword = generateDefaultPassword();
	const [username, setUsername] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [password, setPassword] = useState(defaultPassword);
	const [passwordError, setPasswordError] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState(defaultPassword);
	const [passwordConfirmError, setPasswordConfirmError] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		if (!open) {
			resetAllState();
		}
	}, [open])

	const resetAllState = () => {
		const defaultPassword = generateDefaultPassword();
		setUsername('')
		setUsernameError('')
		setEmail('')
		setEmailError('')
		setPassword(defaultPassword)
		setPasswordError('')
		setPasswordConfirm(defaultPassword)
		setPasswordConfirmError('')
		setShowPassword(false)
	}

	const handleSubmit = async () => {
		dispatch(setLoading(true));
		const result = await adminService.createUser({ user_name: username,password });
		if (result.success) {
			toast.success('Tạo người dùng thành công');
			await getUsers();
			onClose();
		}
		else {
			if ((result as any)?.data?.data) {
				setUsernameError((result.data as any).data);
			}
			else toast.error('Tạo người dùng thất bại. Vui lòng thử lại.');
		}
		dispatch(setLoading(false));
	};

	return <>
		<Dialog open={open} maxWidth="xs" fullWidth>
			<DialogTitle>
				<div className="flex justify-between items-center">
					<span>Tạo người quản trị mới</span>
					<IconButton
						sx={{ marginLeft: '1px' }}
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</div>	
			</DialogTitle>
			<DialogContent className={"resource-info px-[24px]"}>		
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						id="username-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (!value) {
								setUsernameError('Tên đăng nhập không được để trống!')
							}
							else if (!value.match(usernameRegex)) {
								setUsernameError('Tên đăng nhập gồm 6-20 ký tự, không chứa ký tự đặc biệt!')
							}
							else {
								setUsernameError('')
							}
							setUsername(e.target.value)	
						}}
						label="Tên đăng nhập"
						value={username}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ usernameError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						type={showPassword ? 'text' : 'password'}
						id="password-input"
						InputProps={{
							endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
									{
										showPassword ? <VisibilityOffIcon/> : <VisibilityIcon  />
									}
								</IconButton>
						}}
						onChange={(e: any) => {
							const value = e.target.value;
							if (!value) {
								setPasswordError('Mật khẩu không được để trống!')
							}
							else if (!value.match(passwordRegex)) {
								setPasswordError('Mật khẩu gồm 6-20 ký tự, có chứa chữ thường, chữ in hoa, và ký tự số!')
							}
							else {
								setPasswordError('')
							}
							if (e.target.value !== passwordConfirm) {
								setPasswordConfirmError('Xác nhận mật khẩu phải trùng với mật khẩu!')
							}
							setPassword(e.target.value)	
						}}
						label="Mật khẩu"
						value={password}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ passwordError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						type={showPassword ? 'text' : 'password'}
						id="password-confirm-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (value !== password) {
								setPasswordConfirmError('Xác nhận mật khẩu phải trùng với mật khẩu!')
							}
							else {
								setPasswordConfirmError('')
							}
							setPasswordConfirm(e.target.value)	
						}}
						InputProps={{
							endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
								{
									showPassword ? <VisibilityOffIcon/> : <VisibilityIcon  />
								}
							</IconButton>
						}}
						label="Xác nhận mật khẩu"
						value={passwordConfirm}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ passwordConfirmError }
				</span>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Quay lại
				</Button>
				<Button
					variant="contained"
					onClick={handleSubmit}
					disabled={ !!(
						usernameError
						|| passwordError
						|| passwordConfirmError
						|| !username
						|| !password
						|| !passwordConfirm
					) }>
					Xác nhận
				</Button>
			</DialogActions>
		</Dialog>
	</>
}