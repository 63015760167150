import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
    Badge,
    Menu,
    MenuItem,
    List,
    Popper,
    Popover,
    Typography,
    Divider,
    Box,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { getPackageName } from "../../../helper";
import { useEffect, useState } from "react";
import { Order } from "../../../interface/order";
import orderService from "../../../services/order.service";
import storageConstant from "../../../configs/storage.constant";
import Cookies from "js-cookie";
import envConstants from "../../../configs/key";
import axios from '../../../configs/axios';
import { CircleSharp, Notifications, ReplaySharp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setIsSelect, setReload, setSelectOrder } from "../../../redux/order";
import { setAppState } from "../../../redux/commonSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// import { Package } from "../../../interface/package";

function OrderNotification(){
    let nIntervId: NodeJS.Timer; 
    const isRead = useSelector((state: RootState) => state.orders.isReload);
    const ITEM_HEIGHT = 100;
    const [toggle, setToggle] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [paidList, setPaidList] = useState<Order[]>([]);
    const [total, setTotal] = useState(0);
    const [lastestPaidOrder, setLastestPaidOrder] = useState<Order>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getLastest = () =>{
        // const result = await orderService.getLastestOrder();
		// if (result?.success) { 
        //     if(lastestPaidOrder?._id !== result?.data[0]._id)
        //     {
        //         setLastestPaidOrder(result?.data);
        //         toast.info(`Đơn hàng ${result?.data[0].order_alias_string + result?.data[0].order_alias_number.toString().padStart(5, '0')} đã được thanh toán`)
        //     }
        // }
        // const url = envConstants.API_URL + '/order/lastest-paid-order';
        // const eventSource = new EventSource(url);

        // const token = Cookies.get(storageConstant.ACCESS_TOKEN);

        // if(eventSource !== null)
        // {

        //     (eventSource as any).onmessage((_event: any,ev: any)=>{
        //         console.log(ev.data)
        //     })
        // }

        // const response = axios.get('/order/lastest-paid-order', {
        //     responseType: 'stream'
        // }).p;

        // axios({
        //     method: 'get',
        //     url: '/order/lastest-paid-order',
        //     responseType: 'stream'
        //   })
        //     .then(function (response) {
        //         const stream = response.data;
        //         console.log(stream);
        //         stream.on('data', (data: any) => {
        //             toast.info(data);
        //             console.log(data);
        //         });
        //         // response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))
        //     });

        // const stream = response.data;

        // stream.on('data', (data: any) => {
        //     toast.info(data);
        //     console.log(data);
        // });

        // stream.on('end', () => {
        //     console.log("stream done");
        // });
 

    }

    const reLoad = () =>{
      dispatch(setReload({isReload : !isRead}))
    }

    const onSelectOrder = (value: any) =>{
      dispatch(setSelectOrder({order_alias : value}))
      dispatch(setIsSelect({isSelect : true}))
      dispatch(setAppState('order'));
      navigate( '/order');
      setAnchorElUser(null)
    }

    const getList = async ()=>{
        const response = await orderService.getPaidOrderList({});
        if(response?.success){
            setPaidList(response?.data?.docs);
            setTotal(response?.data?.totalDocs)
        }
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        if(!anchorElUser){

            setAnchorElUser(event.currentTarget);
        }else setAnchorElUser(null);
      };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    
    useEffect(()=>{
        // if (!nIntervId) {
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        //     nIntervId = setInterval(async() => getLastest(), 30000);
        // }
        getList()
        
    },[isRead])

    return (
        <div className="relative">
            <IconButton 
            // sx={{ ml: 2 }}
            aria-controls={toggle ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={toggle ? 'true' : undefined}
            onClick={handleOpenUserMenu} 
            >
                <Badge badgeContent={total} color="error">
                    <Notifications/>
                </Badge>
            </IconButton>
            <Menu
              sx={{ mt: '45px', zIndex: '99999', position: 'absolute' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  minHeight: ITEM_HEIGHT * 4.5,
                  maxHeight: ITEM_HEIGHT * 4.5,
                  wordWrap:'break-word',
                  width: '50ch',
                },
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {paidList.length === 0 && 
                <Typography className="italic text-center mt-12" sx={{wordWrap:'break-word',width: '50ch'}}>
                  Không có đơn hàng đã thanh toán nào !
                </Typography> }
              {paidList.map((setting) => (
                <>
                  <MenuItem 
                  onClick={()=>onSelectOrder(setting.order_alias_string + setting.order_alias_number.toString().padStart(5, '0'))}
                  key={setting.order_alias_string + setting.order_alias_number.toString().padStart(5, '0')} sx={{
                    whiteSpace: 'normal'
                  }}>
                    <div className="gap-3">
                      <Typography variant="body1">Hóa đơn <b>{setting.order_alias_string + setting.order_alias_number.toString().padStart(5, '0')}</b> đã được khách hàng thông báo đã thanh toán, vui lòng kiểm tra và cập nhật trạng thái đơn hàng</Typography>
                      <Typography variant="body2" className="text-end"><i className="text-slate-500">Thời gian thực hiện: { moment(setting.updatedAt).format('DD/MM/Y HH:mm') }</i></Typography>
                    </div>
                  </MenuItem>
                  <Divider />
                </>
              ))}
              <Box className="fixed top-[468px] w-[50ch] h-[43px] pt-1 bg-slate-100 border-t-2 border-slate-200">
                <Button onClick={reLoad} startIcon={<ReplaySharp/>}>Làm mới</Button>
              </Box>
            </Menu>

        </div>
    );

}

export default OrderNotification;