
import { useState, useEffect } from "react";
import StatsticsUser from "./StatisticUser";
import { IconButton } from "@mui/material";
import { CachedOutlined } from "@mui/icons-material";
import moment from "moment";
import userService from "../../../services/user.service";
import { UserStats } from "../../../interface/user";
import UserSearchFilter from "./UserSearchFilter";
import UserChart from "./UserChart";

const UserDashboard = () => {
	const [query, setQuery] = useState<{searchstring: string}>({
		searchstring: ''
	});
	const [time, setTime] = useState<Date>(new Date());
	let getStatsTimeoutId: any = null;

	useEffect(() => {
		getUserStats();
	}, []);

	useEffect(() => {
		clearTimeout(getStatsTimeoutId);
		getStatsTimeoutId = setTimeout(() => getUserStats(), 200);
	}, [query]);

	const getUserStats = async () => {
		const resultStats = await userService.getUserStats(query);
		if (resultStats?.success) {
			const { data } = resultStats;
			setUserStats(data);
		}
	}

	const [userStats, setUserStats] = useState<UserStats>({
		total_count: 0,
		vip_count: 0,
		normal_count: 0,
		guest_count: 0,
		unverified_count: 0,
		verified_count: 0
	});

	return <div className="px-2">
		{/* <UserSearchFilter query={query} setQuery={setQuery}/> */}
		<UserChart userStats={userStats}/>
		{/* <div className="mb-4 text-xl flex justify-between font-bold">
			<div className="flex gap-2 text-base italic">
				<IconButton
					color="primary" 
					onClick={()=> {
						getUserStats()
						setTime(new Date());
					}}>
					<CachedOutlined/>
				</IconButton> 
				<div className="my-auto">Cập nhật lần cuối lúc: {moment(time).format('HH:mm:ss DD/MM/Y')}</div>
			</div>
			<div className="text-xl font-bold">{`Tổng cộng: ${userStats.total_count}`}</div>
		</div> */}
	</div>
}

export default UserDashboard;