import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Button,
	IconButton,
	ButtonGroup,
	Tooltip,
	useMediaQuery,
} from "@mui/material";
import { getHighestRole, getRoleName, getUserStatus } from "../../../helper";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import moment from "moment";
// import { setQuery } from "../../../redux/users";
import ChangeRoleDialog from "./ChangeRoleDialog";
import { useEffect, useState } from "react";
import { User } from "../../../interface/user";
import { USER_ROLE, USER_STATUS } from "../../../configs/enum";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import InfoIcon from '@mui/icons-material/Info';
import DetailUser from "./DetailUser";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SendMailModal from "./SendMailDialog";
import SetPasswordDialog from "./SetPasswordDialog";
import { InfoOutlined, MailOutline, ReceiptLong, VpnKey } from "@mui/icons-material";
import { setAppState, setLoading } from "../../../redux/commonSlice";
import { setSelectOrder, setIsSelect } from "../../../redux/order";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import ConfirmModal from "../../component/ConfirmModal";
import feedbackService from "../../../services/feedback.service";
import { toast } from "react-toastify";
import { COMMON_ERROR_MSG } from "../../../configs/constant";

const UserTable = ({ getUsers, query, setQuery, basePackages, extraPackages, getPackage}: {query: any, setQuery: Function, getUsers: Function, getPackage:Function, basePackages: any[], extraPackages: any[]}) => {
	const navigate = useNavigate();
	const users = useSelector((state: RootState) => state.users.users)
	// const query = useSelector((state: RootState) => state.users.query)
	const total = useSelector((state: RootState) => state.users.total)
	const [open, setOpen] = useState(false);
	const [openDetail, setOpenDetail] = useState(false);
	const [openSendMailModal, setOpenSendMailModal] = useState(false);
	const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);
	const [openSendFeedbackModal, setOpenSendFeedbackModal] = useState(false);
	const dispatch = useDispatch()
	const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: theme.palette.primary.main,
		  color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 14,
		},
	}));

	const [selectedUser, setSelectedUser] = useState(null as unknown as User)

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
		  backgroundColor: theme.palette.action.hover,
		},
		'&:nth-of-type(even)': {
		  backgroundColor: 'white',
		},
		// hide last border
		'&:last-child td, &:last-child th': {
		  border: 0,
		},
	}));

	const onSelectUser = (value: any) =>{
		dispatch(setSelectOrder({order_alias : value}))
		dispatch(setIsSelect({isSelect : true}))
		dispatch(setAppState('order'));
		navigate( '/order');
	  }

	const onSendFeedBack = (index: number) => {
		setSelectedUser(users[index]);
		setOpenSendFeedbackModal(true);
	}

	const onPassSending = async (data: any) => {
		dispatch(setLoading(true));
		const result = await feedbackService.sendFeedbackEmail(data);
		if (result?.success) {
			toast.success('Gửi mail thành công');
			setOpenSendFeedbackModal(false);
		}
		else {
			toast.error((result as any)?.data?.message || COMMON_ERROR_MSG);
		}
		dispatch(setLoading(false));

	}
	const onCancelSending = async () => {
		setSelectedUser(null as unknown as User);
		setOpenSendFeedbackModal(false);
	}
	
	const handleChangePage = (e: any, page: any) => {
		setQuery({
			...query,
			page: page + 1
		})
	}

	const handleChangeRowsPerPage = (data: any) => {
		setQuery({
			...query,
			page: 1,
			limit: data.target.value
		})
	}

	const labelDisplayedRows = () => {
		return `Trang ${query.page}/${Math.ceil(total / query.limit)}`; 
	}

	const openChangeRoleModal = (index: number) => {
		setSelectedUser(users[index]);
		setOpen(true)
	}
	const openChangeDetailModal = (index: number) => {
		setSelectedUser(users[index]);
		setOpenDetail(true)
	}
  	return (
		<>
			<ChangeRoleDialog 
				open={open} 
				user={selectedUser} 
				basePackages={basePackages} 
				extraPackages={extraPackages} 
				setSelectedUser={setSelectedUser}
				getPackage={getPackage}
				onClose={() => setOpen(false)} 
				getUsers={getUsers} />
			<DetailUser 
				open={openDetail} 
				basePackages={basePackages} 
				extraPackages={extraPackages} 
				getUsers={getUsers} 
				onClose={()=>setOpenDetail(false)} 
				user={selectedUser} />
			<SendMailModal
				open={openSendMailModal}
				onClose={()=>setOpenSendMailModal(false)}
				user={selectedUser}
			/>
			<SetPasswordDialog
				open={openSetPasswordModal}
				onClose={() => setOpenSetPasswordModal(false)}
				user={selectedUser}
			/>
			<TableContainer 
				component={Paper} 
				className={"mt-6"}
				sx={{ 
					'&::-webkit-scrollbar': {
						width: { md: '0.4em', xs: '3px' },
						height: { md: 'auto', xs: '3px' }
					},
					'&::-webkit-scrollbar-track': {
						boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
						webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
					},
					'&::-webkit-scrollbar-thumb': {
						outline: 'slategrey'
					}
				}}
			>
				<Table aria-label="user table">
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ display: isMobile ? 'none' : 'default'}} width="5%" >STT</StyledTableCell>
							<StyledTableCell 
								sx={{ position: 'sticky', left: '0px', zIndex: '99' }}
								align="left" width="15%" >Tên tài khoản</StyledTableCell>
							<StyledTableCell align="left" width="15%">Email</StyledTableCell>
							<StyledTableCell align="left" width="10%">Họ và tên</StyledTableCell>
							<StyledTableCell align="left" width="10%">Số điện thoại</StyledTableCell>
							<StyledTableCell align="left" width="15%">Trạng thái tài khoản</StyledTableCell>
							<StyledTableCell align="center" width="10%">Loại tài khoản</StyledTableCell>
							<StyledTableCell align="center" width="10%">Ngày tạo</StyledTableCell>
							<StyledTableCell align="left" width="10%"></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{users?.map((user, index) => (
						<StyledTableRow
							key={user._id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<StyledTableCell component="th" scope="row" sx={{ width: '20px', ...(isMobile && { display: 'none' })}}>
								{index + 1 + (query.page - 1) * query.limit}
							</StyledTableCell>
							<StyledTableCell 
								sx={{
									position: 'sticky',
									left: '0px',
									zIndex: '9',
									backgroundColor: (index % 2 === 0 ? '#F5F5F5' : 'white')
								}}
								align="left">{user.user_name}</StyledTableCell>
							<StyledTableCell align="left" >{user.email}</StyledTableCell>
							<StyledTableCell align="left" >{user.fullname}</StyledTableCell>
							<StyledTableCell align="left" >{user.phone}</StyledTableCell>
							<StyledTableCell align="left" >
								<div className="flex items-center">
									<div>{getUserStatus(user.status)}</div>
									{
										(user?.email && user.status !== USER_STATUS.VERIFIED)
										&& (
											<Tooltip title="Gửi email xác thực">
												<IconButton sx={{ marginBottom: '4px', marginLeft: '5px'}}>
													<ContactMailIcon color="warning" onClick={() => {
														setOpenSendMailModal(true)
														setSelectedUser(user)
													}} />
												</IconButton>
											</Tooltip>
										)
									}
								</div>
							</StyledTableCell>
							<StyledTableCell align="center" sx={{ width: '250px'}}>{getRoleName(getHighestRole(user.roles))}</StyledTableCell>
							<StyledTableCell align="center" sx={{ width: '250px'}}>{moment(new Date(user.createdAt)).format('DD-MM-YYYY HH:mm')}</StyledTableCell>
							<StyledTableCell align="right" sx={{ width: '400px'}}>
								<ButtonGroup size="small">
									<Tooltip title={'Nâng cấp gói dịch vụ'} >
										<Button
											// variant="contained"
											color="success"
											disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
											onClick={() => openChangeRoleModal(index)}
										>
											<PublishedWithChangesIcon/>
										</Button>
									</Tooltip>
									<Tooltip title={'Cấp lại mật khẩu'}>
										<Button
											// variant="contained"
											color="warning"
											disabled={user.roles.includes(USER_ROLE.GUEST) || user.status !== USER_STATUS.VERIFIED}
											onClick={() => {
												setOpenSetPasswordModal(true)
												setSelectedUser(user)
											}}
										>
											<VpnKey/>
										</Button>
									</Tooltip>
									<Tooltip title={'Gửi mail đánh giá sản phẩm'}>
										<Button
											color="secondary"
											// variant="contained"
											disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
											onClick={() => onSendFeedBack(index)}
										>
											<MailOutline/>
										</Button>
									</Tooltip>
									<Tooltip title={'Thông tin chi tiết'}>
										<Button
											// variant="contained"
											// disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
											onClick={() => openChangeDetailModal(index)}
										>
											<InfoOutlined/>
										</Button>
									</Tooltip>
									<Tooltip title={'Danh sách đơn hàng'}>
										<Button
											color="secondary"
											// variant="contained"
											// disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
											onClick={() => onSelectUser(user.user_name)}
										>
											<ReceiptLong/>
										</Button>
									</Tooltip>
									{/* <Button
										variant="contained"
										startIcon={<PublishedWithChangesIcon/>}
										color="success"
										size="small"
										disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
										onClick={() => openChangeRoleModal(index)}>
										Nâng cấp
									</Button>
									<Button
										variant="contained"
										startIcon={<EditIcon/>}
										size="small"
										color="warning"
										disabled={user.roles.includes(USER_ROLE.GUEST) || user.status !== USER_STATUS.VERIFIED}
										onClick={() => {
											setOpenSetPasswordModal(true)
											setSelectedUser(user)
										}}>
										Đổi mật khẩu
									</Button>
									<Button
										variant="contained"
										startIcon={<InfoIcon/>}
										size="small"
										// disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
										onClick={() => openChangeDetailModal(index)}>
										Chi tiết
									</Button> */}
								</ButtonGroup>
							</StyledTableCell>
						</StyledTableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmModal
				open={openSendFeedbackModal}
				content={<p>Bạn có muốn gửi email đánh giá sản phẩm cho <b>{selectedUser?.user_name}</b> không?</p>}
				title={'Xác nhận gửi mail'}
				data={selectedUser}
				onCancel={onCancelSending}
				onPass={onPassSending}
			/>
			<TablePagination
				labelRowsPerPage="Hiển thị"
				labelDisplayedRows={labelDisplayedRows}
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={total}
				rowsPerPage={query.limit}
				page={query.page - 1}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</>

	);
};

export default UserTable;