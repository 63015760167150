import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	Divider,
	TextField,
	Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { User } from "../../../interface/user";
import userService from "../../../services/user.service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/commonSlice";
import { toast } from "react-toastify";
import { COMMON_ERROR_MSG, passwordRegex } from "../../../configs/constant";
import { useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { generateDefaultPassword } from "../../../helper";
import { useEffect } from "react";
import ConfirmModal from "../../component/ConfirmModal";

interface Props {
	open: boolean,
	onClose: any,
	user: User,
}

const SetPasswordDialog = ({open, onClose, user} : Props)=>{
	const dispatch = useDispatch();
	const defaultPassword = generateDefaultPassword();
	const [password, setPassword] = useState(defaultPassword);
	const [passwordError, setPasswordError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [passwordConfirm, setPasswordConfirm] = useState(defaultPassword);
	const [passwordConfirmError, setPasswordConfirmError] = useState('');
	const [openConfirm, setOpenConfirm] = useState(false);	

	useEffect(() => {
		if (open) {
			const defaultPassword = generateDefaultPassword();
			setPassword(defaultPassword);
			setShowPassword(false);
			setPasswordConfirm(defaultPassword);
			setPasswordError('');
			setPasswordConfirmError('');
		}
	}, [open])

	const handleSubmit = async ()=> {
		dispatch(setLoading(true));
		const result = await userService.setPassword({
			password,
			id: user._id
		});
		if (result.success) {
			toast.success('Đổi mật khẩu thành công');
			onClose();
		}
		else {
			toast.error((result as any)?.data?.message || COMMON_ERROR_MSG);
		}
		dispatch(setLoading(false));
	}

	const onCancelConfirm = () =>{
		setOpenConfirm(false);
	}
	const onPassConfirm = async () =>{
		await handleSubmit();
		setOpenConfirm(false);
	}


	if (user)
		return <Dialog open={open} maxWidth="sm" fullWidth>
			<DialogTitle>
				<div className="flex justify-between items-center">
					<span>Cấp lại mật khẩu cho người dùng {user.user_name}</span>
					<IconButton
						sx={{ marginLeft: '1px' }}
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</div>	
			</DialogTitle>
			<Divider/>
			<DialogContent className={"px-[24px]"}>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						type={showPassword ? 'text' : 'password'}
						id="password-input"
						InputProps={{
							endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
									{
										showPassword ? <VisibilityOffIcon/> : <VisibilityIcon  />
									}
								</IconButton>
						}}
						onChange={(e: any) => {
							const value = e.target.value;
							if (!value) {
								setPasswordError('Mật khẩu không được để trống!')
							}
							else if (!value.match(passwordRegex)) {
								setPasswordError('Mật khẩu gồm 6-20 ký tự, có chứa chữ thường, chữ in hoa, và ký tự số!')
							}
							else {
								setPasswordError('')
							}
							if (e.target.value !== passwordConfirm) {
								setPasswordConfirmError('Xác nhận mật khẩu phải trùng với mật khẩu!')
							}
							setPassword(e.target.value)	
						}}
						label="Mật khẩu"
						value={password}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ passwordError }
				</span>
				<div className="flex items-center w-full">
					<TextField
						margin="normal"
						required
						fullWidth
						type={showPassword ? 'text' : 'password'}
						id="password-confirm-input"
						onChange={(e: any) => {
							const value = e.target.value;
							if (value !== password) {
								setPasswordConfirmError('Xác nhận mật khẩu phải trùng với mật khẩu!')
							}
							else {
								setPasswordConfirmError('')
							}
							setPasswordConfirm(e.target.value)	
						}}
						InputProps={{
							endAdornment: <IconButton onClick={() => setShowPassword(!showPassword)}>
								{
									showPassword ? <VisibilityOffIcon/> : <VisibilityIcon  />
								}
							</IconButton>
						}}
						label="Xác nhận mật khẩu"
						value={passwordConfirm}
					/>
				</div>
				<span className="mt-1 text-sm text-left text-red-500">
					{ passwordConfirmError }
				</span>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Quay lại
				</Button>
				<Button
					variant="contained"
					onClick={()=>{
						setOpenConfirm(true);
					}}
					disabled={!!(!password || passwordError || !passwordConfirm || passwordConfirmError)}
					>
					Xác nhận
				</Button>
			</DialogActions>
			<ConfirmModal
				open={openConfirm}
				data={null}
				onCancel={onCancelConfirm}
				onPass={onPassConfirm}
				title={'Xác nhận cấp lại mật khẩu'}
				key={user._id}
				content={
					<Typography variant="body1">
						Bạn có chắc muốn cấp lại mật khẩu cho tài khoản <b>{user.user_name}</b> không ?
					</Typography>
				}
			/>
		</Dialog>
	return <></>
}

export default SetPasswordDialog;