import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
	Divider,
	ButtonGroup,
	TextField,
	Tooltip,
	Switch,
	FormControlLabel,
	Typography,
	OutlinedInput,
	Table,
	Avatar,
	Grid,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { User } from "../../../interface/user";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { USER_ROLE } from "../../../configs/enum";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from "moment";
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import orderService from "../../../services/order.service";
import { toast } from "react-toastify";
import { getPriceString } from "../../../helper";
import { ErrorOutlineSharp, ErrorSharp, Mail, Person, Phone, RecentActors, Star } from "@mui/icons-material";
import ExtraPackage, { ExtraPackageProps } from "./ExtraPackage";
import AddExtraPackageModal from "./AddExtraPackageModal";
import CompleteDialog from "../../order/component/CompleteDialog";
import CancelDialog from "../../order/component/CancelDialog";
import ConfirmModal from "../../component/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setQuery } from "../../../redux/users";
import { RootState } from "../../../redux/store";
import userService from "../../../services/user.service";

interface Props {
	open: boolean,
	onClose: any,
	getUsers: Function,
	setSelectedUser: Function,
	getPackage: Function,
	user: User,
	extraPackages: any[],
	basePackages: any[]
}

const ChangeRoleDialog = ({open, onClose, getUsers, user, basePackages, extraPackages, setSelectedUser, getPackage} : Props) => {
	const onReload = ()=>{
		getUsers();
		getData();
	}
	const getData = async () =>{
		const respone = await userService.getById({id: user._id});
		if(respone?.success)
		{
			setSelectedUser(respone.data); 
		}
	  }
	const now = new Date();
	const expiredDefaultDate = new Date(user?.user_package?.base?.expiredAt);
	const diff_day = moment(expiredDefaultDate).diff(moment(now),'days')

	const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const [expiredDate, setExpiredDate] = useState<Date|null>(null);
 
	const updateableList = basePackages.filter(x=>
		((x.price > 0 || x.role === 'normal' || x.role === 'vip')&& (user?.user_package ? (user?.user_package?.base?.package_id !== x._id) : true) && x.active === true )
	)

	const filterPriceUpdateableList = [ 
		...(user?.user_package  && basePackages.find(x=>x?._id === user?.user_package?.base?.package_id) ? 
		[basePackages.filter(x=>x?._id === user?.user_package?.base?.package_id).map((x: any)=>(
			{_id: 'current', package_name: <i>---Tiếp tục sử dụng gói hiện tại ({x.package_name})---</i>}
		))[0]] : []), 
		...(user?.user_package  && basePackages.find(x=>x?._id === user?.user_package?.base?.package_id) ? 
		[basePackages.find(x=>x?._id === user?.user_package?.base?.package_id)] : []), 
		...updateableList.filter((x: any)=>
			(x.active === true && (
				basePackages.find((o:any)=>
					// o._id === user?.user_package.base.package_id
					(user?.user_package ? (user?.user_package?.base?.package_id === o._id) : true)
				) ? 
				basePackages.find((o:any)=>
				// o._id === user?.user_package.base.package_id
				(user?.user_package ? (user?.user_package?.base?.package_id === o._id) : true)
				)?.price <= x?.price
				: true
			) ))];

	const [openAddPackage, setOpenAddPackage] = useState(false);
	const [selectedBasePackage, setSelectedBasePackage] = useState(filterPriceUpdateableList[0]? filterPriceUpdateableList[0]!._id : null);
	const [extraPackageList, setExtraPackageList] = useState<any[]>([]);
	const [pendingOrderList, setPendingOrderList] = useState<any[]>([]);
	const [selectPendingOrder, setSelectPendingOrder] = useState<any>();
	const [openComplete, setOpenComplete] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [total, setTotal] = useState(0);
    const [openConfirm, setOpenConfirm] = useState(false);
	const [ contentConfirm, setContentConfirm ] = useState<any>()

	const completeHandler = async (data: any)=>{
        const obj = {
            orderId: data._id,
            ...data,
        }
        const res = await orderService.completeOrder(obj);

        if (res?.success) {
            setOpenComplete(false);
            getPending();
			onReload();
            toast.success('Hoàn thành đơn hàng thành công.');
		}else{
            toast.error('Hoàn thành đơn hàng thất bại.');
        }
    }

    const cancelandler = async (data: any)=>{
        const obj = {
            orderId: data._id,
            reason: data.reason,
            ...data,
        }
        const res = await orderService.cancelOrder(obj);

        if (res?.success) {
            setOpenCancel(false);
            getPending();
            toast.success('Hủy đơn hàng thành công.');
		}else{
            toast.error('Hủy đơn hàng thất bại.');
        }
    }

	useEffect(() => {
		if(user){
			setSelectedBasePackage(filterPriceUpdateableList[0]? filterPriceUpdateableList[0]!._id : null);
			setExtraPackageList([]);
			setExpiredDate(null);
			getPending();
			setTotal(0);
		}
	}, [open, user])

	useEffect(()=>{
		let expiredAt = null as any;
		if(selectedBasePackage === 'current'){
			expiredAt = (expiredDefaultDate);
		}else if(selectedBasePackage !== 'current')
		{
			const found_select = basePackages.find(x=>x._id === selectedBasePackage);
			expiredAt = (moment(now).add(found_select?.cycle ?? 0,'months').toDate());
		}
		setExpiredDate(expiredAt)
		if(extraPackageList.length > 0)
		{
			setExtraPackageList(extraPackageList.map(x=>{
				const diff_day = moment(expiredAt ?? undefined).endOf('days').diff(moment(new Date()),'days');
				const cycle_day = moment(new Date()).endOf('days').add(x.cycle, 'months').diff(moment(new Date()),'days');
				// console.log(`${moment(expiredDate).format('DD-MM-Y')} - ${moment(new Date()).add(x.cycle, 'months').format('DD-MM-Y')} ${diff_day}+ ${cycle_day} +${(x.price / cycle_day)*diff_day}` );
				x['price'] = Math.ceil((x.price_after / cycle_day)*diff_day);
				return x;
			}))	
		}
	},[selectedBasePackage])

	useEffect(()=>{
		if(!openAddPackage)
			{
				const totalPrice = ((selectedBasePackage !== 'current')
						? basePackages.find((o:any)=>o._id === selectedBasePackage)?.price : 0)
					+ extraPackageList.reduce((prev, cur)=>{return prev + cur?.price*cur.quantity}, 0
					);
				setTotal(totalPrice);
			}
	},[openAddPackage, selectedBasePackage, extraPackageList])

	const getPending = async ()=>{
		const respone = await orderService.getPendingOrdersByUser({userId: user._id});

		if (respone?.success) {
			setPendingOrderList(respone.data);
		}else{
			setPendingOrderList([]);
		}
	}

	const onConfirm = async ()=>{
		if(pendingOrderList.length > 0)
		{
			setOpenConfirm(true);
			setContentConfirm(<div>
				<Typography variant="body1">Việc tạo hóa đơn sẽ ảnh hưởng đến các hóa đơn chưa xử lý trước đó của khách hàng. Bạn vẫn muốn tiếp tục chứ ?</Typography>
				<div className="text-slate-700 mt-4 text-sm"> <b><ErrorOutlineSharp/>Chú ý: </b> Các hóa đơn chưa xử lý trước đó ({
					pendingOrderList.map(x=>x?.order_alias).join(' ,')
				}) sẽ bị chuyển về trạng thái <b>Hủy đơn</b></div>
			</div>)
		}else{

			setOpenConfirm(true);
			setContentConfirm(<div>
				<Typography variant="body1">Bạn có chắc muốn tạo đơn hàng cho khách hàng {user.user_name} không ?</Typography>
			</div>)
		}
	}
	const onCancelConfirm = (data: any)=>{
		setOpenConfirm(false);
	}

	const openCompleteHandle = (data: any)=>{
		setSelectPendingOrder({...data, user_infor: [user]});
		setOpenComplete(true);
	}
	const openCancelHandle = (data: any)=>{
		setSelectPendingOrder({...data, user_infor: [user]});
		setOpenCancel(true);
	}

	const handleChangeRole = (event: SelectChangeEvent) => {
		setSelectedBasePackage(event.target.value);
	}

	const addExtraComponent = (data: any)=>{
		// console.log(data);
		const diff_day = moment(expiredDate).endOf('days').diff(moment(new Date()),'days');
		const cycle_day = moment(new Date()).endOf('days').add(data.cycle, 'months').diff(moment(new Date()),'days');
		// console.log(`${moment(expiredDate).format('DD-MM-Y')} - ${moment(new Date()).add(x.cycle, 'months').format('DD-MM-Y')} ${diff_day}+ ${cycle_day} +${(x.price / cycle_day)*diff_day}` );
		data['price'] = Math.ceil((data.price_after / cycle_day)*diff_day);
		setExtraPackageList([...extraPackageList, data]);
	}

	const removeExtraComponent = (data: any)=>{
		setExtraPackageList(extraPackageList.filter(x=>x._id !== data));
	}

	const changeExtraComponent = (data: any)=>{
		setExtraPackageList(extraPackageList.map(x=>{
			if(x._id === data._id){
				x.quantity = data.quantity;
			}
			return x;
		}));
	}

	const addExtra = ()=>{
		setOpenAddPackage(true);
	}

	const changeTotal = (event: any)=>{
		if(+event.target.value >= 0)
		{
			setTotal(+event.target.value);
		}else{
			setTotal(0);
		}
	}

	const handleSubmit = async () => {
		const data = { 
			userId: user._id,
			detail:[...((selectedBasePackage !== 'current') ? [{
				_id: basePackages.find(x=>x._id === selectedBasePackage)?._id,
				quantity: 1,
				price: basePackages.find(x=>x._id === selectedBasePackage)?.price ?? 0,
				event_guest: basePackages.find(x=>x._id === selectedBasePackage)?.event_guest ?? 0,
				file_storage: basePackages.find(x=>x._id === selectedBasePackage)?.file_storage ?? 0,
				type: 'base',
			}]:[]) ,...extraPackageList],
			status: 'pending',
			total: total,
		};
		if(data.detail.length > 0)
		{
			const result = await orderService.createOrder(data)
			if (result!.success) {
				await getUsers();
				toast.success('Tạo đơn hàng thành công. Vui lòng kiểm tra và xác nhận trạng thái tại trang Quản lý đơn hàng!');
				onClose();
			}
			else {
				toast.error((result as any)?.data?.message || COMMON_ERROR_MSG)
			}
		}else {
			toast.error('Không có sự thay đổi !');
		}
		getPackage();
		setOpenConfirm(false);
	}

	if (user) {
		return (
			<Dialog open={open} maxWidth="md" fullWidth fullScreen={isMobile}>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<span>Nâng cấp gói dịch vụ</span>
						<IconButton
							sx={{ marginLeft: '1px' }}
							edge="start"
							color="inherit"
							onClick={onClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</div>	
				</DialogTitle>
				<Divider/>
				<DialogContent className={"user-info px-[24px]"}>
					<Box>
						{pendingOrderList.map(x=>(<Box className='rounded-lg flex gap-2 justify-between flex-col md:flex-row justify-items-stretch px-3 py-2 my-2 text-slate-100' sx={{bgcolor: 'warning.main'}}>
							<div className="flex justify-items-stretch gap-1">
								<ErrorSharp></ErrorSharp>
								Vui lòng xác nhận đơn hàng ({x.order_alias})							
							</div>
							<div className="flex gap-2 justify-end">
								<Button onClick={()=>openCancelHandle(x)} variant="contained">Hủy đơn</Button>
								<Button onClick={()=>openCompleteHandle(x)} variant="contained" color="success">Hoàn thành</Button>
							</div>
						</Box>))}
					</Box>
					<Box >
					<div className="text-lg font-bold pb-4 mt-2">Thông tin người dùng</div>
					<Grid
						container
						sx={{ marginBottom: "16px" }}
						spacing={{ xs: 2, md: 3 }}
						columns={{ xs: 1, md: 12 }}
					>
						<Grid item className="flex gap-3" xs={2} sm={4} md={4}>
							<Avatar
								sx={{ width: 56, height: 56 }}
							>
								<Mail />
							</Avatar>
							<div>
								<div className="text-xl font-semibold text-slate-500">Email</div>
								<div 
									style={{
										wordBreak: "break-all",
									  }}
									className="text-base">{user?.email}</div>
							</div>
						</Grid>
						<Grid item className="flex gap-3" xs={2} sm={4} md={4}>
							<Avatar
								sx={{ width: 56, height: 56 }}
							>
								<Person />
							</Avatar>
							<div>
								<div className="text-lg font-semibold text-slate-500">Tên tài khoản</div>
								<div className="text-base">{user?.user_name}</div>
							</div>
						</Grid>
						{ user?.fullname && (
							<Grid item className="flex gap-3" xs={2} sm={4} md={4}>
								<Avatar
									sx={{ width: 56, height: 56 }}
								>
									<RecentActors />
								</Avatar>
								<div>
									<div className="text-lg font-semibold text-slate-500">Họ tên</div>
									<div className="text-base">{user?.fullname}</div>
								</div>
							</Grid>
						)}
						{ user?.phone && (
							<Grid item className="flex gap-3" xs={2} sm={4} md={4}>
								<Avatar
									sx={{ width: 56, height: 56 }}
									>
									<Phone />
								</Avatar>
								<div>
									<div className="text-lg font-semibold text-slate-500">Số điện thoại</div>
									<div className="text-base">{user?.phone}</div>
								</div>
							</Grid>
						)}
					</Grid>
					{/* <div className="grid grid-cols-2 gap-5 w-full justify-around pb-4">
						<div className="flex gap-3">
							<Avatar
							sx={{ width: 56, height: 56 }}
							>
							<Mail />
							</Avatar>
							<div>
							<div className="text-xl font-semibold text-slate-500">Email</div>
							<div className="text-base">{user?.email}</div>
							</div>
						</div>

						<div className="flex gap-3">
							<Avatar
							sx={{ width: 56, height: 56 }}
							>
							<Person />
							</Avatar>
							<div>
							<div className="text-lg font-semibold text-slate-500">Tên tài khoản</div>
							<div className="text-base">{user?.user_name}</div>
							</div>
						</div>
						{user?.fullname && <div className="flex gap-3">
							<Avatar
							sx={{ width: 56, height: 56 }}
							>
							<RecentActors />
							</Avatar>
							<div>
							<div className="text-lg font-semibold text-slate-500">Họ tên</div>
							<div className="text-base">{user?.fullname}</div>
							</div>
						</div>}
						{user?.phone && <div className="flex gap-3">
							<Avatar
							sx={{ width: 56, height: 56 }}
							>
							<Phone />
							</Avatar>
							<div>
							<div className="text-lg font-semibold text-slate-500">Số điện thoại</div>
							<div className="text-base">{user?.phone}</div>
							</div>
						</div>}
					</div> */}
					{/* <div className="text-base ml-2 pb-2">{ user?.email && `Email: ${user.email}`}</div>
					<div className="text-base ml-2">
					{`Tên tài khoản: ${user?.user_name}`}
					</div> */}
					<Divider/>
					</Box>
					
					<Box className="mt-4">
						<div className="text-lg font-bold">Thông tin gói dịch vụ (thêm mới/ nâng cấp)</div>
						{
							filterPriceUpdateableList.length > 0 && <div className="flex gap-2 md:flex-row flex-col w-full mt-4">
								<div className="ml-1 my-auto w-full md:w-1/3">Gói dịch vụ chính:</div>
								<FormControl className="w-full md:w-2/3">
									<Select
										size="small"
										labelId="select-label"
										id="simple-select"
										value={selectedBasePackage}
										onChange={handleChangeRole}
									>
										{filterPriceUpdateableList.map((x: any)=>
											<MenuItem value={x!._id}>
												{
												(x!.package_name !== undefined ? x!.package_name : '') 
												}
												{
													(x!.price !== undefined && x!.event_guest !== undefined && x!.file_storage !== undefined)
													?  ` (${getPriceString(x!.price)} - ${x!.event_guest} khách - ${x!.file_storage} Mb)`
													: ''
												}
												{/* {(user?.user_package ? (user?.user_package?.base?.package_id === x!._id) : false) && 
													<Tooltip title='Gói hiện tại'>
														<Star color="warning"/>
													</Tooltip>} */}
											</MenuItem>)}
									</Select>
								</FormControl>
							</div>
						}
						{/* <FormControlLabel control={<Checkbox/>} label={<div className="italic text-sm">Tiếp tục sử dụng gói dịch vụ chính hiện tại</div>} /> */}
						<div className="ml-1 w-full mt-3">
							<div className="py-3 my-auto flex gap-2 justify-between">
								<div>Gói dịch vụ phụ:</div>
								<Button 
									variant="contained" 
									onClick={addExtra} 
									endIcon={<AddCircleSharpIcon/>}
									disabled={
										extraPackages.filter((x=>x.active === true)).map(x=>x._id).filter(val => !extraPackageList.map(x=>x._id).includes(val)).length === 0
									}
								>Thêm</Button>
							</div>
							<Table>
							{extraPackageList
								.map((x: any)=>
									<ExtraPackage data={x} removeExtraComponent={removeExtraComponent} defaultExtraPackages={extraPackageList} changeExtraComponent={changeExtraComponent}/>
								)}
							</Table>
						</div>
						<hr className="my-3 h-0.5 border-t-0 bg-blue-200"/>
						<Box className="bg-slate-200 p-2 rounded-md border-transparent border flex flex-col-reverse gap-3 md:flex-row justify-between">
						        <div className="mt-2">
									<div className="mt-3">
									{(expiredDate )&& <b>Thời gian hết hạn dịch vụ: {' '}</b>}
									{(expiredDate )&& moment(expiredDate).format('DD/MM/YYYY')}
									</div>
									<div>
									<p className="mt-4"><b>Tài nguyên đạt được: </b>{' '}
										{
										(((selectedBasePackage !== 'current')
											? basePackages.find((o:any)=>o._id === selectedBasePackage)?.event_guest ?? 0 : 0)
										+
										extraPackageList.reduce((prev, cur)=>{return prev + cur?.event_guest*cur?.quantity}, 0) ).toString() + ' khách'
										}
										, {' '}
										{
										(((selectedBasePackage !== 'current')
										? basePackages.find((o:any)=>o._id === selectedBasePackage)?.file_storage?? 0 : 0)+
										extraPackageList.reduce((prev, cur)=>{return prev + cur.file_storage*cur.quantity}, 0) ).toString() + ' Mb'
										}
									</p>
									</div>
								</div>
								<div className="font-bold text-lg">
									<div className="flex gap-2 mt-3 items-center">
										<div className="w-full">Tạm tính:</div>
										<div className="text-end w-full">{getPriceString(
											(
												(selectedBasePackage !== 'current')
												? basePackages.find((o:any)=>o._id === selectedBasePackage)?.price : 0)
											+ extraPackageList.reduce((prev, cur)=>{return prev + cur?.price*cur.quantity}, 0)
											, '0')} VNĐ
											
										</div>
									</div>
									<p className="flex gap-2 mt-3 items-center">
										<div>Thành tiền:</div>
										<OutlinedInput type="number" size="small" sx={{
											backgroundColor:'white',
											'input':{ textAlign: 'end', mr: 1, },
											'input[type=number]::-webkit-inner-spin-button': {
												appearance: 'none'
											},
											'input[type=number]::-webkit-outer-spin-button ': {
												appearance: 'none'
											},
											}} endAdornment={'VNĐ'} onChange={changeTotal} value={total}></OutlinedInput>
									</p>
								</div>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose}>
						Quay lại
					</Button>
					<Button
						variant="contained"
						onClick={onConfirm}
						// disabled={
						// 	!enableButton
						// }
						>
						Xác nhận
					</Button>
				</DialogActions>
				<AddExtraPackageModal 
					open={openAddPackage} 
					defaultExtraPackages={extraPackages.filter(x=>x.active===true)} 
					extraPackages={extraPackageList} 
					onAdd={addExtraComponent} 
					user={user}
					expiredDate={expiredDate}
					onClose={()=>setOpenAddPackage(false)}/>
				<CompleteDialog completeHandler={completeHandler} data={selectPendingOrder} onClose={()=>setOpenComplete(false)} open={openComplete} key={selectPendingOrder}/>
       			<CancelDialog cancelHandler={cancelandler} data={selectPendingOrder} onClose={()=>setOpenCancel(false)} open={openCancel} key={selectPendingOrder}/>
				<ConfirmModal 
					content={
						contentConfirm
					} 
					title={'Xác nhận tạo hóa đơn'} 
					data={user} 
					onCancel={onCancelConfirm}
					onPass={handleSubmit}
					open={openConfirm}
				/>
			</Dialog>
		)
	}
	return <></>
}

export default ChangeRoleDialog;
