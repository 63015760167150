
import { useState, useEffect } from "react";
import { OrderStats } from "../../../interface/order";
import orderService from "../../../services/order.service";
import StatsticsOrder from "../../order/component/StatsticsOrder";
import SearchOrder from "../../order/component/SearchOrder";
import { IconButton } from "@mui/material";
import { CachedOutlined } from "@mui/icons-material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setIsSelect, setOrderStatus, setReload, setSelectOrder } from "../../../redux/order";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { setAppState } from "../../../redux/commonSlice";

const OrderDashboard = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const [query, setQuery] = useState<{searchstring: string, toDate: any, fromDate: any, status: string}>({
		searchstring: '',
		status: 'all',
		toDate: null,
		fromDate: null,
	});
	const [time, setTime] = useState<Date>(new Date());

	useEffect(() => {
		getOrderStats();
	}, [query]);


	const useRead = useSelector((state: RootState) => state.orders.isReload);

	const reload = ()=>{
		dispatch(setReload({isReload: !useRead}));
	}

	const getOrderStats = async () => {
		const resultStats = await orderService.getOrderStats(query);
		if (resultStats?.success) {
			const { data } = resultStats;
			setOrderStats(data[0] as any);
		}
	}

	const onChangeOtherKey = (obj: any)=>{
		setQuery({
			...query,
			...obj,
			page: 1,
		})
	}

	const download = async () => {
		await orderService.download({
			...query,
			page: undefined,
			limit: undefined,
		})
	}

	const [orderStats, setOrderStats] = useState<OrderStats>({
		cancel_count: 0,
		cancel_price: 0,
		complete_count: 0,
		complete_price: 0,
		pending_count: 0,
		pending_price: 0,
		total_count: 0,
		total_price: 0,
	});

	return <div className="bg-white py-3 px-2 shadow-lg rounded-lg">
		<StatsticsOrder orderStats={orderStats} onClick={(param: any)=>{
			dispatch(setOrderStatus({status: param}));
			dispatch(setSelectOrder({order_alias: ''}));
			dispatch(setIsSelect({isSelect : true}))
			dispatch(setAppState('orders'));
			navigate( '/order');
		}}/>
		{/* <div className="mb-4 text-xl flex justify-between font-bold">
			<div className="flex gap-2 text-base italic"><IconButton color="primary" onClick={()=>{
				getOrderStats();
				reload();
				setTime(new Date());
			}}><CachedOutlined/></IconButton> <div className="my-auto">Cập nhật lần cuối lúc: {moment(time).format('HH:mm:ss DD/MM/Y')}</div></div>
			<div className="text-xl font-bold">{`Tổng cộng: ${orderStats.total_count}`}</div>
		</div> */}
	</div>
}

export default OrderDashboard;