import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "../../redux/commonSlice";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Loading from "../common/Loading";
import { useMediaQuery, useTheme } from "@mui/material";

type Props = {
  state?: string,
  children: ReactNode;
  role?: string,
  allowMobile?: boolean,
};

const PageWrapper = (props: Props) => {
  const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const selectAdmin = JSON.parse(localStorage.getItem('USER')??'{}');
  const navigate = useNavigate();
  
  useEffect(() => {
    if (props.state && (!props.role || selectAdmin.role === props.role)) {
      dispatch(setAppState(props.state));
    }
    if(selectAdmin.role !== props.role && props.role && props !== null){
      navigate('/')
    }
    if(fullScreen && !props.allowMobile && selectAdmin.role === 'admin')
    {
      navigate('/order')
    }
  }, [dispatch, props]);

  return (
    <>
      <Loading/>
      {props.children}
    </>
  );
};

export default PageWrapper;