import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
    IconButton,
    Collapse,
    Typography,
    TableFooter,
    ButtonGroup,
    Chip,
    Tooltip,
    Grid
} from "@mui/material";
import { getPriceString } from "../../../helper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Order, OrderStats } from "../../../interface/order";
import { Fragment, useState } from "react";
import orderService from "../../../services/order.service";
// import UpdateOrderDialog from './UpdateOrderDialog'
import { LIMIT_RESOURCE } from "../../../configs/enum";
import ToggleActiveDialog from "./ToggleActiveDialog";
import CompleteDialog from "./CompleteDialog";
import { toast } from "react-toastify";
import CancelDialog from "./CancelDialog";
import moment from "moment";
import PaidIcon from '@mui/icons-material/Paid';
import { ShoppingBasket } from "@mui/icons-material";

function StatsticsOrder ({orderStats, onClick}: { orderStats: OrderStats, onClick: Function}) {

    const onSelect = (param: any) =>{
        onClick(param);
    } 
    return (
    <div className="grid grid-cols-2 md:grid-cols-4">
       <Grid item md={2.9} margin={1} className="bg-blue-500 rounded-lg p-2 shadow-lg cursor-pointer" onClick={()=>onSelect('all')}>
            <div className="text-white text-base md:text-xl font-semibold">TỔNG HÓA ĐƠN</div> 
           <Box className="flex justify-between">
                <PaidIcon className="my-auto" htmlColor="white"/>
                <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold">{getPriceString(orderStats?.total_price, '0').replace('VNĐ', '')}</div><div className="ml-1">VNĐ</div></div>
           </Box>
           <Box className="flex justify-between">
                <ShoppingBasket className="my-auto" htmlColor="white"/>
                <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold">{orderStats?.total_count}</div><div className="ml-1">đơn</div></div>
           </Box>
       </Grid>
       <Grid item md={2.9} margin={1} className="bg-green-500 rounded-lg p-2 shadow-lg cursor-pointer" onClick={()=>onSelect('completed')}>
            <div className="text-white text-base md:text-xl font-semibold">TỔNG DOANH THU</div> 
            <Box className="flex justify-between">
                    <PaidIcon className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold ">{getPriceString(orderStats?.complete_price, '0').replace('VNĐ', '')}</div><div className="ml-1">VNĐ</div></div>
            </Box>
            <Box className="flex justify-between">
                    <ShoppingBasket className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold ">{orderStats?.complete_count}</div><div className="ml-1">đơn</div></div>
            </Box>
       </Grid>
       <Grid item md={2.9} margin={1} className="bg-red-500 rounded-lg p-2 shadow-lg cursor-pointer" onClick={()=>onSelect('pending')}>
            <div className="text-white text-base md:text-xl font-semibold">TỔNG CHƯA XỬ LÝ</div> 
            <Box className="flex justify-between">
                    <PaidIcon className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold ">{getPriceString(orderStats?.pending_price, '0').replace('VNĐ', '')}</div><div className="ml-1">VNĐ</div></div>
            </Box>
            <Box className="flex justify-between">
                    <ShoppingBasket className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold ">{orderStats?.pending_count}</div><div className="ml-1">đơn</div></div>
            </Box>
       </Grid>
       <Grid item md={2.8} margin={1} className="bg-slate-500 rounded-lg p-2 shadow-lg cursor-pointer" onClick={()=>onSelect('canceled')}>
            <div className="text-white text-base md:text-xl font-semibold">TỔNG HỦY ĐƠN</div> 
            <Box className="flex justify-between">
                    <PaidIcon className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold ">{getPriceString(orderStats?.cancel_price, '0').replace('VNĐ', '')}</div><div className="ml-1">VNĐ</div></div>
            </Box>
            <Box className="flex justify-between">
                    <ShoppingBasket className="my-auto" htmlColor="white"/>
                    <div className="text-white flex items-end"><div className="text-base md:text-3xl font-bold ">{orderStats?.cancel_count}</div><div className="ml-1">đơn</div></div>
            </Box>
       </Grid>
    </div>
    )
} 

export default StatsticsOrder;