/* eslint-disable */
export const ACCESS_TOKEN_KEY = 'admin_token'
export const REFRESH_TOKEN_KEY = 'admin_rtoken'
export const HTTP_STATUS_CODE = {
	SUCCESS: 200,
	ERROR: 400,
	PAYMENT_REQUIRED: 402,
}
export const ROLE_ORDER = {
	guest: 0,
	normal: 1,
	vip: 2,
}
export const DEFAULT_USER_QUERY = {
	page: 1,
	limit: 10,
	roles: [],
	keyword: ''
}
export const COMMON_ERROR_MSG = 'Đã có lỗi xảy ra. Vui lòng thử lại.'

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const usernameRegex = /^(?=.*[A-Za-z])(?=.*)[A-Za-z\d]{6,20}$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/;