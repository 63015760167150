import { AxiosResponse } from 'axios';
import axios from '../configs/axios';
import { HTTP_STATUS_CODE } from '../configs/constant';

const feedbackService = {
	sendFeedbackEmail: async (data: any) => {
		try {
			const result = await axios.post('/feedback/sendMail' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error: any) {
			return {
				success: false,
				data: error?.code?.message,
			}
		}
	},
}

export default feedbackService