import { PieChart } from '@mui/x-charts/PieChart';
import { UserStats } from "../../../interface/user";

const UserChart = ({ userStats }: { userStats: UserStats }) => {
	return <div className="">
		<div className="">
			<div className="flex flex-col items-center h-[30vh] bg-white px-2 shadow-lg rounded-lg mt-5">
				<div className="mb-2 text-xl font-semibold mt-3 ml-4 text-left">Thống kê loại tài khoản</div>
				<PieChart
					series={[
						{
						data: [
							{ id: 0, value: userStats.vip_count, label: 'VIP', color: '#fff200' },
							{ id: 1, value: userStats.normal_count, label: 'Thường', color: '#3d5a80' },
							{ id: 2, value: userStats.guest_count, label: 'Khách', color: 'gray' },
						],
						},
					]}
					width={400}
					height={200}
					// slotProps={{
					// 	legend: {
					// 	  direction: 'row',
					// 	  position: { vertical: 'bottom', horizontal: 'middle' },
					// 	  padding: -25,
					// 	},
					//   }}
				/>
			</div>
			{/* <div className="flex flex-col items-center h-[30vh] bg-white px-2 shadow-lg rounded-lg mt-5">
				<div className="mb-5 text-xl font-semibold mt-3">Thống kê trạng thái tài khoản</div>
				<PieChart
					series={[
						{
							data: [
								{ id: 0, value: userStats.unverified_count,  label: 'Chưa xác thực', color: 'red' },
								{ id: 1, value: userStats.verified_count, label: 'Đã xác thực', color: 'green' },
							],
						},
					]}
					width={400}
					height={150}
					slotProps={{
						legend: {
						  direction: 'row',
						  position: { vertical: 'bottom', horizontal: 'middle' },
						  padding: -23,
						},
					  }}
				/>
			</div> */}
		</div>
	</div>
	}


export default UserChart;