export enum LIMIT_RESOURCE {
	FILE_STORAGE = 'file_storage',
	EVENT = 'event',
	EVENT_GUEST = 'event_guest'
}
export enum USER_ROLE {
	GUEST = 'guest',
	NORMAL = 'normal',
	VIP = 'vip',
}
export enum USER_STATUS {
	VERIFIED = 'verified',
	UNVERIFIED = 'unverified'
}
export enum PACKAGE_TYPE {
	BASE = 'base',
	EXTRA = 'extra'
}