import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { getPackageName } from "../../../helper";
import packageService from "../../../services/packages.service";
import { Package } from "../../../interface/package";
import { setLoading } from "../../../redux/commonSlice";
import { useDispatch } from "react-redux";

interface Props {
	open: boolean,
	onClose: any,
	resourcePackage: Package
	getPackages: Function
}

const ToggleActiveDialog = ({open, onClose, resourcePackage, getPackages} : Props) => {
	const dispatch = useDispatch();
	const toggleActivePackage = async () => {
		dispatch(setLoading(true));
		const result = await packageService.togglePackageActive(resourcePackage._id);
		if (result?.success) {
			toast.success('Đổi trạng thái thành công');
			getPackages();
			onClose();
		}
		else {
			toast.error(COMMON_ERROR_MSG)
		}
		dispatch(setLoading(false));
	}

	if (resourcePackage) {
		return (
			<Dialog open={open} maxWidth="sm" fullWidth>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<span>{ resourcePackage.active 
								? `Dừng hoạt động gói dịch vụ ${resourcePackage.package_name}`
								: `Kích hoạt gói dịch vụ ${resourcePackage.package_name}`}</span>
						<IconButton
							sx={{ marginLeft: '1px' }}
							edge="start"
							color="inherit"
							onClick={onClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</div>	
				</DialogTitle>
				<DialogContent className={"resource-info px-[24px]"}>
						Bạn có muốn đổi trạng thái hoạt động của gói <b>{ getPackageName(resourcePackage.package_name)}</b> thành {resourcePackage.active ? 'không hoạt động' : 'đang hoạt động'} không?
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose}>
						Quay lại
					</Button>
					<Button
						variant="contained"
						onClick={() => toggleActivePackage()}
						>
						Xác nhận
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
	return <></>

}

export default ToggleActiveDialog;