import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Box,
    IconButton,
    Collapse,
    Typography,
    TableFooter,
    ButtonGroup,
    Chip,
    Tooltip,
    Link
} from "@mui/material";
import { getPriceString } from "../../../helper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Order } from "../../../interface/order";
import { Fragment, useState } from "react";
import orderService from "../../../services/order.service";
// import UpdateOrderDialog from './UpdateOrderDialog'
import { LIMIT_RESOURCE } from "../../../configs/enum";
import ToggleActiveDialog from "./ToggleActiveDialog";
import CompleteDialog from "./CompleteDialog";
import { toast } from "react-toastify";
import CancelDialog from "./CancelDialog";
import moment from "moment";
import { AssignmentIndOutlined, AssignmentTurnedInOutlined, DoNotDisturbOnOutlined, Edit, HistoryRounded, Info, InfoOutlined, MailOutline } from "@mui/icons-material";
import StatusHistory from "./StatusHistory";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAppState } from "../../../redux/commonSlice";
import { RootState } from "../../../redux/store";
import { setQuery } from "../../../redux/users";
import OrderInforDialog from "./OrderInforDailog";
import ConfirmModal from "../../component/ConfirmModal";
import OrderUpdateModal from "./OrderTotalUpdateDialog";
import { red } from "@mui/material/colors";

function OrderTableRow ({row, index, getOrders}: {row: Order, index: number, getOrders: Function}) {
    const query = useSelector((state: RootState) => state.users.query);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);	
    const [openUpdate, setOpenUpdate] = useState(false);	
  
    const onCancelConfirm = () =>{
      setOpenConfirm(false);
    }
    const onPassConfirm = async (data: any) =>{
      console.log(data);
      const obj = {
        order_id: data._id,
        user_id: data.user_id,
      }

      const res = await orderService.resendOrder(obj);

        if (res?.success) {
            getOrders();
            toast.success('Gửi lại mail đơn hàng thành công.');
		    } else{
            toast.error('Gửi lại mail đơn hàng thất bại:'+ res?.data);
        }

      setOpenConfirm(false);
    }
    const onCancelUpdate = () =>{
      setOpenUpdate(false);
    }
    const onPassUpdate = async (data: any) =>{
      const obj = {
        orderId: data._id,
        total: data.total,
      }

      const res = await orderService.updateTotalOrder(obj);

        if (res?.success) {
            getOrders();
            toast.success('Cập nhật tổng tiền thành công.');
		    } else{
            toast.error('Cập nhật tổng tiền thất bại:'+ res?.data);
        }

        setOpenUpdate(false);
    }

    const completeHandler = async (data: any)=>{
        const obj = {
            orderId: data._id,
            ...data,
        }
        const res = await orderService.completeOrder(obj);

        if (res?.success) {
            setOpenComplete(false);
            getOrders();
            toast.success('Hoàn thành đơn hàng thành công.');
		}else{
            toast.error('Hoàn thành đơn hàng thất bại:'+ res?.data);
        }
    }

    const onSelectUser = (value: any) =>{
      dispatch(setQuery({
				...query,
				page: 1,
				keyword: value,
			}))
      dispatch(setAppState('users'));
      navigate( '/users');
    }

    const cancelandler = async (data: any)=>{
        const obj = {
            orderId: data._id,
            reason: data.reason,
            ...data,
        }
        const res = await orderService.cancelOrder(obj);

        if (res?.success) {
            setOpenCancel(false);
            getOrders();
            toast.success('Hủy đơn hàng thành công.');
		}else{
            toast.error('Hủy đơn hàng thất bại.');
        }
    }

    return (
      <Fragment>
        <TableRow hover sx={{
            "&:hover": {
              '& td:nth-child(2)': {
                  bgcolor: '#F5F5F5'
              }
            }
        }}>
          {/* <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
          <TableCell component="th" scope="row" align="center" sx={{display: {xs:'none', md:'table-cell'}}}>
            {index + 1}
          </TableCell>
          <TableCell 
          sx={{position: 'sticky', left: '0px', zIndex: '99', bgcolor: 'white', }}
          align="center">{row.order_alias_string + row.order_alias_number.toString().padStart(5, '0')}</TableCell>
          <TableCell align="left"><Link component="button" onClick={()=>onSelectUser(row.user_infor[0]?.user_name)}>{row.user_infor[0]?.user_name }</Link></TableCell>
          <TableCell align="center">{row.detail.length}</TableCell>
          <TableCell align="right">{getPriceString(row.sub_total).replace('VNĐ', '')}</TableCell>
          <TableCell align="right">
          {getPriceString(row.total).replace('VNĐ', '')} 
          </TableCell>
          <TableCell align="left" padding="none">
          {
            row.status === 'pending' && 
            <IconButton color="primary" size="small" onClick={()=>{setOpenUpdate(true);}}><Edit fontSize="small"/></IconButton>
          }
          </TableCell>
          <TableCell align="center" padding="none">
            {
            row.status === 'pending' ? <Chip color="error" label={'Chưa xử lý'}/>
            : row.status === 'completed' ? <Chip color="success" label={'Hoàn thành'}/>
            : row.status === 'paid' ? <Chip color="warning" label={'Đã thanh toán'}/>
            : <Chip color="default" label={'Hủy đơn'}/>
          }</TableCell>
          <TableCell align="center">{moment(new Date(row.createdAt)).format('DD-MM-YYYY HH:mm')}</TableCell>
          <TableCell align="center">{(row.updatedAt && (row.status === 'completed' || row.status === 'canceled')) && moment(new Date(row.updatedAt)).format('DD-MM-YYYY HH:mm')}</TableCell>
          <TableCell align="center">
            <ButtonGroup size="small">
                <Tooltip title="Hoàn thành đơn">
                    <Button 
                    onClick={()=>setOpenComplete(true)}
                    color={row.status === 'pending' || row.status === 'paid' ? "success" : "inherit"} disabled={row.status !== 'pending' && row.status !== 'paid'}><AssignmentTurnedInOutlined/></Button>
                </Tooltip>
                <Tooltip title="Hủy đơn">
                    <Button 
                    onClick={()=>setOpenCancel(true)}
                    color={row.status === 'pending' || row.status === 'paid' ? "error" : "inherit"} disabled={row.status !== 'pending' && row.status !== 'paid'}><DoNotDisturbOnOutlined/></Button>
                </Tooltip>
                <Tooltip title="Lịch sử Trạng thái">
                    <Button 
                    onClick={()=>setOpenHistory(true)}
                    color="info"><HistoryRounded/></Button>
                </Tooltip>
                <Tooltip title={'Thông tin chi tiết'}>
										<Button
                      color="primary"
											// variant="contained"
											// disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
											onClick={() => setOpenDetail(true)}
										>
											<InfoOutlined/>
										</Button>
								</Tooltip>
                <Tooltip title={'Gửi lại mail'}>
                  <Button
                    color="secondary"
                    // variant="contained"
                    // disabled={user.status === USER_STATUS.UNVERIFIED || user.roles.includes(USER_ROLE.GUEST)}
                    onClick={() => setOpenConfirm(true)}
                  >
                    <MailOutline/>
                  </Button>
                </Tooltip>
             </ButtonGroup>
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Chi tiết Hóa đơn
                </Typography>
                <Table size="small" sx={{}} aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>STT</TableCell>
                      <TableCell>Gói dịch vụ</TableCell>
                      <TableCell align="center">Loại gói</TableCell>
                      <TableCell align="right">Dung lượng file (Mb)</TableCell>
                      <TableCell align="right">Số lượng khách (người)</TableCell>
                      <TableCell align="center">Số lượng mua</TableCell>
                      <TableCell align="right">Đơn giá</TableCell>
                      <TableCell align="right">Tổng tiền (VND)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.detail.map((detailRow: any, idx: number) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {idx+1}
                        </TableCell>
                        <TableCell>{detailRow.package_name || ''}</TableCell>
                        <TableCell align="center">{detailRow.type === 'base' ? 'Gói chính': 'Gói phụ'}</TableCell>
                        <TableCell align="right">{detailRow.file_storage}</TableCell>
                        <TableCell align="right">{detailRow.event_guest}</TableCell>
                        <TableCell align="center">{detailRow.quantity}</TableCell>
                        <TableCell align="right">{getPriceString(detailRow.price)}</TableCell>
                        <TableCell align="right">
                          {getPriceString(detailRow.quantity * detailRow.price)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter >
                    <TableCell sx={{textAlign:'end',fontWeight: 700, fontSize: '1.25rem', lineHeight: '1.75rem'}} colSpan={8}>{`Tổng hóa đơn: ${getPriceString(row.total)}`}</TableCell> 
                   </TableFooter>
                </Table>
                {row.reason
                  && <Typography variant="h6" gutterBottom component="div">
                  Lý do hủy đơn
                </Typography>
                }
                <Typography variant="body1" gutterBottom component="div">
                  {row.reason}
                </Typography>
                {row?.receiver?.full_name
                  && <>
                  <Typography variant="h6" gutterBottom component="div">
                    Thông tin thanh toán
                  </Typography>
                  <div className="flex gap-3">
                  <Typography className="font-semibold" variant="body1" gutterBottom component="div">
                    Người xác nhận:
                  </Typography>
                  <Typography variant="body1" gutterBottom component="div">
                    {row?.receiver?.full_name}
                  </Typography>
                </div>
                <div className="flex gap-3">
                  <Typography className="font-semibold" variant="body1" gutterBottom component="div">
                    Hình thức thành toán:
                  </Typography>
                  <Typography variant="body1" gutterBottom component="div">
                    {row?.payment_method === 'cash' && 'Tiền mặt'}
                    {row?.payment_method === 'bank' && 'Chuyển khoản'}
                  </Typography>
                </div>
                {
                  row?.payment_method === 'bank' && 
                  <div className="flex gap-3">
                    <Typography className="font-semibold" variant="body1" gutterBottom component="div">
                      {row?.receiver?.bank_name && 'Tài khoản thụ hưởng:'}
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                      {row?.receiver?.bank_name} - {row?.receiver?.bank_number} ({row?.receiver?.bank_account})
                    </Typography>
                  </div>
                }
                  </>
                }
                
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
        <CompleteDialog completeHandler={completeHandler} data={row} onClose={()=>setOpenComplete(false)} open={openComplete} key={index}/>
        <CancelDialog cancelHandler={cancelandler} data={row} onClose={()=>setOpenCancel(false)} open={openCancel} key={index}/>
        <StatusHistory data={row} onClose={()=>setOpenHistory(false)} open={openHistory} key={index}/>
        <OrderInforDialog data={row} onClose={()=>setOpenDetail(false)} open={openDetail} key={index}/>
        <ConfirmModal
          open={openConfirm}
          data={row}
          onCancel={onCancelConfirm}
          onPass={onPassConfirm}
          title={'Xác nhận gửi lại mail đơn hàng'}
          key={row._id}
          content={
            <Typography variant="body1">
              Bạn có chắc muốn gửi lại mail đơn hàng <b>{row?.order_alias_string + row!.order_alias_number!.toString().padStart(5, '0')}</b> cho khách hàng <b>{
                row.user_infor[0]?.user_name}</b> không ?
            </Typography>
          }
			  />
        <OrderUpdateModal
          open={openUpdate}
          data={row}
          onCancel={onCancelUpdate}
          onPass={onPassUpdate}
          title={<>Cập nhật tổng tiền hóa đơn <b>{row?.order_alias_string + row!.order_alias_number!.toString().padStart(5, '0')}</b></>}
          key={row._id}
        />
      </Fragment>
    );
  }
  export default OrderTableRow;