import axios from '../configs/axios';
import { HTTP_STATUS_CODE } from '../configs/constant';
import { ChangeRoleData, UserQuery, CreateUserData, SetPasswordData } from '../interface/user';

const userService = {
	getUsers: async(params: UserQuery) => {
		try {
			const result = await axios.get('/user/list', { params })
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: {
						users: result.data.data.docs,
						total: result.data.data.totalDocs
					}
				}
			}
			else {
				return { 
					success: true,
					data: result.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	changeRole: async(data: ChangeRoleData) => {
		try {
			await axios.patch('/user/change-role', data)
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				error
			}
		}
	},
	createUser: async(data: CreateUserData) => {
		try {
			await axios.post('/user', data);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	sendVerifyEmail: async(userId: string) => {
		try {
			await axios.post(`/user/send-verify-email/${userId}`);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	setPassword: async(data: SetPasswordData) => {
		try {
			await axios.patch('/user/set-password', data);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	exportExcel: async (query: any) => {
		try {
			const response = await axios({
				url: '/user/export',
				method: 'POST',
				data: query,
				responseType: 'blob',
			});
			const headerLine = response.headers['content-disposition'];
			const startFileNameIndex = headerLine.indexOf('"') + 1;
			const endFileNameIndex = headerLine.lastIndexOf('"');
			const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
			return { success: true, data: { fileData: response.data, filename } };
		}
		catch (error) {
			return {
				success: false,
				data: error
			}
		}
	},
	getUserStats: async(params: { searchstring: string }) => {
		try {
			const result = await axios.get('/user/stats', { params });
			return { 
				success: true,
				data: result.data.data
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},

	getUserPackageStats: async() => {
		try {
			const result = await axios.get('/user/package_stats');
			return { 
				success: true,
				data: result.data.data
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},

	getById: async(params:any) => {
		try {
			const result = await axios.get('/user/get_by_id',{ params })
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
}

export default userService