import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	TextField,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { COMMON_ERROR_MSG } from "../../../configs/constant";
import { toast } from "react-toastify";
import { Package } from "../../../interface/package";
import packageService from "../../../services/packages.service";
import { setLoading } from "../../../redux/commonSlice";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../component/ConfirmModal";
import { PACKAGE_TYPE, USER_ROLE } from "../../../configs/enum";
import { getRoleName } from "../../../helper";

interface Props {
	open: boolean,
	onClose: any,
	getPackages: Function,
	resourcePackage: Package
}

const UpdatePackageDialog = ({open, onClose, getPackages, resourcePackage} : Props) => {
	const [eventGuestInput, setEventGuestInput] =  useState<string|number>('')
	const [fileStorageInput, setFileStorageInput] =  useState<string|number>('')
	const [cycleInput, setCycleInput] =  useState<string|number>('')
	const [priceInput, setPriceInput] =  useState<string|number>('')
	const [nameInput, setNameInput] = useState<string>('')
	const dispatch = useDispatch()
	const [openConfirm, setOpenConfirm] = useState(false);	

	useEffect(() => {
		if (resourcePackage && open) {
			setEventGuestInput(resourcePackage.event_guest.toString())
			setFileStorageInput(resourcePackage.file_storage.toString())
			setPriceInput(resourcePackage.price.toString())
			setNameInput(resourcePackage.package_name)
			setCycleInput(resourcePackage.cycle.toString())
		}
	}, [resourcePackage, open])

	const handleSubmit = async () => {
		dispatch(setLoading(true));
		const result = await packageService.updatePackage({
			_id: resourcePackage._id,
			package_name: nameInput,
			event_guest: +eventGuestInput,
			file_storage: +fileStorageInput,
			price: +priceInput,
			cycle: +cycleInput,
		})
		if (result?.success) {
			await getPackages();
			toast.success('Chỉnh sửa thành công.');
			onClose();
		}
		else {
			toast.error((result as any)?.error?.message || COMMON_ERROR_MSG)
		}
		dispatch(setLoading(false));
	}

	const onCancelConfirm = () =>{
		setOpenConfirm(false);
	}
	const onPassConfirm = async () =>{
		await handleSubmit();
		setOpenConfirm(false);
	}

	const [enableButton, setEnableButton] = useState(false);

	useEffect(() => {
		const enable = !!(
			eventGuestInput && fileStorageInput && priceInput && nameInput && cycleInput &&
			(
				eventGuestInput != resourcePackage.event_guest
				|| fileStorageInput != resourcePackage.file_storage
				|| priceInput != resourcePackage.price
				|| nameInput != resourcePackage.package_name
				|| cycleInput != resourcePackage.cycle
			)
		)
		setEnableButton(enable)
	}, [eventGuestInput, fileStorageInput, nameInput, priceInput, cycleInput])
		
	if (resourcePackage) {
		return (
			<Dialog open={open} maxWidth="xs" fullWidth>
				<DialogTitle>
					<div className="flex justify-between items-center">
						<span>{ `Chỉnh sửa gói ${ resourcePackage.package_name }`}</span>
						<IconButton
							sx={{ marginLeft: '1px' }}
							edge="start"
							color="inherit"
							onClick={onClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</div>	
				</DialogTitle>
				<DialogContent className={"resource-info px-[24px]"}>
					<div className="flex items-center">
						<TextField
							margin="normal"
							required
							fullWidth
							id="package-name-input"
							onChange={(e: any) => {
								setNameInput(e.target.value)
							}}
							label="Tên gói"
							value={nameInput}
						/>
					</div>
					<div className="flex items-center w-full">
						<TextField
							margin="normal"
							required
							fullWidth
							id="event-guest-input"
							onChange={(e: any) => {
								if (!isNaN(+e.target.value)) {
									setEventGuestInput(e.target.value)	
								}
							}}
							InputProps={{
								endAdornment: <div className="whitespace-nowrap"> người </div>
							}}
							label="Số lượng khách mời"
							value={eventGuestInput}
						/>
						
					</div>
					<div className="flex items-center w-full">
						<TextField
							margin="normal"
							required
							fullWidth
							id="file-storage-input"
							onChange={(e: any) => {
								if (!isNaN(+e.target.value)) {
									setFileStorageInput(e.target.value)	
								}
							}}
							InputProps={{
								endAdornment: <div> Mb </div>
							}}
							label="Dung lượng lưu trữ"
							value={fileStorageInput}
						/>
					</div>
					<div className="flex items-center w-full">
							<TextField
								margin="normal"
								required
								fullWidth
								id="file-storage-input"
								onChange={(e: any) => {
									if (!isNaN(+e.target.value)) {
										setPriceInput(e.target.value)	
									}
								}}
								label="Giá bán"
								value={priceInput}
								InputProps={{
									endAdornment: <div> VNĐ </div>
								}}
							/>
					</div>
					<div className="flex items-center w-full">
							<TextField
								margin="normal"
								required
								fullWidth
								id="file-storage-input"
								onChange={(e: any) => {
									if (!isNaN(+e.target.value)) {
										setCycleInput(e.target.value)	
									}
								}}
								label="Chu kỳ gói"
								value={cycleInput}
								InputProps={{
									endAdornment: <div> Tháng </div>
								}}
							/>
					</div>
					<div className="flex items-center w-full mt-5">
					<FormControl fullWidth>
						<InputLabel>
							Loại gói
						</InputLabel>
						<Select
							disabled
							value={resourcePackage.type}
							label='Loại gói'
						>
							{Object.values(PACKAGE_TYPE).map(p => <MenuItem value={p}>
									{ p === PACKAGE_TYPE.BASE ? 'Gói chính' : 'Gói phụ' }
								</MenuItem>)}
						</Select>
					</FormControl>
				</div>
				{
					resourcePackage.type === PACKAGE_TYPE.BASE &&
					<div className="flex items-center w-full mt-5">
						<FormControl fullWidth>
							<InputLabel>
								Loại tài khoản
							</InputLabel>
							<Select
								disabled
								value={resourcePackage?.role}
								label='Loại tài khoản'
							>
								{Object.values(USER_ROLE).map(r => <MenuItem value={r}>
										{ getRoleName(r) }
									</MenuItem>)}
							</Select>
						</FormControl>
					</div>
				}
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={onClose}>
						Quay lại
					</Button>
					<Button
						variant="contained"
						onClick={()=>{setOpenConfirm(true)}}
						disabled={ !enableButton }
						>
						Xác nhận
					</Button>
				</DialogActions>
				<ConfirmModal
					open={openConfirm}
					data={null}
					onCancel={onCancelConfirm}
					onPass={onPassConfirm}
					title={'Xác nhận sửa đổi gói dịch vụ'}
					key={null}
					content={
						<Typography variant="body1">
							Bạn có chắc muốn lưu sửa đổi của gói dịch vụ <b>{nameInput}</b> không ?
						</Typography>
					}
				/>
			</Dialog>
		)
	}
	return <></>
}

export default UpdatePackageDialog;