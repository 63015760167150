import { AxiosResponse } from 'axios';
import axios from '../configs/axios';
import { HTTP_STATUS_CODE } from '../configs/constant';
import { CreateOrderData, Order, UpdateOrderData } from '../interface/order';

const orderService = {
	getOrders: async(params:any) => {
		try {
			const result = await axios.get('/order',{ params })
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	getOrderStats: async(params:any) => {
		try {
			const result = await axios.get('/order/stats',{ params })
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	updateOrder: async (data: UpdateOrderData) => {
		try {
			const result = await axios.patch('/order' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	completeOrder: async (data: any) => {
		try {
			const result = await axios.post('/order/complete-order' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error: any) {
			return {
				success: false,
				data: error?.code?.message,
			}
		}
	},
	updateTotalOrder: async (data: any) => {
		try {
			const result = await axios.post('/order/update-total-order' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error: any) {
			return {
				success: false,
				data: error?.code?.message,
			}
		}
	},
	cancelOrder: async (data: any) => {
		try {
			const result = await axios.post('/order/cancel-order' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	createOrder: async (data: any) => {
		try {
			const result = await axios.post('/order' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	toggleOrderActive: async (orderId: string) => {
		try {
			const result = await axios.patch('/order/toggle-active' , { _id: orderId });
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	getLastestOrder: async() => {
		try {
			const result = await axios.get('/order/lastest-paid-order')
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},

	getPaidOrderList: async(params:any) => {
		try {
			const result = await axios.get('/order/list-paid-order',{ params })
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	getLastestPendingPaidOrderList: async() => {
		try {
			const result = await axios.get('/order/lastest-pending-paid-order')
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	getBankList: async() => {
		try {
			const result = await axios.get('/bank/list')
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	resendOrder: async(body: any) => {
		try {
			const result = await axios.post('/order/resend-order', body)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	download: async(param: any)=>{
		await (axios.post('/order/download',param,{
			responseType: 'blob',
		}) as any ).then((response: AxiosResponse<any, any>, error: any) => {
            if (error) {
                // setShowLoad(false);
            } else {
                const headerLine = response.headers['content-disposition'];
                if (headerLine) {
                    const filename = headerLine.split('filename=')[1].split(';')[0].replaceAll('"','');
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    // setShowLoad(false);
                }
            }
        });
	},
	getPendingOrdersByUser: async(params:any) => {
		try {
			const result = await axios.get('/order/list-pending-order-user',{ params })
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
}

export default orderService