/* eslint-disable */
import { ACCESS_TOKEN_KEY, HTTP_STATUS_CODE, REFRESH_TOKEN_KEY } from '../configs/constant';

import Cookies from 'js-cookie';
import axios from '../configs/axios';
import storageConstant from '../configs/storage.constant';
import { store } from '../redux/store';
import { setShowTokenExpireMessage } from '../redux/auth';

const adminService = {
	getList: async (params:any) => {
		try {
			const result = await axios.get('/admin/list',{params});
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: {
						users: result.data.data.docs,
						total: result.data.data.totalDocs
					}
				}
			}
			else {
				return { 
					success: true,
					data: result.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
		
	},
	exportExcel: async (data: any) =>{
		console.log(data);
		return {
			success: true,
			data
		}
	},
	createUser: async(data: any) => {
		try {
			await axios.post('/admin/add-new', data);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	setPassword: async(data: any) => {
		try {
			await axios.patch('/admin/set-password', data);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	changePassword: async(data: any) => {
		try {
			await axios.patch('/admin/change-password', data);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	toggleActive: async(data: any) => {
		try {
			await axios.patch('/admin/toggle-status', data);
			return { 
				success: true,
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},

	getStats: async() => {
		try {
			const result = await axios.get('/admin/statsitic')
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
};

export default adminService;
