import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { useState } from "react";
import { TextField, Select, MenuItem,FormControl, InputLabel } from "@mui/material";
import { setQuery } from "../../../redux/admin";

const SearchFilter = () => {
	const query = useSelector((state: RootState) => state.admin.query);
	const total = useSelector((state: RootState) => state.admin.total);
	const dispatch = useDispatch();
	const [keyword, setKeyword] = useState(query.keyword);
	const [status, setStatus] = useState(query.status);

	const statusList = [
		{key : 'all', label: 'Tất cả'},
		{key : 'active', label: 'Đang hoạt động'},
		{key : 'inactive', label: 'Không hoạt động'},
	]
	
	let keywordTimeOutId: any = null;

	const onChangeKeyword = (keyword: string) => {
		setKeyword(keyword);
		clearTimeout(keywordTimeOutId);
		keywordTimeOutId = setTimeout(() => {
			dispatch(setQuery({
				...query,
				page: 1,
				keyword
			}))
		}, 500);
	}
	const onChangeStatus = (status: string) => {
		setStatus(status);
		dispatch(setQuery({
			...query,
			page: 1,
			status
		}))
	}


	return (
		<div className="flex justify-between pr-4">
			<div className="flex w-[50%] gap-2 mt-3">
				<div className="w-3/4">
					<FormControl fullWidth>
						<TextField
							fullWidth
							label='Tìm kiếm'
							id="keyword"
							onChange={(e: any) => { onChangeKeyword(e.target.value) }}
							placeholder="Tìm kiếm theo tên người dùng "
							value={keyword}
						/>
					</FormControl>
				</div>
				<div className="w-1/4 min-w-[150px]">
					<FormControl fullWidth>
						<InputLabel>Trạng thái</InputLabel>
						<Select
							defaultValue='all'
							label="Trạng thái"
							value={status ? status : 'all'}
							onChange={(event: any) => onChangeStatus(event.target.value)}
						>
							{statusList.map(x=><MenuItem value={x.key}>{x.label}</MenuItem>)}
						</Select>
					</FormControl>
				</div>
			</div>

			<div className="text-xl font-bold mt-auto">
				<div>Tổng cộng: {total}</div>				
			</div>
		</div>
	);
};

export default SearchFilter;