import OrderDashboard from "./components/OrderDashboard";
import UserDashboard from "./components/UserDashboard";
import PackageDashboard from "./components/PackageDashBoard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import StatsticsAdmin from "./components/AdminStat";
import { Box, Grid, IconButton } from "@mui/material";
import { CachedOutlined } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import { setAppState } from "../../redux/commonSlice";
import UserPackageStats from "./components/UserPackageStats";
import OrderPendingList from "./components/OrderPendingList";
import TemplateStats from "./components/TemplateStat";

const DashboardPage = () => {
	const selectAdmin = useSelector((state: RootState) => state.auth.admin);
	const [time, setTime] = useState<Date>(new Date());
	const dispatch = useDispatch();
	const reload = ()=>{
		dispatch(setAppState('dashboard'))
	}

	return <div key={time.getTime()}>
	{/* <div className="mr-0 px-1 py-1 rounded-lg">
		<div className=" text-xl flex justify-end font-bold">
			<div className="flex gap-2 text-base italic text-slate-700"><div className="my-auto">
				Cập nhật lần cuối lúc: {moment(time).format('HH:mm:ss DD/MM/Y')} 
				<IconButton color="primary" onClick={()=>{
					reload();
					// getOrders();
					setTime(new Date());
				}}><CachedOutlined/></IconButton>
				</div></div>
		</div>
	</div> */}
	{
		selectAdmin.role === 'admin' && <>
			<OrderDashboard />
			<div className="w-full grid grid-cols-10 gap-4">
				<div className="col-span-4">
					<OrderPendingList/>
					<UserDashboard />
				</div>
				<div className="col-span-6">
					<UserPackageStats/>
				</div>
				<div className="col-span-10">
					<TemplateStats/>
				</div>
			</div>
		</>
	}
	{
		selectAdmin.role === 'root' && <>
			<StatsticsAdmin />
		</>
	}
	</div>
}

export default DashboardPage;