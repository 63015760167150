import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  SelectChangeEvent,
  Chip,
  Stack,
} from "@mui/material";
import { setQuery as setQueryState } from "../../../redux/users";
import { Replay } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SearchFilter = ({
  setQuery,
  query,
}: {
  setQuery: Function;
  query: {
    page: number;
    limit: number;
    keyword: string;
    roles: any;
    status: string;
  };
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const queryState = useSelector((state: RootState) => state.users.query);
  const total = useSelector((state: RootState) => state.users.total);
  const dispatch = useDispatch();
  // const [roles, setRoles] = useState(query.roles as string[]);
  const [roles, setRoles] = useState<string[]>(["vip", "normal"]);
  const [keyword, setKeyword] = useState(query.keyword);

  const defaultRoleList = [
    { key: "guest", name: "Tài khoản khách" },
    { key: "normal", name: "Tài khoản thường" },
    { key: "vip", name: "Tài khoản VIP" },
  ];

  let keywordTimeOutId: any = null;
  let roleTimeOutId: any = null;

  useEffect(() => {
    if (queryState.keyword !== "") {
      setKeyword(queryState.keyword as any);
      setQuery({
        ...query,
        page: 1,
        keyword: queryState.keyword,
      });
      dispatch(setQueryState({ ...queryState, keyword: "" }));
    }
  }, [queryState]);

  const onChangeKeyword = (keyword: string) => {
    setKeyword(keyword);
    clearTimeout(keywordTimeOutId);
    keywordTimeOutId = setTimeout(() => {
      setQuery({
        ...query,
        page: 1,
        keyword,
      });
    }, 500);
  };

  const onChangeRoles = (role: string) => {
    let newRoles: string[] = [];
    newRoles = [role];
    setRoles(newRoles);
    clearTimeout(roleTimeOutId);
    roleTimeOutId = setTimeout(() => {
      setQuery({
        ...query,
        page: 1,
        // roles: newRoles,
        roles: role === "all" ? undefined : [role],
      });
    }, 500);
  };
  const onChangeRole = (event: SelectChangeEvent<typeof roles>) => {
    const {
      target: { value },
    } = event;

    let newRoles: string[] = [];
    newRoles = typeof value === "string" ? value.split(",") : value;
    setRoles(newRoles.length > 0 ? newRoles : ["vip", "normal"]);
    clearTimeout(roleTimeOutId);
    roleTimeOutId = setTimeout(() => {
      setQuery({
        ...query,
        page: 1,
        // roles: newRoles,
        roles: newRoles,
      });
    }, 500);
  };

  const reLoad = () => {
    setKeyword("");
    setRoles(["normal", "vip"]);
    setQuery({
      ...query,
      page: 1,
      keyword: "",
      // roles: newRoles,
      roles: ["normal", "vip"],
    });
  };

  return (
    <div className="flex justify-between w-full">
      <Stack
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
        className="gap-2 mt-3 items-stretch w-full"
      >
        <div className="w-full md:w-3/4">
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Tìm kiếm"
              id="keyword"
              sx={{
                minWidth: { md: "400px", xs: "340px" },
              }}
              onChange={(e: any) => {
                onChangeKeyword(e.target.value);
              }}
              placeholder="Tìm kiếm theo tên người dùng, email, họ tên và số điện thoại"
              value={keyword}
            />
          </FormControl>
        </div>
        {/* <div className="w-1/4 min-w-[150px]">
					<FormControl fullWidth>
						<InputLabel>Loại tài khoản</InputLabel>
						<Select
							defaultValue='all'
							label="Loại tài khoản"
							value={roles?.length > 0 && roles[0] ? roles[0] : 'all'}
							onChange={(event: any) => onChangeRoles(event.target.value)}
						>
							{defaultRoleList.map(x=><MenuItem value={x.key}>{x.name}</MenuItem>)}
						</Select>
					</FormControl>
				</div> */}
        <div className="">
          <FormControl fullWidth>
            <InputLabel>Loại tài khoản</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{
                minWidth: "200px",
              }}
              value={roles}
              onChange={onChangeRole}
              input={<OutlinedInput label="Loại tài khoản" />}
              renderValue={(selected) =>
                defaultRoleList
                  .filter((x) => selected.includes(x.key))
                  .map((x) => x.name)
                  .join(", ")
              }
            >
              {defaultRoleList.map((x) => (
                <MenuItem value={x.key}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          size="large"
          className="mb-1"
          sx={{ height: "100%" }}
          color="info"
          variant="contained"
          onClick={() => reLoad()}
        >
          <Replay />
        </Button>
      </Stack>

      {/* <div className="text-xl font-bold mt-auto">
				<div>Tổng cộng: {total}</div>				
			</div> */}
    </div>
  );
};

export default SearchFilter;
