import axios from '../configs/axios';
import { HTTP_STATUS_CODE } from '../configs/constant';

const emailSettingService = {
    getList: async () =>{
        try {
			const result = await axios.get('/email-setting/list');
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
			else {
				return { 
					success: true,
					data: result.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		} 
    },

    updateSetting: async(data: any) => {
        try {
            const result = await axios.patch('/email-setting/update-setting', data);
            return { 
                success: true,
                data: result.data.data
            }
        }
        catch(error: any) {
            return {
                success: false,
                data: error?.data,
            }
        }
    },
}

export default emailSettingService;