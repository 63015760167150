import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
	Button,
	Divider,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { User } from "../../../interface/user";
import userService from "../../../services/user.service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/commonSlice";
import { toast } from "react-toastify";
import { COMMON_ERROR_MSG } from "../../../configs/constant";

interface Props {
	open: boolean,
	onClose: any,
	user: User,
}

const SendMailDialog = ({open, onClose, user} : Props)=>{
	const dispatch = useDispatch();

	const handleSubmit = async ()=> {
		dispatch(setLoading(true));
		const result = await userService.sendVerifyEmail(user._id);
		if (result.success) {
			toast.success('Gửi email thành công');
		}
		else {
			toast.error((result as any)?.data?.message || COMMON_ERROR_MSG);
		}
		dispatch(setLoading(false));
		onClose();
	}
	if (user)
		return <Dialog open={open} maxWidth="sm" fullWidth>
			<DialogTitle>
				<div className="flex justify-between items-center">
					<span>Gửi mail xác thực tài khoản</span>
					<IconButton
						sx={{ marginLeft: '1px' }}
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</div>	
			</DialogTitle>
			<Divider/>
			<DialogContent className={"px-[24px]"}>
				Bạn có muốn gửi mail xác thực cho người dùng
				<span className="font-bold">{` ${user.user_name}`}</span>
				?
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Quay lại
				</Button>
				<Button
					variant="contained"
					onClick={handleSubmit}
					>
					Xác nhận
				</Button>
			</DialogActions>
		</Dialog>
	return <></>
}

export default SendMailDialog;