import { FormEvent, useEffect, useState } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch, useSelector } from "react-redux";
import { CachedOutlined, SyncAltSharp, Visibility, VisibilityOff } from "@mui/icons-material";
import moment from "moment";
import emailSettingService from "../../../services/email_setting.service";
import { toast } from "react-toastify";
import { checkRegexEmail } from "../../../helper";
import ConfirmModal from "../../component/ConfirmModal";

const initValidate = {
    invalid: false,
    message: ''
};

const EmailConfigForm = () => {

    const [host, setHost] = useState('');
    const [validateHost, setValidateHost] = useState(initValidate);
    const [port, setPort] = useState('');
    const [validatePort, setValidatePort] = useState(initValidate);
    const [userName, setUserName] = useState('');
    const [validateUserName, setValidateUserName] = useState(initValidate);
    const [pwd, setPwd] = useState('');
    const [validatePwd, setValidatePwd] = useState(initValidate);
    const [nameSender, setNameSender] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);	
    const [validateReceiverEmail, setValidateReceiverEmail] = useState(initValidate);
    const [showPassword, setShowPassword] = useState(false);

  	const handleClickShowPassword = () => setShowPassword((show) => !show);

    const getData = async () =>{
        const data = await emailSettingService.getList();
        if(data.success){
            setHost(data.data.host??'');
            setPort(data.data.port??'');
            setUserName(data.data.username??'');
            setPwd(data.data.password??'');
            setNameSender(data.data.namesender??'');
            setReceiverEmail(data.data.receiveremail??'');
        }
    }

    const checkValidate = async () =>{
        let check_ = false;
        if(host.length > 0){
            setValidateHost(initValidate);
        }else{
            check_ = true;
            setValidateHost({
                invalid: true,
                message: 'Máy chủ không được để trống!'
            })
        } 
        if(port.length > 0){
            setValidatePort(initValidate);
        }else{
            check_ = true;
            setValidatePort({
                invalid: true,
                message: 'Cổng không được để trống!'
            })
        }
        if(userName.length > 0){
            setValidateUserName(initValidate);
        }else{
            check_ = true;
            setValidateUserName({
                invalid: true,
                message: 'Tài khoản không được để trống!'
            })
        }
        if(pwd.length > 0){
            setValidatePwd(initValidate);
        }else{
            check_ = true;
            setValidatePwd({
                invalid: true,
                message: 'Tài khoản không được để trống!'
            })
        }
        if(receiverEmail.length === 0){
            check_ = true;
            setValidateReceiverEmail({
                invalid: true,
                message: 'Email nhận thông tin không được để trống!'
            })
        } else if(!checkRegexEmail(receiverEmail)){
            check_ = true;
            setValidateReceiverEmail({
                invalid: true,
                message: 'Email nhận thông tin không đúng định dạng!'
            })
        } else{
            setValidateReceiverEmail(initValidate);
        }
        return check_;
    }

    useEffect(()=>{
        getData()
    },[])

    const onCancelConfirm = () =>{
		setOpenConfirm(false);
	}
	const onPassConfirm = async () =>{
		await onSubmit();
		setOpenConfirm(false);
	}

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) =>{
        event.preventDefault();
        const checkLast = await checkValidate();
        if(checkLast){
            toast.error('Chưa điền đủ thông tin');
            return;
        }
        if((validateHost.invalid || validatePort.invalid || validateUserName.invalid || validatePwd.invalid || validateReceiverEmail.invalid) && checkLast)
        {
            toast.error('Chưa điền đủ thông tin');
            return;
        }else{
            setOpenConfirm(true);
        }
    }

    const onSubmit = async () =>{

        const data = await emailSettingService.updateSetting(
            {
                host,
                port,
                username: userName,
                password: pwd,
                namesender: nameSender,
                receiveremail: receiverEmail,
            }
        );
        if(data.success){
            toast.success('Cập nhật thông tin thành công !')
            setHost(data.data.host);
            setPort(data.data.port);
            setUserName(data.data.username);
            setPwd(data.data.password);
            setNameSender(data.data.namesender);
        }else{
            toast.error('Có lỗi xảy ra!:'+data.data)
        }
    }

    const onChangeHost = (event: any) =>{
        setHost(event.target.value);
        if(event.target.value.length > 0){
            setValidateHost(initValidate);
        }else{
            setValidateHost({
                invalid: true,
                message: 'Máy chủ không được để trống!'
            })
        }
    }
    const onChangePort = (event: any) =>{
        setPort(event.target.value);
        if(event.target.value.length > 0){
            setValidatePort(initValidate);
        }else{
            setValidatePort({
                invalid: true,
                message: 'Cổng không được để trống!'
            })
        }
    }
    const onChangeUserName = (event: any) =>{
        setUserName(event.target.value);
        if(event.target.value.length > 0){
            setValidateUserName(initValidate);
        }else{
            setValidateUserName({
                invalid: true,
                message: 'Tài khoản không được để trống!'
            })
        }
    }
    const onChangePwd = (event: any) =>{
        setPwd(event.target.value);
        if(event.target.value.length > 0){
            setValidatePwd(initValidate);
        }else{
            setValidatePwd({
                invalid: true,
                message: 'Tài khoản không được để trống!'
            })
        }
    }
    const onChangeNameSender = (event: any) =>{
        setNameSender(event.target.value);
    }

    const onChangeRecieverEmail = (event: any) =>{
        setReceiverEmail(event.target.value);
        if(event.target.value.length === 0){
            setValidateReceiverEmail({
                invalid: true,
                message: 'Email nhận thông tin không được để trống!'
            })
        } else if(!checkRegexEmail(event.target.value)){
            setValidateReceiverEmail({
                invalid: true,
                message: 'Email nhận thông tin không đúng định dạng!'
            })
        } else{
            setValidateReceiverEmail(initValidate);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container width={'80%'} rowGap={5} sx={{m: 'auto'}}>
                <Grid item md={4} sx={{my: 'auto'}}>
                    <div className="font-semibold text-lg">Host ( Máy chủ ) <div className="text-red-500 font-bold inline-block"> &#42; </div> :</div>
                </Grid>
                <Grid item md={8}>
                    <OutlinedInput size="small" fullWidth value={host} error={validateHost.invalid} onChange={onChangeHost}/>
                    <FormHelperText>{validateHost.message}</FormHelperText>
                </Grid>
                
                <Grid item md={4} sx={{my: 'auto'}}>
                    <div className="font-semibold text-lg">Cổng <div className="text-red-500 font-bold inline-block"> &#42; </div>:</div>
                </Grid>
                <Grid item md={8}>
                    <OutlinedInput size="small" fullWidth value={port} error={validatePort.invalid} onChange={onChangePort} type="number"/>
                    <FormHelperText>{validatePort.message}</FormHelperText>
                </Grid>
                <Grid item md={4} sx={{my: 'auto'}}>
                    <div className="font-semibold text-lg">Tên tài khoản <div className="text-red-500 font-bold inline-block"> &#42; </div>:</div>
                </Grid>
                <Grid item md={8}>
                    <OutlinedInput size="small" fullWidth value={userName} error={validateUserName.invalid} onChange={onChangeUserName}/>
                    <FormHelperText>{validateUserName.message}</FormHelperText>
                </Grid>
                <Grid item md={4} sx={{my: 'auto'}}>
                    <div className="font-semibold text-lg">Mật khẩu <div className="text-red-500 font-bold inline-block"> &#42; </div>:</div>
                </Grid>
                <Grid item md={8}>
                    <OutlinedInput 
                        size="small" 
                        fullWidth 
                        error={validatePwd.invalid}
                        value={pwd} 
                        onChange={onChangePwd} 
                        type={ showPassword?"text":"password" }
                        id="password"
                        endAdornment={
                            <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }
                        />
                    <FormHelperText>{validatePwd.message}</FormHelperText>
                </Grid>
                <Grid item md={4} sx={{my: 'auto'}}>
                    <div className="font-semibold text-lg">Tên hiển thị của người gửi:</div>
                </Grid>
                <Grid item md={8}>
                    <OutlinedInput size="small" fullWidth value={nameSender} onChange={onChangeNameSender}/>
                </Grid>
                <Grid item md={4} sx={{my: 'auto'}}>
                    <div className="font-semibold text-lg">Email nhận thông tin <div className="text-red-500 font-bold inline-block"> &#42; </div>:</div>
                </Grid>
                <Grid item md={8}>
                    <OutlinedInput size="small" fullWidth value={receiverEmail} onInput={onChangeRecieverEmail} defaultValue={receiverEmail}/>
                    <FormHelperText>{validateReceiverEmail.message}</FormHelperText>
                </Grid>
                <Grid item md={12} sx={{mr:'auto'}} className="flex justify-end">
                    <Button variant="contained" type="submit">Cập nhật</Button>
                </Grid>
            </Grid>
            <ConfirmModal
                open={openConfirm}
                data={null}
                onCancel={onCancelConfirm}
                onPass={onPassConfirm}
                title={'Cập nhật cấu hình'}
                key={1}
                content={
                    <Typography variant="body1">
                        Bạn có chắc muốn cập nhật cấu hình mới không ?
                    </Typography>
                }
            />
        </form>
    )
    
}

export default EmailConfigForm;