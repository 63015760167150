import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserQuery } from "../interface/user";
import { DEFAULT_USER_QUERY } from "../configs/constant";
const initialState = {
	query: DEFAULT_USER_QUERY as UserQuery,
	users: [] as User[],
	total: 0
};

export const usersSlice = createSlice({
	name: "usersState",
	initialState,
	reducers: {
		setQuery: (state, action: PayloadAction<UserQuery>) => {
			state.query = action.payload;
		},
		setUsers: (state, action: PayloadAction<User[]>) => {
			state.users = action.payload;
		},
		setTotal: (state, action: PayloadAction<number>) => {
			state.total = action.payload;
		}
	}
});

export const {
	setQuery,
	setUsers,
	setTotal
} = usersSlice.actions;

export default usersSlice.reducer;