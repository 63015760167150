import axios from '../configs/axios';
import { HTTP_STATUS_CODE } from '../configs/constant';
import { CreatePackageData, Package, UpdatePackageData } from '../interface/package';

const packageService = {
	getPackages: async() => {
		try {
			const result = await axios.get('/package')
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	updatePackage: async (data: UpdatePackageData) => {
		try {
			const result = await axios.patch('/package' , data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	createPackage: async (data: CreatePackageData) => {
		try {
			const result = await axios.post('/package', data)
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
					data: result.data.data
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	togglePackageActive: async (packageId: string) => {
		try {
			const result = await axios.patch('/package/toggle-active' , { _id: packageId });
			if (result.status === HTTP_STATUS_CODE.SUCCESS) {
				return { 
					success: true,
				}
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	},
	getPackageStats: async() => {
		try {
			const result = await axios.get('/package/stats');
			return { 
				success: true,
				data: result.data.data
			}
		}
		catch(error) {
			return {
				success: false,
				data: error
			}
		}
	}
}

export default packageService