import { useState, useEffect } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import userService from "../../../services/user.service";
import { Box } from "@mui/material";
import templateService from "../../../services/template.service";

const chartSetting = {
    height: 400,
  };
  

function TemplateStats () {

    const [stats, setStats] = useState<any[]>([]);

    const getData = async () =>{
        const resultStats = await templateService.getTemplateStats();
		if (resultStats?.success) {
			const { data } = resultStats;
			setStats(data.map((x: any)=>({
                name: x.infor[0]?.name ?? 'Không rõ',
                public: x.public,
                not_public: x.not_public,
                sum: x.sum,
            })));
		} 
    }

    useEffect(()=>{
        getData()
    },[])

    const valueFormatter = (value: any) => `${value} thiệp`;

    return (<div className="w-full rounded-lg shadow-md bg-white mt-4 h-full mb-2">
    <div className="text-center text-xl font-bold px-3 pt-4 ml-4">Thống kê lượt sử dụng mẫu thiệp (Top 10)</div>
    <BarChart
        xAxis={[{ scaleType: 'band', data: stats.length > 0 ? stats.map(x=>x.name??null) : [] }]}
        series={[
            { data: stats.length > 0 ? stats.map(x=>x.public??0) : [0], valueFormatter, stack: 'total', color: '#4e79a7', label: 'Thiệp công khai' },
            { data: stats.length > 0 ? stats.map(x=>x.not_public??0) : [0], valueFormatter,stack: 'total',color: '#af7aa1', label: 'Thiệp chưa công khai' },
        ]}
        // margin={{left: 200}}
        {...chartSetting}
    />
    </div>)
}

export default TemplateStats;